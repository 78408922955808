<template>
  <div
    class="relative w-72 h-72 shadow-md ml-3 overflow-hidden my-5 rounded-xl bg-white"
    :class="getMargin"
  >
    <div class="absolute left-1 top-1 flex">
      <div v-for="rating in [0, 1, 2, 3, 4]" :key="rating">
        <svg
          class="flex-shrink-0 h-5 w-5"
          :class="productInfo.averageRating > rating ? 'text-action' : 'text-gray-300'"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="variant.label"
      class="absolute top-0 right-0 pr-2 pt-2 p-1 text-white text-sm bg-action rounded-bl-md"
    >
      {{ variant.label }}
    </div>
    <!-- Image -->
    <button @click="goToProductPage" class="rounded-t-lg">
      <div
        v-if="productInfo.image"
        class="overflow-hidden h-40 w-full mb-2 mx-auto flex items-center justify-center bg-no-repeat bg-cover bg-center"
        :style="`background-image: url('${productInfo.image}');`"
      ></div>
      <div
        v-else-if="variant.image"
        class="overflow-hidden h-40 w-40 mt-2 mx-auto flex items-center justify-center bg-no-repeat bg-contain bg-center"
        :style="
          variant.thumbnail
            ? `background-image: url('${variant.thumbnail}');`
            : `background-image: url('${variant.image}');`
        "
      ></div>
      <div v-else class="overflow-hidden h-40 w-40 mt-2 mx-auto flex items-center justify-center">
        <img
          width="180px"
          loading="auto"
          src="/images/icons/image-coming-soon.png"
          alt="No image available"
        />
      </div>
      <!-- Info -->
      <div
        class="w-64 h-12 mt-1 font-medium mx-4 flex items-end break-words whitespace-normal"
        style="word-wrap: break-word; word-break: break-word"
      >
        {{ productInfo.title }}
      </div>
    </button>

    <!-- Color Icons -->
    <div class="flex mx-4 w-48 flex-wrap">
      <div v-for="(variant, index) in productInfo.variants" :key="index">
        <div
          class="w-5 h-5 flex rounded-full border cursor-pointer"
          v-if="productInfo.options[0] === 'Color' && uniqueColor(variant.options[0], index)"
          v-html="getVariantColor(variant.options[0])"
          @click="changeVariant(index)"
          style="margin-right: 2px"
        ></div>
        <div v-else class="h-5"></div>
      </div>
    </div>
    <!-- End of Color Icons -->

    <div class="ml-4">
      <p
        v-if="variant.price < variant.msrp"
        class="text-gray-600 line-through inline-block mr-2 text-sm"
      >
        ${{ variant.msrp.toFixed(2) }}
      </p>
      <p
        class="inline-block text-md font-medium"
        :class="{ 'text-red': variant.price < variant.msrp }"
        v-if="variant.price"
      >
        ${{ variant.price.toFixed(2) }}
      </p>
      <p class="text-gray-500 text-sm -mt-1">
        {{ variant.productNum }}
      </p>
    </div>

    <!-- Button -->
    <form
      @submit.prevent="addToPopup"
      class="bg-action w-16 h-16 rounded-br-xl rounded-tl-md absolute bottom-0 right-0 flex items-center justify-between text-2xl md:text-xl"
    >
      <button type="submit" class="iconBtn py-2 mr-2 text-3xl">
        <font-awesome-icon icon="cart-plus" />
      </button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    productInfo: Object,
    index: Number,
    length: Number,
    sortByCost: { type: Boolean, default: true },
  },
  data: function () {
    return {
      numForCart: 1,
      selectedVariant: 0,
      colors: new Map(),
    };
  },
  methods: {
    goToProductPage() {
      this.$store.dispatch('products/getSuggested');
      const filters = this.$store.getters['filter/getSelectedFilters'];
      const query = this.$store.getters['filter/getProcessedFilters'](
        filters,
        {
          ...this.$route.query,
        },
        false,
        {
          pindex: this.index,
          scrollTo: parseInt(window.scrollY),
        }
      );

      this.$router.push({
        name: this.$route.name,
        query,
      });
      this.$store.commit('products/setProduct', this.productInfo);

      this.$router.push({
        path: `/product/${this.productInfo._id}?variants=${
          this.productInfo.variants[this.selectedVariant]._id
        }`,
      });
    },
    addToPopup() {
      this.$store.commit('order/setProduct', this.productInfo);
    },
    changeVariant(index) {
      this.productInfo.image = null;
      this.selectedVariant = index;
    },
    uniqueColor(color, index) {
      if (this.colors.has(color)) {
        if (index === this.colors.get(color)) return true;
        return false;
      }
      //this makes it so that you can always so the price.
      if (index > 7) {
        return false;
      }
      this.colors.set(color, index);
      return true;
    },
    getVariantColor(color) {
      return this.$store.getters['filter/getVariantColor'](color);
    },
    setSelectedViaProductNum(productNum) {
      for (let i = 0; i < this.productInfo.variants.length; i++) {
        if (this.productInfo.variants[i].productNum.toLowerCase().includes(productNum)) {
          this.changeVariant(i);
          i = this.productInfo.variants.length;
        }
      }
    },
    sortVariantsByCost() {
      if (this.sortByCost)
        this.productInfo.variants = this.productInfo.variants.sort((a, b) => {
          return a.price - b.price;
        });
    },
  },
  computed: {
    getMargin() {
      if (this.index == 0) {
        return 'ml-8 sm:ml-4';
      }
      if (this.index == this.length - 1) {
        return 'mr-8 sm:mr-4';
      }
      return '';
    },
    getImage() {
      if (this.productInfo.image) {
        return this.productInfo.image;
      } else {
        return this.variant.image;
      }
    },
    variant() {
      return this.productInfo.variants[this.selectedVariant];
    },
  },
  mounted() {
    this.sortVariantsByCost();
    if (this.$route.query.title) {
      this.setSelectedViaProductNum(this.$route.query.title.toLowerCase());
    }
  },
};
</script>

<style>
img.variant {
  width: auto;
  height: auto;
}
</style>
