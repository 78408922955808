<template>
  <div class="flex justify-between border md:rounded-md overflow-hidden bg-white">
    <div class="whitespace-nowrap font-medium">
      <div class="text-white pt-1">
        <span class="bg-primary p-2 rounded-br-md">
          {{ notification.title }}
        </span>
      </div>
      <div class="text-sm text-gray-900 p-2">
        {{ notification.details }}
      </div>
    </div>

    <div class="flex flex-col divide-y divide-gray-200">
      <div class="flex-1 flex">
        <button
          type="button"
          @click="dismiss(notification)"
          class="w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-primary hover:text-action focus:outline-none focus:z-10"
        >
          dismiss
        </button>
      </div>
      <div class="flex-1 flex" v-if="notification.link">
        <button
          type="button"
          @click="link(notification)"
          class="w-full border border-transparent rounded-none rounded-br-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2"
        >
          go
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: Object,
  },
  methods: {
    dismiss() {
      this.notification.read = true;
      this.$store.dispatch('notifications/updateNotification', this.notification);
    },
    link(notification) {
      switch (notification.type) {
        case 3:
          this.$router.push('/admin/users/' + notification.link + '/company');
          break;
        case 0:
        case 1:
        case 2:
        case 4:
        case 6:
        case 7:
        case 8:
          this.$router.push('/admin/orders/' + notification.link);
          break;
        case 5:
          this.$router.push('/admin/products/' + notification.link);
          break;
        case 9:
        case 10:
          break;
        case 11:
          this.$router.push('/product/' + notification.link);
          break;
        default:
      }
    },
  },
};
</script>

<style></style>
