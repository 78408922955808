<template>
  <div
    class="text-center text-white text-xl sm:text-sm bg-gray-700 py-5 sm:py-6 px-4 sm:px-12 md:px-14 lg:px-40 shadow-md md:relative"
  >
    <div class="grid grid-cols-2 sm:grid-cols-3">
      <div class="mb-6 hidden sm:block sm:mb-0 text-left md:max-w-xs md:pr-8 sm:pr-12">
        <div class="text-base sm:text-lg">Hours of Operation</div>
        <div v-if="siteSettings" class="text-xs">
          <div class="flex my-1 sm:my-0 justify-between">
            <div class="">Monday:</div>
            <div v-if="siteSettings.hoursOfOperation[1].open != 'closed'">
              {{ convertNumberToTime(siteSettings.hoursOfOperation[1].open) }} -
              {{ convertNumberToTime(siteSettings.hoursOfOperation[1].close) }}
            </div>
            <div v-else>closed</div>
          </div>
          <div class="flex my-1 sm:my-0 justify-between">
            <div class="w-12">Tuesday:</div>
            <div v-if="siteSettings.hoursOfOperation[2].open != 'closed'">
              {{ convertNumberToTime(siteSettings.hoursOfOperation[2].open) }} -
              {{ convertNumberToTime(siteSettings.hoursOfOperation[2].close) }}
            </div>
            <div v-else>closed</div>
          </div>
          <div class="flex my-1 sm:my-0 justify-between">
            <div>Wednesday:</div>
            <div v-if="siteSettings.hoursOfOperation[3].open != 'closed'">
              {{ convertNumberToTime(siteSettings.hoursOfOperation[3].open) }} -
              {{ convertNumberToTime(siteSettings.hoursOfOperation[3].close) }}
            </div>
            <div v-else>closed</div>
          </div>
          <div class="flex my-1 sm:my-0 justify-between">
            <div>Thursday:</div>
            <div v-if="siteSettings.hoursOfOperation[4].open != 'closed'">
              {{ convertNumberToTime(siteSettings.hoursOfOperation[4].open) }} -
              {{ convertNumberToTime(siteSettings.hoursOfOperation[4].close) }}
            </div>
            <div v-else>closed</div>
          </div>
          <div class="flex my-1 sm:my-0 justify-between">
            <div>Friday:</div>
            <div v-if="siteSettings.hoursOfOperation[5].open != 'closed'">
              {{ convertNumberToTime(siteSettings.hoursOfOperation[5].open) }} -
              {{ convertNumberToTime(siteSettings.hoursOfOperation[5].close) }}
            </div>
            <div v-else>closed</div>
          </div>
          <div class="flex my-1 sm:my-0 justify-between">
            <div>Closed:</div>
            <div>
              {{ siteSettings.closed }}
            </div>
          </div>
        </div>
      </div>
      <router-link tag="div" to="/" class="text-center hidden sm:block">
        <img src="/images/icons/Logo-Tertiary-White.svg" alt="Logo" class="sm:hidden" />
        <img
          src="/images/icons/Logo-Secondary-White.svg"
          alt="Logo"
          class="hidden mx-auto sm:block"
          width="250px"
        />
      </router-link>

      <div class="col-span-2 sm:col-span-1 text-center sm:mt-2 mb-6 sm:mb-0 sm:text-right">
        <router-link to="/about">About us</router-link>
        <router-link to="/contact">Contact</router-link>
        <router-link to="/privacy">Privacy</router-link>
        <router-link to="/return">Return</router-link>
      </div>
    </div>
    <div class="text-sm font-semibold">Page Custom Supply &#169; 2022</div>
    <a href="https://prodevs.us/" class="text-xs text-gray-200"
      >Website created by: Seth Hinton & Gabriel Suttner</a
    >
  </div>
</template>

<script>
export default {
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  methods: {
    convertNumberToTime(value) {
      if (value) {
        const date = new Date();
        date.setHours(value.slice(0, value.indexOf(':')));

        date.setMinutes(value.slice(value.indexOf(':') + 1, value.length));
        return date.toLocaleString('en-US', { timeStyle: 'short' }).toLowerCase();
      }
    },
  },
};
</script>
<style scoped>
a {
  display: block;
  margin-top: 0.25rem;
  color: white;
}
</style>
