<template>
  <div
    class="w-40 h-48 bg-white shadow-md mx-1 sm:mx-3 overflow-hidden my-4 rounded-xl cursor-pointer font-medium flex flex-col"
    @click="reRoute"
    :class="getMargin"
  >
    <div class="w-full h-32 overflow-hidden mx-auto flex items-center justify-center">
      <img v-lazy="tag.image" :alt="`Image for ${tag.name}`" class="object-cover" />
    </div>
    <div class="px-2 text-center flex-grow flex items-center justify-center whitespace-pre-wrap">
      {{ tag.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tag: Object,
    index: Number,
    length: Number,
  },
  computed: {
    getMargin() {
      if (this.index == 0) {
        return 'ml-4';
      } else if (this.index == this.length - 1) {
        return 'mr-5';
      } else {
        return '';
      }
    },
  },
  methods: {
    reRoute() {
      //redirect to the product list page and populate the page with the products that fall under the subcategory
      if (this.$route.name === 'Subcategories') {
        this.$router.push({
          name: 'Category Product',
          query: {
            type: 'normal',
            categories: this.$route.params.sub,
            subcategories: this.tag.name,
          },
        });
      } else {
        //redirect to the categories page with the selected subcategory.
        // this.$store.dispatch('tags/getCategories', `type=Subcategory&category=${this.tag.name}`)
        this.$router.push({ name: 'Subcategories', params: { sub: this.tag.name } });
      }
    },
  },
};
</script>

<style scoped></style>
