<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="">
    <div class="px-4 pt-12 sm:px-6 lg:px-8 lg:pt-10"></div>

    <div class="mt-8 bg-white pb-12 lg:pb-20">
      <div class="relative z-0">
        <!-- <div class="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div> -->
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="relative lg:grid lg:grid-cols-7">
            <!-- Start First Card -->
            <div
              class="mx-auto max-w-md lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3 lg:ml-0 lg:max-w-none lg:mt-20 lg:-mr-3"
            >
              <div class="flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-xl">
                <div v-if="loading" class="bg-gray-400 animate-pulse h-128 w-full"></div>
                <div class="flex flex-1 flex-col" v-else>
                  <div
                    class=""
                    :style="`background-image: url(${firstCard.imgPath}; background-position: center; background-size: cover; height:400px`"
                  ></div>
                  <div
                    class="flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-100 p-6 sm:p-10 lg:p-6 xl:p-10"
                  >
                    <div>
                      <h3 class="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                        {{ firstCard.title }}
                      </h3>
                      <div
                        class="mt-4 flex items-center justify-center text-center"
                        v-html="firstCard.content"
                      />
                    </div>
                    <div class="mt-8" v-if="firstCard.link">
                      <div class="rounded-lg shadow-md">
                        <button
                          @click="route(firstCard)"
                          class="block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-primary hover:bg-gray-100"
                          aria-describedby="tier-hobby"
                        >
                          {{ firstCard.subtitle }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End First Card -->

            <!-- Start Second Card -->
            <div
              class="mx-auto mt-10 max-w-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-0 lg:max-w-none"
            >
              <div
                v-if="loading"
                class="bg-gray-400 animate-pulse h-128 w-full rounded-xl shadow-xl"
              ></div>

              <div v-else class="relative z-10 rounded-xl shadow-xl overflow-hidden bg-gray-100">
                <div
                  class="pointer-events-none absolute inset-0 rounded-xl border-2 border-gray-100 overflow-hidden"
                  aria-hidden="true"
                />

                <div class="flex flex-1 flex-col">
                  <div
                    class=""
                    :style="`background-image: url(${secondCard.imgPath}; background-position: center; background-size: cover; height:400px`"
                  />
                  <div class="flex flex-1 flex-col justify-between p-6 sm:p-10 lg:p-6 xl:p-10">
                    <div>
                      <h3 class="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                        {{ secondCard.title }}
                      </h3>
                      <div
                        class="mt-4 flex items-center justify-center text-center"
                        v-html="secondCard.content"
                      />
                    </div>
                    <div class="rounded-lg shadow-md mt-8" v-if="secondCard.link">
                      <button
                        @click="route(secondCard)"
                        class="block w-full rounded-lg border border-transparent bg-action text-white px-6 py-3 text-center text-base font-medium hover:bg-gray-100 hover:text-primary"
                        aria-describedby="tier-hobby"
                      >
                        {{ secondCard.subtitle }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Second Card -->

            <!-- Start Third Card -->
            <div
              class="mx-auto mt-10 max-w-md lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3 lg:-ml-3 lg:mr-0 lg:max-w-none lg:mt-20"
            >
              <div v-if="loading" class="bg-gray-400 animate-pulse h-128 w-full rounded-xl"></div>

              <div
                v-else
                class="flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-xl"
              >
                <div class="flex flex-1 flex-col">
                  <div
                    class=""
                    :style="`background-image: url(${thirdCard.imgPath}; background-position: center; background-size: cover; height:400px`"
                  ></div>
                  <div
                    class="flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-100 p-6 sm:p-10 lg:p-6 xl:p-10"
                  >
                    <div>
                      <h3 class="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                        {{ thirdCard.title }}
                      </h3>
                      <div
                        class="mt-4 flex items-center justify-center text-center"
                        v-html="thirdCard.content"
                      />
                    </div>
                    <div class="mt-8" v-if="thirdCard.link">
                      <div class="rounded-lg shadow-md">
                        <button
                          @click="route(thirdCard)"
                          class="block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-primary hover:bg-gray-100"
                          aria-describedby="tier-hobby"
                        >
                          {{ thirdCard.subtitle }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Third Card -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    layouts() {
      return this.$store.getters
        .getLayouts('Home Page')
        .filter((el) => el.template == 'Announcement');
    },
    firstCard() {
      return !this.layouts ? null : this.layouts[0] ? this.layouts[0] : null;
    },
    secondCard() {
      return !this.layouts ? null : this.layouts[1] ? this.layouts[1] : null;
    },
    thirdCard() {
      return !this.layouts ? null : this.layouts[2] ? this.layouts[2] : null;
    },
    loading() {
      return this.$store.getters['getLayoutLoad'];
    },
  },
  methods: {
    route(card) {
      if (card.link == null) {
        return;
      }
      if (card.link.includes('{')) {
        //for objects
        const link = JSON.parse(card.link);
        this.$router.push(link);
        return;
      }
      location.href = card.link;
    },
  },
};
</script>

<style></style>
