import axios from 'axios';
export default {
  namespaced: true,
  state: {
    tags: null,
    tagSet: null,
    showPriceChangeEmailer: false,
    vendor: null,
    percentage: 0,
  },
  mutations: {
    /**
     *
     * @param {*} state
     * @param {*} data
     * This function will clear out all of the previously contained details because this function should
     * be called when the admin page loads. This is needed because there are so many different places
     * tags are required, and this should decrease the load on the server. However, because different customers
     * will only use the type=Category tags and one or two subcategories at a time, we don't need to send all of
     * the categories for the customers.
     */
    setTags(state, data) {
      state.tags = data;
      state.tagSet = new Set();
      data.forEach((el) => {
        state.tagSet.add(el._id);
      });
    },
    replaceTag(state, data) {
      if (state.tagSet.has(data._id)) {
        state.tags.forEach((el) => {
          if (el._id == data._id) {
            el = data;
          }
        });
      } else {
        state.tagSet.add(data._id);
        state.tags.push(data);
      }
    },
    setPriceChangeEmailer(state, data) {
      state.showPriceChangeEmailer = data;
    },
    /**
     *
     * @param {*} state
     * @param {Object} data
     * @param {String} data.name - Name of the vendor
     * @param {String} data.percentage - Percentage the vendor was changed to.
     */
    setVendor(state, data) {
      state.vendor = data.name;
      state.percentage = data.percentage;
    },
    setNewTags(state, data) {
      if (!state.tagSet) {
        //this portion will run if there are no tags present.
        state.tags = data;
        state.tagSet = new Set();
        data.forEach((el) => {
          state.tagSet.add(el._id);
        });
      } else {
        data.forEach((el) => {
          if (!state.tagSet.has(el._id)) {
            state.tagSet.add(el._id);
            state.tags.push(el);
          }
        });
      }
      window.sessionStorage.setItem('categoryTags', JSON.stringify(state.tags));
    },
  },
  getters: {
    sortTags: (state) => {
      let tags = state.tags;

      if (tags) {
        tags = tags.sort((a, b) => {
          if (a.name && b.name) return a.name.localeCompare(b.name);
        });

        // if (rootstate.user && rootGetters.getCommercial) {
        //   tags = tags.sort((a, b) => a.orderOther - b.orderOther);
        // } else {
        //   tags = tags.sort((a, b) => a.orderRetail - b.orderRetail);
        // }
      } else {
        tags = [];
      }
      return tags;
    },
    /**
     * @param {type, value}
     */
    getTags: (state, getters) => (param) => {
      let tags = getters.sortTags;
      if (!tags) {
        return [];
      }
      if (!param.type) {
        return tags;
      }
      tags = tags.filter((el) => {
        return el.type === param.type;
      });

      if (param.type === 'Subcategory') {
        if (param.value)
          return tags.filter((el) => {
            return el.category === param.value;
          });
      }

      return tags;
    },
    getPriceChangeEmailer(state) {
      return state.showPriceChangeEmailer;
    },
    getVendor(state) {
      return state.vendor;
    },
    getPercentage(state) {
      return state.percentage;
    },
  },
  actions: {
    async sendPriceIncreaseEmail({ commit }, payload) {
      try {
        await axios.post('/api/v1/site/increase', payload);
        commit(
          'setError',
          {
            message: `Successfully sent email`,
            color: 'green',
            duration: 10000,
          },
          { root: true }
        );
        commit('setPriceChangeEmailer', false);
        commit('setVendor', { name: null, percentage: 0 });
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error sending email',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async sendPriceDecreaseEmail({ commit }, payload) {
      try {
        await axios.post('/api/v1/site/decrease', payload);
        commit(
          'setError',
          {
            message: `Successfully sent email`,
            color: 'green',
            duration: 10000,
          },
          { root: true }
        );
        commit('setPriceChangeEmailer', false);
        commit('setVendor', { name: null, percentage: 0 });
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error sending email',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    /**
     * @param {*} payload.name Name of the vendor.
     * @param {*} payload.percentage Percentage you would like the product to increase by of the vendor.
     */
    async alterVendorPricing({ commit }, payload) {
      try {
        await axios.put('/api/v1/tags/vendor', payload);

        commit(
          'setError',
          {
            message: `Successfully altered ${payload.name}`,
            color: 'green',
            duration: 10000,
          },
          { root: true }
        );
        commit('setPriceChangeEmailer', true);
        commit('setVendor', payload);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error updating vendor prices',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    /**
     * This function is only called when the admin page is loaded. It will return all tags that have
     * an accompnaying name. It should only be called on the admin page because most of the tags being returned
     * are only useful on the admin page.
     * @param {*} param0
     */
    async getAllTags({ commit }) {
      const tags = JSON.parse(window.sessionStorage.getItem('allTags'));
      try {
        if (tags) {
          commit('setTags', tags);
        } else {
          let response = await axios.get('/api/v1/tags/');
          commit('setTags', response.data);
          window.sessionStorage.setItem('allTags', JSON.stringify(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * This will retreive more specific tags than the ones retrieved in all tags. This is good because
     * the data being returned will be a much smaller package.
     * @param {*} store
     * @param {String} payload containing the search paramaters desired, type=Category&category=X
     */
    async getCategories({ commit }, payload) {
      try {
        window.sessionStorage.clear();

        let response = await axios.get('/api/v1/tags/categories?' + payload);
        commit('setNewTags', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async updateCategoryOrder({ commit }) {
      try {
        await axios.put('/api/v1/tags/categories');
        commit(
          'setError',
          {
            message: 'Successfully updated categories and subcategories',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Failed to update categories',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
    },

    async saveVendor({ commit }, payload) {
      try {
        window.sessionStorage.clear();
        const response = await axios.patch('/api/v1/tags/vendor', payload);
        commit('setTags', response.data);
        commit(
          'setError',
          {
            message: 'Saved Tag',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error adding or updating Tag',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async addNewTag({ commit }, payload) {
      try {
        window.sessionStorage.clear();
        const response = await axios.patch('/api/v1/tags/', payload);
        commit('replaceTag', response.data);

        commit(
          'setError',
          {
            message: 'Saved Tag',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error adding or updating Tag',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async deleteTag({ commit }, payload) {
      try {
        window.sessionStorage.clear();
        const response = await axios.delete('/api/v1/tags/' + payload);
        commit('setTags', response.data);
        commit(
          'setError',
          {
            message: 'Successfully deleted Tag',
            color: 'green',
          },
          { root: true }
        );
        window.location;
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error deleting Tag',
            color: 'red',
          },
          { root: true }
        );
      }
    },
  },
};
