<template>
  <div>
    <Announcements />

    <MembershipInformation v-if="user == null" />

    <!-- Categories -->
    <div class="sm:px-12 md:px-24">
      <div class="flex items-center justify-between md:justify-start mx-6 mt-8">
        <h4 class="md:mr-12">Categories</h4>
        <router-link :to="{ path: '/categories' }">View all</router-link>
      </div>

      <CategoryCarousel />

      <!-- Favorites -->
      <div v-if="favorites && favorites.length > 0">
        <div class="flex items-center justify-between md:justify-start mx-6 mt-8">
          <h4 class="md:mr-12">Favorites</h4>
          <router-link to="/user/favorites"> View all </router-link>
        </div>
        <ProductCarousel :products="favorites" :loading="false" :sortByCost="false" />
      </div>

      <!-- Recent Purchases -->
      <div v-if="recentlyPurchased != null && recentlyPurchased.length > 0">
        <div class="flex items-center justify-between md:justify-start mx-6 mt-8">
          <h4 class="md:mr-12">Recently Purchased</h4>
          <router-link :to="{ path: '/products', query: { type: 'Recent Purchases' } }">
            View all
          </router-link>
        </div>

        <ProductCarousel :products="recentlyPurchased" :loading="false" />
      </div>

      <!-- Featured Items -->
      <div class="flex items-center justify-between md:justify-start mx-6 mt-8">
        <h4 class="md:mr-12">Featured</h4>
        <router-link :to="{ path: '/products', query: { tags: 'Featured' } }">
          View all
        </router-link>
      </div>

      <ProductCarousel :products="featured" :loading="featuredLoading" />

      <div
        v-if="
          user &&
          (user.email === 'pagehardware4less@gmail.com' ||
            user.email === 'gabrielsuttner@gmail.com')
        "
      >
        <InformationSection />
      </div>
    </div>
  </div>
</template>

<script>
import MembershipInformation from '@/components/home/MembershipInformation';
import ProductCarousel from '@/components/items/ProductCarousel';
import CategoryCarousel from '@/components/categories/CategoryCarousel';
import InformationSection from '@/components/home/InformationSection';
import Announcements from '@/layouts/Announcements';

export default {
  components: {
    MembershipInformation,
    ProductCarousel,
    CategoryCarousel,
    Announcements,
    InformationSection,
  },
  computed: {
    featured() {
      return this.$store.getters['products/getFeatured'];
    },
    featuredLoading() {
      return this.$store.getters['products/getFeaturedLoading'];
    },
    recentlyPurchased() {
      return this.$store.getters['products/getRecentlyPurchased'](25);
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    favorites() {
      return this.$store.getters['products/getFavorites'](10);
    },
  },
  methods: {},
  watch: {},
  created() {
    this.$store.dispatch('products/initialSetup');
  },
};
</script>
<style></style>
