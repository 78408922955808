<template>
  <div id="app" class="font-main text-gray-800 w-full mb-20 sm:mb-0">
    <Banner v-if="banners" :layouts="banners" v-show="showBanner"></Banner>
    <Navigation-Bar></Navigation-Bar>

    <router-view :key="$route.path" />

    <SiteFooter></SiteFooter>

    <transition :name="getTransition">
      <FeedbackPopup
        v-if="error"
        class="text-center z-100"
        :color="error.color"
        :duration="error.duration"
        @goAway="$store.state.error = null"
        >{{ error.message }}
      </FeedbackPopup>
    </transition>

    <transition :name="getTransition">
      <FeedbackPopup
        v-if="addedProduct"
        :duration="3500"
        color="white"
        @goAway="$store.commit('order/clearAddedProduct')"
      >
        <div class="relative flex items-center text-gray-800 px-4 md:px-6">
          <div
            class="overflow-hidden shadow rounded-lg h-12 w-12 p-2 flex items-center justify-center"
          >
            <img v-lazy="addedProduct.image" alt="" />
          </div>
          <div class="text-left pl-4 w-56 sm:w-64 md:w-80">
            <h4 class="truncate">{{ addedProduct.name }}</h4>
            <div class="truncate">{{ addedProduct.productNum }}</div>
          </div>
          <div class="">
            <!-- Start: right-4 md:right-6 bottom-0  -->
            <!-- End: right-1 md:right-2 bottom-1  -->
            <div
              id="cartQty"
              class="absolute right-1 md:right-2 bottom-1 transition-all duration-700 mb-1 mr-1 bg-red-400 rounded-full flex items-center justify-center h-5 w-5 text-white text-sm"
            >
              {{ addedProduct.quantity }}
            </div>
            <div class="absolute right-1 md:right-2 bottom-0 mb-1" id="cart">
              <img src="/images/icons/Cart.svg" alt="Cart Icon" width="33px" />
            </div>
            <div
              class="absolute right-1 text-center -bottom-1 text-sm"
              style="width: 65px"
              v-if="$store.state.order.selectedCartIndex"
            >
              {{ cartName.slice(0, 5) }}
            </div>
          </div>
        </div>
      </FeedbackPopup>
    </transition>
    <div
      class="fixed bottom-5 sm:bottom-0 w-full bg-primary-alt sm:z-10 shadow-blur-glow px-5 sm:px-8 py-4"
      v-if="showDisclaimer"
    >
      <div class="relative w-full">
        Page Custom Supply uses Cookies for security and to better your experience. By using this
        site you agree to our terms of use found on our
        <router-link to="/privacy">privacy policy</router-link>.

        <button
          @click="closeDisclaimer"
          class="absolute mr-2 sm:mr-0 -right-2 -top-1 rounded-sm hover:bg-red-300 px-3 py-1"
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>
    </div>

    <transition name="modal">
      <Product v-if="product" @close="clearProduct" />
    </transition>
    <transition name="expandLeft">
      <FloatingNotifications />
    </transition>

    <div
      class="hidden text-yellow-500 text-teal-500 text-blue-500 text-green-500 text-orange-400 border-red-500 border-green-500 border-purple-500 border-gray-500 border-t-8 rounded-l-xl"
    >
      This div is where we can put any classes that are being stripped by compression
    </div>
  </div>
</template>

<script>
// import DesktopNav from '@/components/shared/DesktopNav';
import NavBar from '@/components/shared/NavBar';

import Banner from '@/layouts/Banner';
import SiteFooter from '@/components/shared/SiteFooter';
import FeedbackPopup from '@/components/shared/FeedbackPopup';

import Product from '@/components/items/ProductSelect';
import FloatingNotifications from '@/components/user/notifications/FloatingNotifications.vue';

export default {
  data: () => ({
    firstLoadOfSite: true,
    code: '',
    showBanner: true,
    largeScreen: window.innerWidth >= 600,
    showDisclaimer: true,
  }),

  metaInfo() {
    return {
      title: 'Online Hardware Supply Store | Cabinet Hardware | Page Custom Supply',
      meta: [
        {
          name: 'description',
          content:
            'Page Custom Supply is your go-to Online Hardware Supply Store for your Cabinet Hardware needs: knobs and pulls, hinges, drawer slides... Click here to shop our inventory!',
        },
        { name: 'keywords', content: 'cabinet hardware store, online hardware supply' },
      ],
    };
  },

  computed: {
    addedProduct() {
      return this.$store.getters['order/getAddedProduct'];
    },
    cartName() {
      return this.$store.state.order.cartName;
    },
    error() {
      return this.$store.state.error;
    },
    layouts() {
      return this.$store.state.layouts;
    },
    banners() {
      let banners = this.$store.state.layouts.filter((t) => {
        return t.template == 'Banner';
      });
      return banners;
    },
    getTransition() {
      if (this.largeScreen) {
        return 'coverUpPopup';
      } else {
        return 'coverDownPopup';
      }
    },
    product() {
      return this.$store.getters['order/getProduct'];
    },
    isAppIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    site_version() {
      return this.$store.state.site_version;
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    authorized() {
      return this.$store.getters['getAuthorized'];
    },
    isLoggedIn() {
      return this.$store.getters['getIsLoggedIn'];
    },
  },
  watch: {
    site_version() {
      this.refreshPrompt();
    },

    $route() {
      this.changeBanner();
    },

    isAppIdle() {
      if (this.isAppIdle && this.authorized) {
        this.$store.dispatch('logoutIfAdmin');
      }
    },
  },
  methods: {
    //prompts the user to refresh the page if needed
    refreshPrompt() {
      if (!this.$store.getters.getSiteVersion) {
        this.$store.commit('setError', {
          message:
            'The site has been updated. Please refresh your browser by pressing Ctrl + Shift + R on Windows or Cmd + Shift + R on Mac',
          color: 'blue',
          duration: 0,
        });
      }
    },
    clearProduct() {
      this.$store.commit('order/setProduct', null);
    },
    closeDisclaimer() {
      window.localStorage.setItem('acceptedDisclaimer', true);
      this.showDisclaimer = false;
    },
    changeBanner() {
      switch (this.$route.name) {
        case 'Home':
        case 'About':
        case 'Privacy Policy':
        case 'Return Policy':
        case 'Search':
        case 'Product':
        case 'Products':
        case 'Categories':
        case 'Subcategories':
        case 'Category Product':
        case 'contact':
        case 'Checkout':
        case 'Learn More Commercial':
        case 'Learn More Retail':
        case 'Commercial Sign Up':
          this.showBanner = true;
          break;
        default:
          this.showBanner = false;
      }
    },
    getSiteVersion() {
      this.$store.dispatch('getSiteVersion');
      setInterval(() => {
        this.$store.dispatch('getSiteVersion');
        if (this.isLoggedIn) {
          this.$store.dispatch('notifications/getMostRecent');
        }
      }, 600000);
    },

    // check_webp_feature:
    //   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
    //   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
    checkWebP(callback) {
      var webP = new Image();
      webP.onload = webP.onerror = function () {
        callback(webP.height == 2);
      };
      webP.src =
        'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    },
    setCategories() {
      const categories = JSON.parse(window.sessionStorage.getItem('categoryTags'));

      if (categories) {
        this.$store.commit('tags/setTags', categories);
      } else {
        this.$store.dispatch('tags/getCategories', 'type=Category');
      }
    },
    setOpenPopup() {
      const currentTime = new Date();
      const today = this.siteSettings.hoursOfOperation[currentTime.getDay()];
      const closingTime = new Date(currentTime.toISOString().slice(0, 10) + ' ' + today.close);
      let prompt = '';
      let promptColor = 'blue';
      const dayOpen = 'Tuesday';

      if (currentTime < closingTime) {
        prompt = `Welcome to PCS, we are open until ${closingTime
          .toLocaleString('en-US', { timeStyle: 'short' })
          .toLowerCase()} today`;
        promptColor = 'green';
      } else {
        prompt = `Welcome to PCS, we are currently closed. We will open at ${today.open} am on ${dayOpen}`;
      }
      this.$store.commit('setError', {
        message: prompt,
        color: promptColor,
        duration: 6000,
      });
    },
    isOpen() {},
    isOpenClasses() {
      if (this.isOpen().includes('open until')) {
        return 'text-primary';
      } else {
        return 'text-action';
      }
    },
    getColors() {
      const colors = JSON.parse(window.sessionStorage.getItem('colorFilter'));
      if (colors) {
        this.$store.commit('filter/setFilterTemplates', [colors]);
      } else {
        this.$store.dispatch('filter/getSingleTemplate', '?name:%20Color');
      }
    },
  },
  components: {
    // 'Desktop-Navigation': DesktopNav,
    'Navigation-Bar': NavBar,
    SiteFooter,
    FeedbackPopup,
    Banner,
    Product,
    FloatingNotifications,
  },

  async created() {
    const cookie = document.cookie;

    if (cookie.includes('token')) {
      this.$store.dispatch('getUser');
    }
    this.$store.dispatch('products/getSearchArray');
    this.$store.dispatch('admin/getLayouts');
    this.getSiteVersion();
    this.changeBanner();
    this.setCategories();
    this.getColors();
    addEventListener('resize', () => {
      this.largeScreen = innerWidth >= 600;
    });

    //check if the browser supports webp if it does then webp will be loaded else, jpg will be loaded.
    this.checkWebP(function (support) {
      window.$webpSupport = support;
    });

    const acceptedDisclaimer = window.localStorage.getItem('acceptedDisclaimer');
    if (acceptedDisclaimer === 'true') {
      this.showDisclaimer = false;
    }
    this.$store.dispatch('order/getShippingCosts');
  },
};
</script>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
}
.scrollbar-track-gray-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1 !important;
  background-color: #e2e8f0 !important;
  background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
}
.scrollbar-thumb-gray::-webkit-scrollbar-thumb {
  --bg-opacity: 1 !important;
  background-color: #cbd5e0 !important;
  background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
}
.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem !important;
}

.flip {
  transform: rotate(180deg);
}
.lock {
  background: linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.4),
      rgba(27, 27, 27, 0.4),
      rgba(65, 65, 65, 0.4),
      rgba(78, 78, 78, 0.3),
      rgba(167, 167, 167, 0.3)
    ),
    #1a202c;
  min-height: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
}

.feedback-popup {
  z-index: 10000;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  /* This keeps content behind from scrolling */
  overscroll-behavior: contain;
}

img[lazy='loading'] {
  width: 50px;
  height: 50px;
  animation: 1s infinite spin;
}
img[lazy='loaded'] {
  width: 100%;
  height: 100%;
}
img[lazy='error'] {
  width: 110px;
}

img {
  transition-property: all;
  transition-duration: 150ms;
}

.trash-can {
  display: none;
}
.trash-can-wrapper:hover .trash-can {
  display: block;
}

#cartQty {
  /* display: inline-block; */
  /* animation: 3s ease-in-out infinite alternate addToCart; */
  /* transform: translate(100%); */
  animation: 1.5s linear addToCart;
}

.tooltip {
  position: relative;
  display: block;
  cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  /* top: -5px; */
  /* right: 105%; */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Tooltip text */
.tooltip .tooltiptext-right {
  visibility: hidden;
  background-color: white;
  top: -5px;
  left: 105%;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext-right {
  visibility: visible;
}

@keyframes addToCart {
  from,
  70% {
    right: 4rem;
    bottom: 0;
  }
  80% {
    right: 3.5rem;
    bottom: 1rem;
  }
  90% {
    right: 2rem;
    bottom: 2.1rem;
  }
  95% {
    right: 1.2rem;
    bottom: 1.5rem;
  }
  to {
    right: 1rem;
    bottom: 1rem;
  }
}

/* For anything larger than iPad */
@media only screen and (min-width: 900px) {
  @keyframes addToCart {
    from,
    75% {
      right: 6rem;
      bottom: 0;
    }
    80% {
      right: 5.5rem;
      bottom: 1rem;
    }
    90% {
      right: 3rem;
      bottom: 2.1rem;
    }
    95% {
      right: 2.2rem;
      bottom: 1.5rem;
    }
    to {
      right: 2rem;
      bottom: 1rem;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  animation: 1.7s ease-in-out infinite pulse;
}

/* 
	ANIMATIONS
*/

/* Slide Left transition */
.slideLeft-leave-active {
  transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.2, 1.25);
  position: absolute;
  z-index: -2;
  overflow: hidden;
}
.slideLeft-leave-to {
  transform: translateX(-100vw);
}
.slideLeft-enter {
  transform: translateX(100vw);
}
.slideLeft-enter-active {
  transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.2, 1.25);
  overflow: hidden;
}

/* Slide Right transition */
.slideRight-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.2, 1.25);
  position: absolute;
  z-index: -2;
  overflow: hidden;
  background: black;
}
.slideRight-leave-to {
  transform: translateX(100vw);
  /* background-position: -100vw; */
}
.slideRight-enter {
  transform: translateX(-100vw);
  /* background-position: -100vw; */
}
.slideRight-enter-active {
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.2, 1.25);
  overflow: hidden;
  background: black;
}

/* SlideUp transition */
.slideUp-enter-active {
  transition: transform 0.6s ease, opacity 0.6s ease;
}
.slideUp-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.slideUp-enter,
.slideUp-leave-to {
  opacity: 0;
  transform: translateY(200px);
}

/* CoverUp transition */
.coverUp-enter-active {
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}
.coverUp-leave-active {
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.coverUp-leave-to {
  opacity: 0.5;
  position: absolute;
}
.coverUp-enter {
  transform: translateY(100vh);
}

/* CoverDown transition */
.coverDown-enter-active {
  transition: transform 0.01s ease, opacity 0.01s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.coverDown-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}
.coverDown-leave-to {
  transform: translateY(100vh);
  /* opacity: 0; */
}

/* CoverUpPopup transition */
.coverUpPopup-enter-active,
.coverUpPopup-leave-active {
  transition: transform 0.2s ease-in-out;
}
.coverUpPopup-enter,
.coverUpPopup-leave-to {
  transform: translateY(100vh);
  /* opacity: 0; */
}

/* CoverDownPopup transition */
.coverDownPopup-enter-active,
.coverDownPopup-leave-active {
  transition: transform 0.2s ease-in-out;
}
.coverDownPopup-enter,
.coverDownPopup-leave-to {
  transform: translateY(-40vh);
  /* opacity: 0; */
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Fade fast transition */
.fadeFast-enter-active,
.fadeFast-leave-active {
  transition: opacity 0.2s ease;
}

.fadeFast-enter,
.fadeFast-leave-to {
  opacity: 0;
}

/* Fade page transition */
.fadePage-enter-active,
.fadePage-leave-active {
  transition: filter 0.2s ease, opacity 0.3s ease;
  position: absolute;
  left: 0;
  right: 0;
}
.fadePage-leave-to,
.fadePage-enter {
  filter: brightness(0%);
  opacity: 0;
}

/* Scale down transition
	To be used on position: absolute dropdowns
*/
.scaleDown-enter,
.scaleDown-leave-to {
  transform: scaleY(0);
}
.scaleDown-leave,
.scaleDown-enter-to {
  transform: scaleY(1);
}
.scaleDown-enter-active,
.scaleDown-leave-active {
  transition: transform 0.08s;
  transform-origin: top;
}

/* Scale up transition
	To be used on position: absolute scaleups
*/
.scaleUp-enter,
.scaleUp-leave-to {
  transform: scaleY(0);
}
.scaleUp-leave,
.scaleUp-enter-to {
  transform: scaleY(1);
}
.scaleUp-enter-active,
.scaleUp-leave-active {
  transition: transform 0.08s;
  transform-origin: bottom;
}

/* Modal transition
  To be use with the ModalFeedback component
*/
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Product transition */
.product-enter-active,
.product-leave-active {
  transition: opacity 0.2s ease;
}

.product-enter,
.product-leave-to {
  opacity: 0;
}

/* Expand left transition (for search bar) */
.expandLeft-enter,
.expandLeft-leave-to {
  max-width: 0;
  z-index: 1;
}
.expandLeft-enter-to,
.expandLeft-leave {
  max-width: 54vw;
}

.expandLeft-enter-active,
.expandLeft-leave-active {
  transition: max-width 0.07s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

/* Expand right transition (for quickEdit screen) */
.expandRight-enter,
.expandRight-leave-to {
  transform: translateX(100vw);
  z-index: 1;
}
.expandRight-enter-active,
.expandRight-leave-active {
  transition: transform 0.12s ease-in-out;
  position: absolute;
}

/* Expand down - similar to scaleDown, but not for absolute elements */
.expandDownSmall-enter,
.expandDownSmall-leave-to {
  max-height: 0px;
}
.expandDownSmall-enter-to,
.expandDownSmall-leave {
  max-height: 3rem;
}
.expandDownSmall-enter-active,
.expandDownSmall-leave-active {
  overflow: hidden;
  transition: max-height 0.2s;
}

.expandDownLarge-enter,
.expandDownLarge-leave-to {
  max-height: 0px;
}
.expandDownLarge-enter-to,
.expandDownLarge-leave {
  max-height: 14rem;
}
.expandDownLarge-enter-active,
.expandDownLarge-leave-active {
  overflow: hidden;
  transition: max-height 0.2s;
}

.icon {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-arrows[type='number'] {
  -moz-appearance: textfield;
}
</style>
