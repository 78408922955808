/* eslint-disable no-unused-vars */
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    reviews: [],
  },
  mutations: {},
  getters: {
    getReviews: ({ reviews }) => reviews,
  },
  actions: {
    async getReviews({ commit }) {
      try {
        const response = await axios.get('/api/v1/reviews/');
        commit('setReviews', response.data.reviews);
      } catch (error) {
        console.log(error);
      }
    },
    async createReview({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/reviews/', payload);
        commit('products/updateReviews', response.data, { root: true });
      } catch (error) {
        console.log(JSON.stringify(error));
        if (error.message.includes('403')) {
          commit(
            'setError',
            {
              message: 'Only signed in users can leave ratings. Please sign in and try again.',
              color: 'red',
              duration: 10000,
            },
            { root: true }
          );
        } else {
          commit(
            'setError',
            {
              message: 'Could not post a review',
              color: 'red',
            },
            { root: true }
          );
          console.log(error);
        }
      }
    },

    async deleteReview({ commit }, payload) {
      try {
        const response = await axios.delete('/api/v1/reviews/' + payload);
        commit('products/updateReviews', response.data, { root: true });
      } catch (error) {
        console.log(JSON.stringify(error));
        commit(
          'setError',
          {
            message: 'Could not post a review',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
    },

    async updateReview({ commit }, payload) {
      try {
        await axios.put('/api/v1/reviews', payload);
      } catch (error) {
        console.log(JSON.stringify(error));
        commit(
          'setError',
          {
            message: 'Could not post a review',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
    },
  },
};
