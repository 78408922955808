import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';

const Contact = () => import('../views/Contact.vue');
const About = () => import('../views/About.vue');
const Privacy = () => import('../views/PrivacyPolicy.vue');
const Return = () => import('../views/ReturnPolicy.vue');

const Categories = () => import('../views/Categories.vue');

const Products = () => import('../views/Products.vue');
const Product = () => import('../views/ProductPage.vue');

/**
 * Profile Pages
 */
const User = () => import(/* webpackChunkName: "group-user" */ '../views/User.vue');
const Profile = () => import(/* webpackChunkName: "group-user" */ '../components/user/Profile.vue');
const UserSettings = () =>
  import(/* webpackChunkName: "group-user-settings" */ '../components/user/UserSettings.vue');
const DelayedPayment = () =>
  import(/* webpackChunkName: "group-user-settings" */ '../components/user/DelayedPayment.vue');

const UserOrders = () =>
  import(/* webpackChunkName: "group-user" */ '../components/user/ListofUserOrders.vue');
const UserOrder = () => import(/* webpackChunkName: "group-user" */ '../components/user/Order.vue');
const Team = () => import(/* webpackChunkName: "group-user" */ '../components/user/Team');
const SpecialPricing = () =>
  import(/* webpackChunkName: "group-user" */ '../components/user/SpecialPricing.vue');

const Favorites = () =>
  import(/* webpackChunkName: "group-user" */ '@/components/user/Favorites.vue');

const SetPassword = () =>
  import(/* webpackChunkName: "group-user" */ '@/components/SetPassword.vue');

const CommercialSignUp = () => import('../views/CommercialSignUp.vue');

/**
 * Checkout views
 */
const Checkout = () => import(/* webpackChunkName: "group-checkout" */ '../views/Checkout.vue');
const Cart = () => import('../components/checkout/Cart.vue');
const CheckoutLogin = () =>
  import(/* webpackChunkName: "group-checkout" */ '@/components/checkout/Login.vue');
// const Summary = () =>
//   import(/* webpackChunkName: "group-checkout" */ '../components/checkout/Summary.vue');
const ThankYou = () =>
  import(/* webpackChunkName: "group-checkout" */ '@/components/checkout/ThankYou.vue');
const NewCheckout = () =>
  import(/* webpackChunkName: "group-checkout" */ '@/components/checkout/NewCheckout.vue');

/**
 * Admin views
 */
const Admin = () => import('../views/Admin.vue');

// const EditUser = () =>
//   import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/EditUser');
const EditUser = () =>
  import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/UserEdit');
const EditUsers = () =>
  import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/UserList');
const UserInfo = () =>
  import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/UserInfo');
const CompanyInfo = () =>
  import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/CompanyInfo');
const UserSpecialPricing = () =>
  import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/UserPricing');
const UserBilling = () =>
  import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/UserBilling');
const UserLogs = () =>
  import(/* webpackChunkName: "group-edit-user" */ '../components/admin/users/UserLogs');

const Edit = () => import(/* webpackChunkName: "group-edit-layout" */ '@/editor/EditLayout');

const ProductList = () =>
  import(/* webpackChunkName: "group-edit-product" */ '../components/admin/products/ProductList');
const EditProduct = () =>
  import(/* webpackChunkName: "group-edit-product" */ '../components/admin/products/EditProduct');

const Orders = () =>
  import(/* webpackChunkName: "group-edit-order" */ '../components/admin/orders/OrderList');
const Order = () =>
  import(/* webpackChunkName: "group-edit-order" */ '../components/admin/orders/EditOrder');

const CreateOrder = () =>
  import(
    /* webpackChunkName: "group-checkout" */ '../components/admin/orders/createOrder/CreateOrder'
  );

const Variants = () =>
  import(
    /* webpackChunkName: "group-variants" */
    '../components/admin/variants/VariantList'
  );

const Coupons = () =>
  import(/* webpackChunkName: "group-coupon" */ '../components/admin/site/coupon/CouponList');

const SiteSettings = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/index');
const CompanyInformation = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/CompanyInformation');
const Layouts = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/Layouts');
const Maintenance = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/Maintenance');
const Filters = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/Filters');
const Templates = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/Templates');
const SiteCategories = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/Categories');
const Notifications = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/NotificationTemplates');
const VerifyUserPricing = () =>
  import(/* webpackChunkName: "site-settings" */ '../components/admin/site/VerifyUserPricing');
const MiscellaneousSiteSettings = () =>
  import(
    /* webpackChunkName: "site-settings" */ '../components/admin/site/MiscellaneousSiteSettings'
  );

const PhotoUploader = () => import('@/components/admin/photos/index');
const Logger = () => import('@/components/admin/logger/index');

const Vendors = () => import('@/components/admin/vendors/Vendors');
const InfoRetail = () => import('@/views/InfoRetail.vue');
const InfoCommercial = () => import('@/views/InfoCommercial.vue');

const Quickbooks = () => import('@/views/QuickbooksInfo');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadcrumb: 'Home',
      title: 'Home - Page Custom Supply',
      metaTags: [
        {
          name: 'description',
          content:
            'Page Custom Supply is your go-to Online Hardware Supply Store for your Cabinet Hardware needs: knobs and pulls, hinges, drawer slides.',
        },
        {
          property: 'og:description',
          content:
            'Page Custom Supply is your go-to Online Hardware Supply Store for your Cabinet Hardware needs: knobs and pulls, hinges, drawer slides.',
        },
      ],
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About Us - Page Custom Supply',
      metaTags: [
        {
          name: 'description',
          content:
            'My desire was to create a company that would focus on closing those gaps to offer our customers the most reliable and responsive experience possible. To influence a change in this industry led me to build Page Custom Supply. Our focus is to offer a more consistent experience, and solidify impeccable service as our hallmark.',
        },
        {
          property: 'og:description',
          content:
            'My desire was to create a company that would focus on closing those gaps to offer our customers the most reliable and responsive experience possible. To influence a change in this industry led me to build Page Custom Supply. Our focus is to offer a more consistent experience, and solidify impeccable service as our hallmark.',
        },
      ],
    },
  },
  { path: '/privacy', name: 'Privacy Policy', component: Privacy },
  { path: '/return', name: 'Return Policy', component: Return },
  {
    path: '/set-password',
    name: 'Set Password',
    component: SetPassword,
  },
  {
    path: '/search',
    name: 'Search',
    component: Products,
  },
  {
    path: '/product/:id/:variant?',
    name: 'Product',
    component: Product,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/categories/',
    component: Categories,
    name: 'Categories',
  },
  {
    path: '/categories/:sub',
    component: Categories,
    name: 'Subcategories',
    title: (route) => `${route.params.sub} - Page Custom Supply`,
    metaTags: [
      {
        name: 'description',
        content:
          'Page Custom Supply is your go-to Online Hardware Supply Store for your Cabinet Hardware needs: knobs and pulls, hinges, drawer slides.',
      },
      {
        property: 'og:description',
        content:
          'Page Custom Supply is your go-to Online Hardware Supply Store for your Cabinet Hardware needs: knobs and pulls, hinges, drawer slides.',
      },
    ],
  },
  {
    path: '/category-products',
    component: Products,
    name: 'Category Product',
  },

  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/checkout',
    component: Checkout,
    children: [
      { path: '/', name: 'Checkout', component: Cart },
      { path: 'login', component: CheckoutLogin },
      { path: 'summary', component: NewCheckout },
      // { path: 'summary', component: Summary },
      { path: 'thank-you', component: ThankYou },
    ],
  },
  {
    path: '/learn-more-commercial',
    name: 'Learn More Commercial',
    component: InfoCommercial,
  },
  {
    path: '/learn-more-retail',
    name: 'Learn More Retail',

    component: InfoRetail,
  },

  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: 'createOrder',
        name: 'Create Order',
        component: CreateOrder,
      },
      {
        path: 'products',
        name: 'All Products',
        component: ProductList,
      },
      {
        path: 'products/:id',
        name: 'Product Editor',
        component: EditProduct,
      },
      {
        path: 'users',
        name: 'All Users',
        component: EditUsers,
      },
      // {
      //   path: 'users/1/:id',
      //   name: 'User Editor One',
      //   component: EditUser,
      // },
      {
        path: 'users/:id',
        component: EditUser,

        children: [
          {
            path: '/',
            name: 'User Info',
            component: UserInfo,
          },
          {
            path: 'company',
            name: 'Company',
            component: CompanyInfo,
          },
          {
            path: 'special-pricing',
            name: 'User Special Pricing',
            component: UserSpecialPricing,
          },
          {
            path: 'orders',
            name: 'User Orders',
            component: UserOrders,
          },
          {
            path: 'billing',
            name: 'User Billing',
            component: UserBilling,
          },
          {
            path: 'logs',
            name: 'User Logs',
            component: UserLogs,
          },
        ],
      },
      {
        path: 'orders',
        name: 'All Orders',
        component: Orders,
      },
      {
        path: 'orders/:id/:previous?',
        name: 'Order Editor',
        component: Order,
      },
      {
        path: 'layouts/:id',
        name: 'Editor',
        component: Edit,
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: Vendors,
      },
      {
        path: 'photos',
        name: 'Photos',
        component: PhotoUploader,
      },
      {
        path: 'logging',
        name: 'Log',
        component: Logger,
      },
      {
        path: 'variants',
        name: 'Variants',
        component: Variants,
      },

      {
        path: 'site-settings',
        component: SiteSettings,
        children: [
          {
            path: '/',
            name: 'Layouts',
            component: Layouts,
          },
          {
            path: 'company-information',
            name: 'Company Information',
            component: CompanyInformation,
          },
          {
            path: 'filters',
            name: 'Filters',
            component: Filters,
          },
          {
            path: 'maintenance',
            name: 'Maintenance',
            component: Maintenance,
          },
          {
            path: 'templates',
            name: 'Templates',
            component: Templates,
          },
          {
            path: 'categories',
            name: 'Categories',
            component: SiteCategories,
          },
          {
            path: 'notifications',
            name: 'Notifications',
            component: Notifications,
          },
          {
            path: 'verify-user-pricing',
            name: 'Verify User Pricing',
            component: VerifyUserPricing,
          },
          {
            path: 'miscellaneous',
            name: 'Miscellaneous',
            component: MiscellaneousSiteSettings,
          },
          {
            path: 'coupons',
            name: 'Coupons',
            component: Coupons,
          },
        ],
      },
    ],
  },
  {
    path: '/user',
    component: User,
    props: true,
    children: [
      {
        path: '/',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'settings',
        name: 'User Settings',
        component: UserSettings,
      },
      {
        path: 'orders',
        name: 'Orders',
        component: UserOrders,
      },
      {
        path: 'orders/:id',
        name: 'Order',
        component: UserOrder,
      },
      {
        path: 'team',
        name: 'Edit Team',
        component: Team,
      },
      {
        path: 'special-pricing',
        name: 'Special Pricing',
        component: SpecialPricing,
      },
      {
        path: 'favorites',
        name: 'Favorites',
        component: Favorites,
      },
      {
        path: 'make-payment/:order_id/:cart_id?',
        name: 'Delayed Payment',
        component: DelayedPayment,
      },
    ],
  },
  {
    path: '/commercial',
    component: CommercialSignUp,
    name: 'Commercial Sign Up',
  },
  {
    path: '/qb-info',
    component: Quickbooks,
    name: 'QuickBooks Info',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
