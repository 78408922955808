<template>
  <div>
    <div
      class="mt-4 sm:mt-0 flex text-xl justify-center md:justify-start md:mx-0 align-bottom"
      :key="quantityKey"
    >
      <div
        v-if="variant.price != variant.msrp"
        class="mr-3 self-center"
        :class="variant.price !== variant.msrp ? 'text-gray-500 line-through font-normal' : null"
      >
        ${{ variant.msrp.toFixed(2) }}
      </div>
      <div
        class="self-center font-medium"
        :class="variant.price != variant.msrp ? 'text-red text-2xl' : null"
      >
        ${{ variant.price.toFixed(2) }}
      </div>

      <div class="text-sm text-gray-500 p-2 font-normal" v-if="variant.pieceSize">
        (QTY: {{ variant.pieceSize }})
      </div>
    </div>
    <!-- Product Num, vendor -->
    <div class="flex text-center font-normal justify-center md:justify-start">
      <div>
        Product num: <span class="font-medium">{{ variant.productNum }}</span>
      </div>

      <div class="text-gray-500 ml-1">| {{ product.vendor }}</div>
    </div>
    <!-- End Product Num, vendor -->

    <!-- Whole Sale Pricing -->
    <div class="text-sm my-1 text-center md:text-left" v-if="discounts">
      Wholesale Pricing
      <div v-for="(discount, index) of discounts" :key="`discount-${index}`">
        buy {{ discount.quantity }} for ${{ discount.price }}, save {{ discount.discount }}%
      </div>
    </div>
    <!-- End Whole Sale -->
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      quantity: 1,
      displayWholeSale: false,
    };
  },
  props: {
    variant: Object,
    product: Object,
  },
  watch: {},
  computed: {
    quantityKey() {
      return this.$store.getters['products/getQtyKey'];
    },
    wholeSalePrice() {
      let price = this.variant.price;
      if (
        this.variant.wholeSaleQtyThree &&
        this.variant.quantity >= this.variant.wholeSaleQtyThree
      ) {
        price = this.variant.wholeSalePriceThree;
      } else if (
        this.variant.wholeSaleQtyTwo &&
        this.variant.quantity >= this.variant.wholeSaleQtyTwo
      ) {
        price = this.variant.wholeSalePriceTwo;
      } else if (
        this.variant.wholeSaleQtyOne &&
        this.variant.quantity >= this.variant.wholeSaleQtyOne
      ) {
        price = this.variant.wholeSalePriceOne;
      }
      return price.toFixed(2);
    },
    discounts() {
      //{quantity, price, discount}
      const discounts = [];
      if (this.variant.wholeSalePriceOne) {
        discounts.push({
          price: this.variant.wholeSalePriceOne,
          quantity: this.variant.wholeSaleQtyOne,
          discount: (
            ((this.variant.price - this.variant.wholeSalePriceOne) / this.variant.price) *
            100
          ).toFixed(2),
        });
      }
      if (this.variant.wholeSalePriceTwo) {
        discounts.push({
          price: this.variant.wholeSalePriceTwo,
          quantity: this.variant.wholeSaleQtyTwo,
          discount: (
            ((this.variant.price - this.variant.wholeSalePriceTwo) / this.variant.price) *
            100
          ).toFixed(2),
        });
      }
      if (this.variant.wholeSalePriceThree) {
        discounts.push({
          price: this.variant.wholeSalePriceThree,
          quantity: this.variant.wholeSaleQtyThree,
          discount: (
            ((this.variant.price - this.variant.wholeSalePriceThree) / this.variant.price) *
            100
          ).toFixed(2),
        });
      }
      return discounts;
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped></style>
