<template>
  <div v-if="variant">
    <!-- Search Field -->
    <form
      @submit.prevent="getByProductNum"
      class="flex flex-col md:flex-row text-center md:text-left"
      v-if="product.variants.length > 1 && !isGroup"
    >
      <label class="font-medium md:mr-4 text-lg mt-1" for="productNumber">Search</label>
      <div class="flex mx-auto md:mx-0 relative">
        <input
          id="productNumber"
          v-model="productNum"
          type="text"
          class="styled w-56 bg-gray-200 sm:bg-white sm:border sm:border-gray-300"
          placeholder="product number"
          style="border-radius: 16rem; padding-left: 2.5rem"
        />
        <button
          class="focus:outline-none px-2 py-2 sm:mt-px absolute left-0 hover:bg-blue-200 rounded-l-full"
        >
          <img src="/images/icons/Search.svg" alt="Search Icon" width="16px" />
        </button>
      </div>
    </form>
    <!-- End Search Field -->
    <div v-if="searchError" class="text-red text-sm">{{ searchError }}</div>
    <template v-if="isGroup">
      <div
        v-for="(optionName, optionIndex) in product.options"
        :key="optionIndex"
        class="md:flex my-2 text-center md:text-left ml-2-3"
      >
        <div class="font-medium md:mr-4 text-lg mt-1">
          {{ groupName }}
        </div>

        <div class="relative w-full h-10">
          <select
            class="styled rounded shadow w-48 h-10 overflow-scroll"
            @change="setGroupSelected($event.target.value)"
            :value="selectedGroup"
          >
            <option v-for="group in groups" :value="group" :key="group">
              {{ group }}
            </option>
            <!-- <option value="">Select Group</option> -->
            <!-- <template v-for="(optionGroups, optionGroupIndex) in options[optionIndex]">
              <template v-for="(groups, groupIndex) in optionGroups">
              </template>
            </template> -->
          </select>
          <select
            class="styled rounded shadow ml-3 w-48 h-10 overflow-scroll"
            @change="setGroupVariant($event.target.value)"
            :value="variantGroup"
          >
            <!-- <option value="">Select Value</option> -->
            <option
              v-for="groupValue in groupValues"
              :key="groupValue.value"
              :value="groupValue.value"
            >
              {{ groupValue.label }}
            </option>
          </select>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(optionName, optionIndex) in product.options"
        :key="optionIndex"
        class="md:flex my-2 text-center md:text-left ml-2-3"
      >
        <div class="font-medium md:mr-4 text-lg mt-1">
          {{ optionName.type && optionName.type === 'group' ? optionName.value : optionName }}
        </div>

        <div class="flex flex-wrap items-center justify-center" :key="selectedIndex">
          <template v-if="product.variantLayout === 0">
            <div
              v-for="optionValue in options[optionIndex]"
              :key="optionValue"
              class="flex flex-wrap"
            >
              <!-- Color icons -->
              <button
                v-if="optionName === 'Color'"
                class="w-6 h-6 mr-1 rounded-full overflow-hidden cursor-pointer shadow relative color-tool-tip-wrapper focus:outline-none"
                @click="changeVariant(optionValue, optionIndex)"
                :class="isSelectedCircleClass(optionValue, optionIndex)"
              >
                <div v-html="getVariantColor(optionValue)" class="h-full w-full flex"></div>
              </button>

              <button
                v-else
                class="shadow-md px-2 py-px m-1 rounded max-w-xs"
                :class="`${isSelectedClass(optionValue, optionIndex)} ${isSelectable(
                  optionValue,
                  optionIndex
                )}`"
                @click="changeVariant(optionValue, optionIndex)"
              >
                {{ optionValue }}
              </button>
            </div>
          </template>
          <template v-else>
            <div
              class="relative w-full h-10"
              :class="optionSearch[optionIndex].showProduct ? 'z-2' : ''"
            >
              <input
                @focus="
                  optionSearch[optionIndex].searchValue = '';
                  closeOthers();
                  optionSearch[optionIndex].showProduct = true;
                "
                @keyup="setSearch(optionIndex, $event)"
                class="styled shadow my-2 w-full"
                type="text"
                :value="optionSearch[optionIndex].searchValue"
              />
              <div class="relative option-search" v-if="optionSearch[optionIndex].showProduct">
                <i
                  class="close"
                  @click="
                    optionSearch[optionIndex].showProduct = false;
                    optionSearch[optionIndex].searchValue =
                      product.variants[selectedIndex].options[optionIndex];
                  "
                  >&times;</i
                >
                <ul class="bg-white border py-2 w-full h-48 overflow-y-auto">
                  <template v-for="(optionValue, oIndex) in options[optionIndex]">
                    <li
                      class="cursor-pointer p-2"
                      :class="`${
                        oIndex !== options[optionIndex].length - 1 ? 'border-b-2' : ''
                      } ${isSelectable(optionValue, optionIndex)}`"
                      :key="optionValue"
                      v-if="
                        optionValue.toLowerCase().indexOf(optionSearch[optionIndex].searchValue) >
                        -1
                      "
                      v-html="optionValue"
                      @click="changeVariant(optionValue, optionIndex)"
                    ></li>
                  </template>
                </ul>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="optionName === 'Color' && product.variantLayout === 0"
          class="text-sm md:font-medium md:ml-4 mt-1 pt-px capitalize"
        >
          {{ variant.options[0] }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isGroup: false,
      selectedIndex: 0,
      variantGroup: null,
      selectedGroup: null,
      variant: null,
      productNum: null,
      searchError: null,
      optionSearch: new Array(),
    };
  },
  props: {
    variantIndex: Number,
    product: Object,
    variantGroupIndex: String,
  },
  computed: {
    options() {
      const options = new Array();
      for (let i = 0; i < this.product.options.length; i++) {
        const optionValues = new Array();
        for (let j = 0; j < this.product.variants.length; j++) {
          if (
            this.product.options[i] !== '' &&
            optionValues.indexOf(this.product.variants[j].options[i]) === -1
          )
            optionValues.push(this.product.variants[j].options[i]);
        }
        options.push(optionValues);
      }
      return options;
    },
    groupValues() {
      const groupValues = [];
      for (let i = 0; i < this.product.variants.length; i += 1) {
        const variant = this.product.variants[i];
        for (let j = 0; j < variant.options.length; j += 1) {
          const groupsObj = variant.options[j];
          for (let k = 0; k < groupsObj.length; k += 1) {
            const group = groupsObj[k];
            if (group.groupName === this.selectedGroup) {
              for (let l = 0; l < group.groupValues.length; l += 1) {
                const groupValue = group.groupValues[l];
                groupValues.push({
                  label: groupValue,
                  value: `group#${i}#${j}#${k}#${l}`,
                });
              }
            }
          }
        }
      }
      return groupValues.sort((a, b) => a.label.localeCompare(b.label));
    },
    groups() {
      const groups = [];
      for (let i = 0; i < this.product.variants.length; i += 1) {
        const variant = this.product.variants[i];
        for (let j = 0; j < variant.options.length; j += 1) {
          const groupsObj = variant.options[j];
          for (let k = 0; k < groupsObj.length; k += 1) {
            const group = groupsObj[k];
            if (groups.indexOf(group.groupName) === -1) {
              groups.push(group.groupName);
            }
          }
        }
      }
      return groups.sort((a, b) => a.localeCompare(b));
    },
    groupName() {
      let groupName = '';
      for (let i = 0; i < this.product.options.length; i += 1) {
        const option = this.product.options[i];
        if (typeof option !== 'string' && option && option.type && option.type === 'group') {
          groupName = option.value;
        }
      }
      return groupName;
    },
  },
  watch: {
    product() {
      this.initialize();
    },
  },
  methods: {
    setGroupVariant(variantGroup) {
      this.variantGroup = variantGroup;
      const selectedGroup = this.variantGroup.split('#');
      selectedGroup.shift();
      this.changeVariant(variantGroup, selectedGroup[0]);
    },
    setGroupSelected(groupName) {
      this.selectedGroup = groupName;
      this.setGroupVariant(this.groupValues.values().next().value.value);
    },
    closeOthers() {
      for (let i = 0; i < this.optionSearch.length; i += 1) {
        this.optionSearch[i].showProduct = false;
      }
    },
    setSearch(optionIndex, $event) {
      this.optionSearch[optionIndex].searchValue = $event.target.value;
    },
    getOptionObject() {
      return {
        showProduct: false,
        searchValue: '',
      };
    },
    getVariantColor(color) {
      return this.$store.getters['filter/getVariantColor'](color);
    },
    getByProductNum() {
      if (!this.productNum) {
        return;
      }
      for (let i = 0; i < this.product.variants.length; i++) {
        if (
          this.product.variants[i].productNum.toLowerCase().includes(this.productNum.toLowerCase())
        ) {
          this.selectedIndex = i;
          this.variant = this.product.variants[i];
          this.$emit('changeVariant', {
            selectedIndex: this.selectedIndex,
            selectedGroup: this.variantGroup,
          });
          this.searchError = null;
          this.productNum = null;
          return;
        }
      }
      this.searchError = `${this.productNum} doesn't exist in this product.`;
      this.productNum = null;
    },
    initialize() {
      this.selectedIndex = this.variantIndex;
      this.variantGroup = this.variantGroupIndex;
      // let hasGroup = false;
      // for (let i = 0; i < this.product.options.length; i += 1) {
      //   const option = this.product.options[i];
      //   if (typeof option !== 'string' && option && option.type && option.type === 'group') {
      //     i = this.product.options.length;
      //     hasGroup = true;
      //   }
      // }
      this.variant = this.product.variants[this.selectedIndex];
      const search = new Array();
      for (let i = 0; i < this.options.length; i += 1) {
        const optionValue = this.product.options[i];
        if (!this.isGroup) {
          this.isGroup = optionValue.type && optionValue.type === 'group';
        }
        const searchObj = this.getOptionObject();
        searchObj.searchValue = this.variant.options[i];
        search.push(searchObj);
      }
      if (this.isGroup) {
        if (this.variantGroup) {
          const selectedGroup = this.variantGroup
            .substr(0, this.variantGroup.lastIndexOf('#'))
            .split('#');
          selectedGroup.shift();
          this.selectedGroup = this.product.variants[selectedGroup[0]].options[selectedGroup[1]][
            selectedGroup[2]
          ].groupName;
        } else {
          this.setGroupSelected(this.groups[0]);
        }
      }
      this.optionSearch = search;
    },
    isSelectable(optionValue, index) {
      if (this.product == null) {
        return true;
      }
      const variant = this.variant;
      const variants = new Set();

      this.product.variants.forEach((el) => {
        variants.add(el);
      });
      //all colors are selectable
      if (index === 0) {
        return true;
      }

      let i = 0;
      while (i < index) {
        variants.forEach((el) => {
          if (el.options[i] !== variant.options[i]) {
            if (variants.has(el)) {
              variants.delete(el);
            }
          }
        });
        i++;
      }
      let result = 'bg-gray-300 text-gray-500 focus:outline-none';
      variants.forEach((el) => {
        if (optionValue === el.options[index]) result = '';
      });
      return result;
    },
    isSelectedCircleClass(optionValue, optionIndex) {
      if (optionValue === this.variant.options[optionIndex]) {
        return 'selected-circle border-double border-4 border-gray-500 w-8 h-8';
      }
    },
    isSelectedClass(optionValue, optionIndex) {
      if (optionValue === this.variant.options[optionIndex]) {
        return 'bg-primary text-white shadow-inner';
      }
    },
    changeVariant(option, index) {
      let i = 0;
      const variants = new Set();
      //create a set of all the variants
      this.product.variants.forEach((el) => {
        variants.add(el);
      });

      if (option.indexOf('group') > -1) {
        const variantArray = Array.from(variants);
        const selectedOption = option.split('#');
        this.selectedIndex = +selectedOption[1];
        this.variantGroup = option;
        this.variant = {
          ...variantArray[this.selectedIndex],
        };
        this.variant.quantity = 1;
      } else {
        if (index == 0) {
          variants.forEach((el) => {
            if (el.options[i] !== option) {
              if (variants.has(el)) {
                variants.delete(el);
              }
            }
          });
        } else {
          while (i < index) {
            variants.forEach((el) => {
              if (el.options[i] !== this.variant.options[i]) {
                if (variants.has(el)) {
                  variants.delete(el);
                }
              }
            });
            i++;
          }
        }
        const variantArray = Array.from(variants);
        variantArray.forEach((el) => {
          if (el.options[index] == option) {
            this.variant = el;
            this.variant.quantity = 1;
          }
        });

        //set selectedindex to the index of the thing
        for (let i = 0; i < this.product.variants.length; i++) {
          if (this.variant === this.product.variants[i]) {
            this.selectedIndex = i;
            i = this.product.variants.length;
          }
        }
      }
      const data = {
        selectedIndex: this.selectedIndex,
        selectedGroup: this.variantGroup,
      };
      this.$emit('changeVariant', data);
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style scoped>
.min-w-48 {
  min-width: 12rem;
}
.option-search .close {
  position: absolute;
  right: -10px;
  color: white;
  top: -10px;
  line-height: 1;
  border: 1px solid grey;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  background: #00a0b1;
  cursor: pointer;
}
.modal-wrapper .ml-2-3 {
  margin-left: 2.3rem;
}
</style>
