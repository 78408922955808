<template>
  <div>
    <!-- Incrementor -->
    <div class="my-4">
      <div
        class="flex bg-gray-300 shadow-md w-40 mx-auto md:mx-0 rounded py-1 justify-center md:justify-start"
        :key="quantityKey"
      >
        <button @click="decrement" :disabled="variant.quantity === 1" class="px-5 py-1 text-lg">
          <font-awesome-icon icon="minus" />
        </button>
        <input
          type="number"
          v-model="variant.quantity"
          @change="quantityKey++"
          @blur="quantityKey++"
          min="0"
          class="w-12 text-center text-lg"
        />
        <button @click="increment" class="px-5 py-1 text-lg">
          <font-awesome-icon icon="plus" />
        </button>
      </div>
      <div class="text-gray-600 text-sm my-3 md:ml-6 text-center md:text-left">
        <span v-if="variant.stock > 0"> ({{ variant.stock }} available) </span>
        <span v-else-if="product.local">(availability determined by local vendor)</span>
        <span v-else-if="product.dropShip">(availability determined by vendor)</span>

        <span v-else>(coming soon)</span>
      </div>
    </div>
    <!-- End Incrementor -->

    <!-- Add to cart  -->
    <div class="text-center pb-6 sm:pb-0 md:my-8 md:text-left">
      <div v-if="carts.length === 1">
        <button
          v-if="!addedProduct"
          class="btn-action my-1 w-10/12 sm:w-1/2 rounded-lg md:w-80"
          @click="addToCart()"
        >
          + Add {{ variant.quantity }} to cart
        </button>
        <button
          v-else
          class="py-2 px-4 shadow-lg text-white transition-colors duration-100 bg-gray-400 mx-auto md:mx-0 my-4 w-10/12 sm:w-1/2 rounded-lg md:w-80"
          disabled="true"
        >
          Adding to cart
        </button>
      </div>
      <div v-else class="flex flex-col md:my-4">
        <select
          class="w-10/12 sm:w-1/2 md:w-80 mx-auto md:mx-0 shadow focus:border-blue-500 block sm:text-sm border-blue-300 rounded-md"
          v-model="cartIndex"
        >
          <option v-for="(cart, index) in carts" :value="index" :key="cart._id">
            {{ cart.orderName }}
          </option>
        </select>
        <button
          v-if="!addedProduct"
          class="btn-action mx-auto md:mx-0 my-1 w-10/12 sm:w-1/2 rounded-lg md:w-80"
          @click="addToCart()"
        >
          + Add {{ variant.quantity }} to
          {{ $store.state.order.carts[cartIndex].orderName }}
        </button>
        <button
          v-else
          class="py-2 px-4 shadow-lg text-white transition-colors duration-100 bg-gray-400 mx-auto md:mx-0 my-4 w-10/12 sm:w-1/2 rounded-lg md:w-80"
          disabled="true"
        >
          Adding to cart
        </button>
      </div>
    </div>
    <!-- Related Product redirection -->
    <PopUp v-if="hasRelatedProduct" @close="productAdded = false">
      <AddCartRequiredProduct
        :relatedProductText="product.relatedProductText"
        :relatedProduct="relatedProduct"
        @close="productAdded = false"
      ></AddCartRequiredProduct>
    </PopUp>
    <!-- End Related Product redirection -->
  </div>
</template>

<script>
import CartItem from '@/assets/utils/CartItem';
import PopUp from '@/components/shared/DesktopModal';
import AddCartRequiredProduct from '@/components/shared/AddCartRequiredProduct';

export default {
  data: function () {
    return {
      cartIndex: 0,
      productAdded: false,
    };
  },
  props: {
    variant: Object,
    variantGroup: String,
    product: Object,
    flex: { type: Boolean, default: false },
  },
  computed: {
    hasRelatedProduct() {
      let hasRelatedProduct = true;
      for (let i = 0; i < this.relatedProduct.length; i += 1) {
        if (!this.relatedProduct[i].product) {
          hasRelatedProduct = false;
          break;
        }
      }
      return this.productAdded && hasRelatedProduct;
    },
    relatedProduct() {
      return this.product.relatedProduct;
    },
    addedProduct() {
      return this.$store.getters['order/getAddedProduct'];
    },
    quantityKey: {
      get() {
        return this.$store.getters['products/getQtyKey'];
      },
      set() {
        this.$store.commit('products/setQtyKey');
      },
    },
    carts() {
      const carts = this.$store.getters['order/getCarts'];
      return carts;
    },
    commercial() {
      return this.$store.getters.getCommercial;
    },
    authorized() {
      return this.$store.getters.getAuthorized;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
  },
  watch: {
    variant() {
      this.initialize();
    },
  },
  methods: {
    addToCart() {
      const cartItem = this.makeCartItem();
      this.$store.commit('order/setCartIndex', this.cartIndex);
      this.$store.dispatch('order/addToCart', cartItem);
      this.productAdded = true;
    },

    makeCartItem() {
      if (this.variant.quantity < 1) {
        return;
      }
      const item = JSON.parse(JSON.stringify(this.variant));
      item.quantity *= 1;
      item.price =
        item.quantity >= item.wholeSaleQtyThree
          ? item.wholeSalePriceThree
          : item.quantity >= item.wholeSaleQtyTwo
          ? item.wholeSalePriceTwo
          : item.quantity >= item.wholeSaleQtyOne
          ? item.wholeSalePriceOne
          : item.price;

      const location = item.binLocation ? item.binLocation : item.dropShip ? 'DropShip' : 'Order';

      const productOptions = this.product.options;
      let isGroup = this.variantGroup && this.variantGroup.indexOf('group') !== -1;
      const maxIteration = Math.min(3, productOptions.length);
      let title = this.product.title;
      if (isGroup) {
        const variantGroup = this.variantGroup?.split('#');
        for (let i = 0; i < maxIteration; i += 1) {
          const group = item.options[i][+variantGroup[3]];
          title += ` (${group.groupName}) (${group.groupValues[variantGroup[4]]})`;
        }
      } else {
        for (let i = 0; i < maxIteration; i += 1) {
          const option = item.options[i];
          title += ` (${option})`;
        }
      }

      const cartItem = new CartItem(
        title,
        item.image,
        this.product._id,
        item._id,
        item.quantity,
        0,
        item.price,
        item.price,
        item.productNum,
        location,
        item.quickbooksId,
        this.product.vendor,
        item.lb,
        item.oz,
        item.stock,
        item.isBundle,
        item.bundleItems,
        item.bundleDiscountPer,
        this.variantGroup
      );

      cartItem.setWholeSaleData(
        { quantity: item.wholeSaleQtyOne, price: item.wholeSalePriceOne },
        { quantity: item.wholeSaleQtyTwo, price: item.wholeSalePriceTwo },
        { quantity: item.wholeSaleQtyThree, price: item.wholeSalePriceThree }
      );
      return cartItem;
    },

    decrement() {
      this.$store.commit('products/setQtyKey');
      if (this.variant.quantity > 1) {
        this.variant.quantity = this.variant.quantity * 1 - 1;
      }
    },

    increment() {
      this.$store.commit('products/setQtyKey');
      this.variant.quantity = this.variant.quantity * 1 + 1;
    },

    initialize() {
      this.$store.commit('products/setQtyKey');
      this.variant.quantity = 1;
    },
  },
  components: {
    PopUp,
    AddCartRequiredProduct,
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
