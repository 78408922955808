<!--
    ModalPopup to be used to get user feedback or just notify user of something very important
    @props: title, body, 

    Example usage:
    <transition name="modal">
      <ModalPopup v-if="showModal" 
        title="Editor" 
        @close="showModal = false">
      </ModalPopup>
    </transition>
    
-->

<template>
  <div class="modal-mask hidden md:fixed">
    <transition name="fadeFast">
      <div class="modal-wrapper" @click.self="close" v-if="show">
        <div
          :class="controlWidth ? ' md:w-2/3 xl:w-1/3' : ' lg:w-1/2 max-h-full'"
          class="mx-auto w-11/12 sm:w-2/3 font-medium relative rounded-lg shadow-md transition-all duration-300 bg-gray-200 pb-2 overflow-hidden"
        >
          <!-- Module Header -->
          <div
            @click="close"
            class="absolute top-0 right-0 px-3 pb-1 sm:hover:bg-red-400 text-4xl cursor-pointer leading-none font-normal"
            :class="title ? 'text-white' : null"
          >
            &#215;
          </div>
          <div class="w-full px-4 py-2 text-white" :class="title ? 'bg-primary shadow' : null">
            {{ title }}
          </div>
          <div class="mt-4 mb-2 mx-4">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    body: String,
    controlWidth: { type: Boolean, default: false },
  },
  data: function () {
    return {
      show: false,
    };
  },
  methods: {
    close() {
      this.show = false;
      setTimeout(() => {
        this.$emit('close');
      }, 100);
    },
  },
  beforeMount() {
    setTimeout(() => {
      this.show = true;
    }, 50);
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
