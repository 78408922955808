<template>
  <div class="relative h-56">
    <button
      @click="scrollLeft()"
      class="hidden md:block absolute focus:outline-none -left-4 top-4 px-6 py-8 hover:bg-gray-200 rounded-sm"
    >
      <font-awesome-icon icon="chevron-left" class="text-2xl text-gray-700" />
    </button>
    <button
      @click="scrollRight()"
      class="hidden md:block absolute focus:outline-none -right-4 top-4 px-6 py-8 hover:bg-gray-200 rounded-sm"
    >
      <font-awesome-icon icon="chevron-right" class="text-2xl text-gray-700" />
    </button>
    <div class="carousel-wrapper">
      <transition-group
        name="product"
        tag="div"
        ref="scrollWrapper"
        class="carousel flex z-1 scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch"
      >
        <div v-for="(category, index) in categories" :key="category._id" class="mx-1 sm:mx-0">
          <CategoryTile :tag="category" :index="index" :length="categories.length" />
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import CategoryTile from '@/components/categories/CategoryTile';

export default {
  components: {
    CategoryTile,
  },
  computed: {
    categories() {
      return this.$store.getters['tags/getTags']({ type: 'Category' });
    },
  },
  methods: {
    scrollLeft() {
      this.$refs.scrollWrapper.$el.scrollBy({
        top: 0,
        left: -700,
        behavior: 'smooth',
      });
    },
    scrollRight() {
      this.$refs.scrollWrapper.$el.scrollBy({
        top: 0,
        left: 700,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style>
.carousel {
  overflow: auto;
  white-space: nowrap;
  /* scroll-snap-type: x proximity; */
}

.carousel-wrapper {
  position: relative;
}

/* For anything larger than mobile device */
@media only screen and (min-width: 600px) {
  .carousel-wrapper::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white);
    width: 15px;
  }

  .carousel-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
    width: 15px;
  }
}

.carousel > * {
}
</style>
