import axios from 'axios';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async clearKeys({ commit }) {
      try {
        await axios.put('/api/v1/quickbooks/clear');
        commit('setError', {
          message: `Successfully cleared keys.`,
          color: 'green',
        });
      } catch (error) {
        commit('setError', {
          message: `Failed to clear keys.`,
          color: 'red',
        });
      }
    },
    async syncFrom() {
      try {
        if (!confirm('Are you sure you would like to sync FROM QuickBooks.')) {
          return;
        }
        await axios.get('/api/v1/quickbooks/items');
        this.$store.commit('setError', {
          message: `The server has received this request and will take about 20 minutes to complete. Andrew will receive an email when the sync is complete. Please don't sync again for today.`,
          color: 'green',
          duration: 0,
        });
      } catch (error) {
        console.log(error);
      }
    },

    async syncCompany(state, payload) {
      try {
        await axios.post('/api/v1/quickbooks/company', payload);
      } catch (error) {
        console.log(error);
        this.$store.commit('setError', {
          message: `Failed to sync company.`,
          color: 'green',
          duration: 0,
        });
      }
    },

    async syncVendors(state, payload) {
      let success = 0;
      let failed = 0;
      for (let i = 0; i < payload.length; i++) {
        try {
          await axios.post('/api/v1/quickbooks/vendor', this.items[i]);
          success += 1;
        } catch (error) {
          failed += 1;
          console.log(error);
        }
      }

      if (failed > 0) {
        this.$store.commit('setError', {
          message: `Failed to Sync/Update ${failed} Vendors.`,
          color: 'red',
          duration: 0,
        });
      } else {
        this.$store.commit('setError', {
          message: `Successfully Synced/Updated ${success} Vendors.`,
          color: 'green',
          duration: 0,
        });
      }
    },

    async syncIndividualProduct({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/quickbooks/singleProduct', payload);
        commit('products/setProduct', response.data, { root: true });
        commit(
          'setError',
          {
            message: `Successfully synced Product.`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: `Failed to sync product.`,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async createInvoice({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/quickbooks/invoiceNew', payload);
        commit('editOrder/setOrder', response.data, { root: true });
        commit(
          'setError',
          {
            message: `Created invoice.`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: `Failed to create Invoice.`,
            color: 'red',
            duration: 0,
          },
          { root: true }
        );
      }
    },
    async createPurchaseOrder({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/quickbooks/purchaseOrder', payload);
        commit('editOrder/setOrder', response.data, { root: true });
        commit(
          'setError',
          {
            message: `Created purchase order.`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: `Failed to create purchase order.`,
            color: 'red',
            duration: 0,
          },
          { root: true }
        );
      }
    },
  },
};
