import axios from 'axios';

const suggestions = {
  namespaced: true,
  state: {
    suggestions: null,
    suggestionsArray: new Array(),
    topTwenty: null,
  },
  mutations: {
    setTopTwenty: (state, payload) => {
      state.topTwenty = payload;
    },
    /**
     * Payload is the users cart
     */
    setSuggestions: (state, payload) => {
      let suggestions = state.suggestions;
      if (suggestions == null) {
        suggestions = new Map();
      }
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].suggestions) {
          for (let j = 0; j < payload[i].suggestions.length; j++) {
            let item = payload[i].suggestions[j];

            if (suggestions.has(item._id)) {
              let k = suggestions.get(item._id);
              k += 1;
              suggestions.set(item._id, k);
            } else {
              suggestions.set(item._id, 2);
            }
          }
        }
      }
      state.suggestions = suggestions;
      state.cart = payload;
    },

    //payload is items, to filter out all of the cart items from the suggestions.
    setArray: (state) => {
      let suggestionsMap = state.suggestions;
      let suggestionsArray = new Array();
      let item;
      let maxSuggestionSize = 20;

      for (let i = 0; i < state.topTwenty.length; i++) {
        suggestionsMap.set(state.topTwenty[i]._id, 1);
      }

      //remove the items that are already in the cart.
      for (let i = 0; i < state.cart.length; i++) {
        item = state.cart[i];

        suggestionsMap.forEach(removeEntry);
      }

      //this makes it so items that are in multiple suggestions lists have the highest placement.
      let i = 10;
      //run this algorithm if number of items in the array are less than 10
      while (suggestionsArray.length < maxSuggestionSize && i >= 0) {
        suggestionsMap.forEach(setArray);
        i--;
      }

      state.suggestionsArray = suggestionsArray;

      //remove items in cart from the suggestions.
      function removeEntry(value, key, map) {
        if (key == item._id) {
          map.delete(key);
        }
      }

      //grab the most frequent items from the map in descending order.
      function setArray(value, key) {
        if (value > i) {
          suggestionsArray.push(key);
          suggestionsMap.delete(key);
        }
      }
    },
  },
  getters: {
    suggestions: (state, getters, rootState) => {
      let suggestionsArray = new Array();
      let items = rootState.itemsMap;
      for (let i = 0; i < state.suggestionsArray.length; i++) {
        if (items.has(state.suggestionsArray[i])) {
          let item = items.get(state.suggestionsArray[i]);
          if (item.stock > 0) suggestionsArray.push(item);
        }
      }

      return suggestionsArray;
    },
  },
  actions: {
    async addSuggestion({ commit }, data) {
      try {
        await axios.put('/api/items/addsuggestion', data);
        commit(
          'setError',
          {
            message: 'Successfully added Suggestion',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: error,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async removeSuggestion({ commit }, data) {
      try {
        await axios.put('/api/items/removesuggestion', data);
        commit(
          'setError',
          {
            message: 'Successfully added Suggestion',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: error,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async getTopTwenty({ commit }) {
      try {
        let response = await axios.get('/api/items/toptwenty');
        commit('setTopTwenty', response.data);
      } catch (err) {
        console.log(err);
      }
    },
  },
};

export default suggestions;
