import axios from 'axios';

export default {
  namespaced: true,
  state: {
    variants: [],
    search: null,
    loading: false,
    sort: null,
  },
  mutations: {
    setVariants(state, payload) {
      state.variants = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setSort(state, payload) {
      state.sort = payload;
    },
  },
  getters: {
    filterVariants: (state) => {
      let variants = state.variants;

      if (state.search) {
        const search = state.search.toLowerCase();
        return variants.filter((el) => {
          if (el.productNum) {
            if (el.productNum.toLowerCase().includes(search)) {
              return el;
            }
          }
        });
      }
      return variants;
    },
    sortVariants: (state, getters) => {
      let variants = getters.filterVariants;
      if (state.sort) {
        console.log(state.sort);
        switch (state.sort) {
          case 'productNum':
            variants = variants.sort((a, b) => {
              return a.productNum < b.productNum ? -1 : 1;
            });
            break;
          case 'productName':
            variants = variants.sort((a, b) => {
              return a.product_name < b.product_name ? -1 : 1;
            });
            break;
          case 'qbID':
            variants = variants.sort((a, b) => {
              return a.quickbooksId < b.quickbooksId ? -1 : 1;
            });
        }
      }
      return variants;
    },
    getMoreVariants: (state, getters) => (payload) => {
      return getters.sortVariants.slice(payload, payload + 25);
    },
    getLoading: (state) => {
      return state.loading;
    },
    getSearch: (state) => {
      return state.search;
    },
    getSort: (state) => {
      return state.sort;
    },
  },
  actions: {
    async saveVariant({ commit }, payload) {
      try {
        commit('setLoading', true);

        await axios.patch('/api/v1/products/variant', payload);
        commit(
          'setError',
          {
            message: 'Saved variant',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error saving variant',
            color: 'red',
          },
          { root: true }
        );
      }
      commit('setLoading', false);
    },

    async getVariants({ commit }, payload) {
      commit('setLoading', true);
      try {
        const response = await axios.get('/api/v1/products/variant/all?' + payload);
        commit('setVariants', response.data);
      } catch (err) {
        console.log(err);
      }
      commit('setLoading', false);
    },
    async deleteVariant({ commit }, data) {
      commit('setLoading', true);
      try {
        await axios.delete(`/api/v1/products/variant/${data}`);
        commit(
          'setError',
          {
            message: 'Successfully deleted variant.',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error deleting variant',
            color: 'red',
          },
          { root: true }
        );
      }
      commit('setLoading', false);
    },
    async saveToProduct({ commit }, data) {
      try {
        await axios.post(`/api/v1/products/variant/${data.product_id}/${data.variant_id}`);
        commit(
          'setError',
          {
            message: 'Successfully saved variant.',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error saving variant',
            color: 'red',
          },
          { root: true }
        );
      }
    },
  },
};
