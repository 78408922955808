<template>
  <div class="grid md:grid-cols-2 gap-4 md:gap-8 md:p-8 p-4">
    <div class="md:col-span-2 text-center">
      <div class="relative">
        <h2
          class="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl"
        >
          What kind of customer are you?
        </h2>
        <p class="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
          Setup an account to unlock customer pricing and . . .
        </p>
      </div>
    </div>
    <!-- Professional -->
    <div class="shadow rounded-xl overflow-hidden">
      <!-- Image -->
      <div
        class="overflow-hidden"
        :style="`background-image: url(${imgPathOne}; background-position: center; background-size: cover; height:400px`"
      >
        <div
          class="text-black font-semibold flex flex-col items-center justify-center h-full text-center w-full p-8"
          style="background-color: rgba(256, 256, 256, 0.6)"
        >
          <h3>Business or Trade Reselling Products</h3>
          <h4>
            Gain access to order history, favorites, and negotiate pricing for your most frequently
            purchased products, just for you.
          </h4>
          <div class="flex justify-around items-center mt-10 w-full md:w-160">
            <router-link to="/learn-more-commercial">Learn More</router-link>
            <router-link to="/commercial" tag="button" class="btn-action rounded">
              Sign Up
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Retail -->

    <div class="shadow rounded-xl overflow-hidden">
      <!-- Image -->
      <div
        class="overflow-hidden"
        :style="`background-image: url(${imgPathTwo}; background-position: center; background-size: cover; height:400px`"
      >
        <div
          class="text-black font-semibold flex flex-col items-center justify-center h-full text-center w-full p-8"
          style="background-color: rgba(256, 256, 256, 0.6)"
        >
          <h3>Homeowner or DIY</h3>
          <h4>
            Sign up to get Special Customer pricing. The savings normally covers shipping costs.
            Gain access to order history and favorites.
          </h4>
          <div class="flex justify-around items-center mt-10 w-full md:w-160">
            <router-link to="/learn-more-retail">Learn More</router-link>
            <router-link
              :to="{
                name: 'Profile',
                params: { initialShowRegister: true },
              }"
              tag="button"
              class="btn-action rounded"
              >Sign Up</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    imgPathOne: '/images/static/are-you-a-pro.webp',
    imgPathTwo: '/images/static/do-it-yourself.webp',
  }),
  computed: {},
  components: {},
  mounted() {
    setTimeout(() => {
      if (!window.$webpSupport) {
        this.imgPathOne = '/images/static/are-you-a-pro.jpg';
        this.imgPathTwo = '/images/static/do-it-yourself.jpg';
      }
    }, 1000);
  },
};
</script>
