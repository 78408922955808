import axios from 'axios';

export default {
  namespaced: true,
  state: {
    user: null,
    coupons: null,

    orders: new Array(),
    order: null,

    products: [],

    orderSearch: null,
    orderCartSearch: null,
    search: null,
    userSearch: null,
    productSearch: null,

    vendor: 'All Vendors',
    category: 'All Categories',
    subcategory: 'All Subcategories',
    tag: 'All Tags',
    qbUpdate: false,
    sort: null,
    queryTotal: 1,

    userLoading: false,
    loading: false,
    qbProgress: 100,
    qbTotal: 100,
    qbName: 'gabrielsuttner@gmail.com',
    cancelTokenSource: null,
  },
  mutations: {
    setProducts(state, payload) {
      state.products = [...state.products, ...payload];
    },
    setCancelTokenSource(state, payload) {
      state.cancelTokenSource = payload;
    },
    clearProducts(state) {
      state.products = [];
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    clearFilters(state) {
      state.vendor = 'All Vendors';
      state.category = 'All Categories';
      state.subcategory = 'All Subcategories';
      state.tag = 'All Tags';
      state.qbUpdate = false;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setQBUpdate(state, payload) {
      state.qbUpdate = payload;
    },
    setOrders(state, payload) {
      state.orders = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setVendor(state, payload) {
      state.vendor = payload;
    },
    setCategory(state, payload) {
      state.category = payload;
    },
    setSubcategory(state, payload) {
      state.subcategory = payload;
    },
    setTag(state, payload) {
      state.tag = payload;
    },
    setQueryTotal(state, payload) {
      state.queryTotal = payload;
    },
    setOrderSearch(state, payload) {
      state.orderSearch = payload;
    },
    setOrderCartSearch(state, payload) {
      state.orderCartSearch = payload;
    },
    setProductSearch(state, payload) {
      state.productSearch = payload;
    },
    setSort(state, payload) {
      if (state.sort === payload) {
        payload = '-' + payload;
      }
      state.sort = payload;
    },
    setCoupons(state, payload) {
      state.coupons = payload;
    },
    setUserLoading(state, payload) {
      state.userLoading = payload;
    },
    setQbSyncData(state, payload) {
      state.qbProgress = payload.progress;
      state.qbTotal = payload.total;
      state.qbName = payload.syncer;
    },
  },
  getters: {
    getQbName: ({ qbName }) => qbName,
    getQbProgress: ({ qbTotal, qbProgress }) => {
      let value = (qbProgress / qbTotal) * 100;
      if (qbProgress > 0) {
        value = value.toFixed(2) * 1;
      } else {
        value = 100;
      }
      return value;
    },
    getProducts: ({ products }) => products,
    getQueryTotal: ({ queryTotal }) => queryTotal,
    getCoupons: ({ coupons }) => coupons,
    getProductSearch: ({ productSearch }) => productSearch,
    getSearch: ({ search }) => search,
    getUser: ({ user }) => user,
    getUserLoading: ({ userLoading }) => userLoading,
    getLoading: ({ loading }) => loading,
    getUserOrders: ({ user }) => {
      if (user) {
        return user.orders;
      }
      return user;
    },
    getOrderSearch: ({ orderSearch }) => orderSearch,
    getOrderCartSearch: ({ orderCartSearch }) => orderCartSearch,
    //return the _id of the previous product.
    getPreviousProductId: (state, getters) => {
      if (state.products && state.product) {
        const product = state.product;
        const products = getters.getProducts;
        for (let i = 0; i < products.length; i++) {
          if (i == 0 && product._id === products[i]._id) {
            return null;
          }
          if (product._id === products[i]._id) {
            return products[i - 1]._id;
          }
        }
      }
      return null;
    },
    getCancelTokenSource({ cancelTokenSource }) {
      return cancelTokenSource;
    },
    getNextProductId: (state, getters) => {
      if (state.products && state.product) {
        const product = state.product;
        const products = getters.getProducts;
        for (let i = 0; i < products.length - 1; i++) {
          if (product._id === products[i]._id) {
            return products[i + 1]._id;
          }
        }
      }
      return null;
    },

    filterOrders: (state) => {
      let orders = state.orders;
      if (orders === undefined) {
        return null;
      }

      if (state.orderCartSearch) {
        orders = orders.filter((el) => {
          if (el.cart) {
            if (
              el.cart.some((j) => {
                if (j.productNum) {
                  if (j.productNum.toLowerCase().includes(state.orderCartSearch.toLowerCase())) {
                    return true;
                  }
                }
              })
            ) {
              return el;
            }
          }
        });
      }

      return orders;
    },

    getOrders: (state, getters) => {
      const orders = getters.filterOrders;

      return orders;
    },

    getOrder: (state) => {
      return state.order;
    },
  },
  actions: {
    async addProduct({ commit }, payload) {
      try {
        commit('setLoading', true);
        const response = await axios.post('/api/v1/products', payload);
        commit('setLoading', false);

        commit('products/setProduct', response.data, { root: true });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (error) {
        console.log(error);
        commit('setLoading', false);

        commit(
          'setError',
          {
            message: 'Error adding Product',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async updateProducts(context, payload) {
      try {
        await axios.patch('/api/v1/products', payload);
        context.commit(
          'setError',
          {
            message: 'Updated products',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        context.commit(
          'setError',
          {
            message: 'Error updating products',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async updateProduct({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        try {
          commit('setLoading', true);
          const response = await axios.put('/api/v1/products', payload);
          commit('setLoading', false);
          if (response.data === 'Success') {
            commit(
              'setError',
              {
                message: 'Updated Product',
                color: 'green',
              },
              { root: true }
            );
          } else {
            commit(
              'setError',
              {
                message: `${response.data} is a duplicate product, You must change the product number and re-save`,
                color: 'red',
                duration: 0,
              },
              { root: true }
            );
          }

          return resolve(true);
        } catch (error) {
          console.log(error);
          commit('setLoading', false);
          commit(
            'setError',
            {
              message: 'Error updating Product',
              color: 'red',
            },
            { root: true }
          );
        }
      });
    },

    async deleteProduct(context, payload) {
      try {
        await axios.delete('/api/v1/products/' + payload);
        context.commit(
          'setError',
          {
            message: 'Successfully deleted Product',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        context.commit(
          'setError',
          {
            message: 'Error deleting Product',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async deleteProducts(context, payload) {
      for (let i = 0; i < payload.length; i++) {
        try {
          await axios.delete('/api/v1/products/' + payload[i]._id);
          context.commit(
            'setError',
            {
              message: `Successfully deleted ${payload[i].title} products. Please refresh the page`,
              color: 'green',
            },
            { root: true }
          );
        } catch (error) {
          console.log(error);
          context.commit(
            'setError',
            {
              message: `Failed to delete ${payload[i].title}.`,

              color: 'red',
            },
            { root: true }
          );
        }
      }
    },

    async getProducts({ commit, getters }, payload) {
      if (payload.showLoader) {
        commit('setLoading', true);
      }
      delete payload.showLoader;

      let query;
      if (payload != null) {
        Object.keys(payload).forEach((key) => {
          if (payload[key] === undefined || payload[key] === 'undefined') {
            delete payload[key];
          }
        });

        query =
          '?' +
          JSON.stringify(payload)
            .replace(/}/g, '')
            .replace(/{/g, '')
            .replace(/"/g, '')
            .replace(/:/g, '=')
            .replace(/,/g, '&');
      } else {
        query = '';
      }

      try {
        let cancelTokenSource = getters['getCancelTokenSource'];
        if (cancelTokenSource) {
          cancelTokenSource.cancel('New Search Triggered');
        }
        cancelTokenSource = axios.CancelToken.source();
        commit('setCancelTokenSource', cancelTokenSource);
        console.log(query);
        const response = await axios.get(`/api/v1/products/all${query}`, {
          cancelToken: cancelTokenSource.token,
        });

        commit('setProducts', response.data.products);
        commit('setQueryTotal', response.data.total);
        commit('setLoading', false);
        return true;
      } catch (error) {
        console.log(error);
        commit('setLoading', false);
        return false;
      }
    },

    async updateVariant(context, payload) {
      try {
        await axios.post('/api/v1/products/variant', payload);
      } catch (error) {
        console.log(error);
        context.commit(
          'setError',
          {
            message: 'Error adding variant',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async deleteVariant(context, payload) {
      try {
        await axios.delete(`/api/v1/products/variant/${payload.product_id}/${payload.variant_id}`);
        context.commit(
          'setError',
          {
            message: 'Successfully deleted variant',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        context.commit(
          'setError',
          {
            message: 'Error deleting variant',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async removeVariant(context, payload) {
      try {
        await axios.patch(`/api/v1/products/variant/${payload.product_id}/${payload.variant_id}`);
        context.commit(
          'setError',
          {
            message: 'Successfully removed variant',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        context.commit(
          'setError',
          {
            message: 'Error removing variant',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async removeBundleItem(context, payload) {
      try {
        await axios.delete(
          `/api/v1/products/bundle/${payload.variant_id}/${payload.bundle_item_id}`
        );
        context.commit(
          'setError',
          {
            message: 'Successfully removed Product',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        context.commit(
          'setError',
          {
            message: 'Error removing Product',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async upload({ commit }, data) {
      try {
        await axios.post('/api/v1/products/upload', data);
        commit(
          'setError',
          {
            message: 'Successfully uploaded products',
            color: 'green',
          },
          { root: true }
        );
        return true;
      } catch (error) {
        console.log(error.message);
        commit(
          'setError',
          {
            message: error,
            color: 'red',
          },
          { root: true }
        );
        return false;
      }
    },

    async getOrders({ commit }, payload) {
      commit('setLoading', true);
      try {
        const response = await axios.get('/api/v1/orders/some' + payload);
        commit('setOrders', response.data.orders);
        commit('setQueryTotal', response.data.total);
      } catch (error) {
        console.log(error);
      }
      commit('setLoading', false);
    },

    async getUsers({ commit }) {
      try {
        const response = await axios.get('/api/v1/users/all');
        commit('setUsers', response.data);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     *
     * @param {*} payload User._id
     */
    async getUser({ commit }, payload) {
      commit('setUserLoading', true);
      try {
        if (payload === 'new') {
          commit('setUser', { _id: null, orders: [], products: [], associatedAccounts: [] });
        } else {
          const response = await axios.get('/api/v1/users/' + payload);
          commit('setUser', response.data);
        }
      } catch (error) {
        console.log(error);
      }
      commit('setUserLoading', false);
    },

    async addLayout({ commit }, data) {
      try {
        await axios.post('/api/v1/layouts', data);
        commit(
          'setError',
          {
            message: 'Successfully added Layout',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error adding Layout',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async updateLayout({ commit }, data) {
      try {
        await axios.put('/api/v1/layouts', data);
        commit(
          'setError',
          {
            message: 'Successfully updated layout.',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error saving Layout',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async getLayouts({ commit }) {
      try {
        commit('setLayoutLoad', true, { root: true });
        let response = await axios.get('/api/v1/layouts');
        commit('setLayouts', response.data, { root: true });
        commit('setLayoutLoad', false, { root: true });
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: '',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async deleteLayout({ commit }, data) {
      try {
        await axios.delete('/api/v1/layouts/' + data);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: '',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async updateUser({ commit }, data) {
      console.log(data);
      try {
        const response = await axios.patch('/api/v1/users/', data);
        commit('setUser', response.data);
        commit(
          'setError',
          {
            message: 'Successfully updated user',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error updating user',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async deleteUser({ commit }, data) {
      try {
        await axios.delete('/api/v1/users/' + data._id);
        commit(
          'setError',
          {
            message: 'Successfully deleted user',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error deleting user',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async getCoupons({ commit }) {
      try {
        const response = await axios.get('/api/v1/coupons/');
        commit('setCoupons', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async saveCoupon({ commit }, payload) {
      try {
        await axios.post('/api/v1/coupons', payload);
        commit(
          'setError',
          {
            color: 'green',
            message: 'Successfully saved coupon.',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            color: 'red',
            message: 'Faild to saved coupon.',
          },
          { root: true }
        );
      }
    },
    async deleteCoupon({ commit }, payload) {
      try {
        await axios.delete('/api/v1/coupons/' + payload);
        commit(
          'setError',
          {
            color: 'green',
            message: 'Successfully deleted coupon.',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            color: 'red',
            message: 'Faild to delete coupon.',
          },
          { root: true }
        );
      }
    },
    async getQbSyncProgress({ commit }) {
      const response = await axios.get('/api/v1/quickbooks/progress');
      commit('setQbSyncData', response.data);
    },
    async sendConfirmation({ commit }, payload) {
      try {
        await axios.post('/api/v1/orders/confirmation', payload);
        commit(
          'setError',
          {
            color: 'green',
            message: `Successfully sent confirmation email to ${payload.email}.`,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            color: 'red',
            message: `failed to send confirmation email to ${payload.email}.`,
          },
          { root: true }
        );
      }
    },
    async sendInvoice({ commit }, payload) {
      try {
        await axios.post('/api/v1/orders/invoice', payload);
        commit(
          'setError',
          {
            color: 'green',
            message: `Successfully sent invoice to ${payload.email}.`,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            color: 'red',
            message: `failed to send invoice to ${payload.email}.`,
          },
          { root: true }
        );
      }
    },
  },
};

/**
 * When working with modules:
 *
 * state:
 *
 * mutations:
 *
 * getters:
 *  Inside of module getters, the root state is exposed as a 3rd argument and rootGetters the 4th
 *
 *  getSomething(state, getters, rootState, rootGetters)
 *
 * actions:
 *  Similarly, inside module actions, context.state will expose the local state, and root state will be exposed as context.rootState.
 *    increment({ state, commit, dispatch, rootState}) {
 *
 *    }
 *
 *    Inside of actions, in order to activate an action in the store, do the following
 *
 *    dispatch('someAction', null, { root: true })
 *
 *    It is the same for a mutation
 *
 *    commit('someMutation', null, { root: true})
 *
 *    leaving off the third parameter will make it so that vue only looks inside of this module to run the action or mutation.
 */

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + days);
};
