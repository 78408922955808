import CartItem from './CartItem';

class Order {
  constructor(orderName) {
    this.orderName = orderName;
    this.cart = new Array();
  }

  setMongoId(id) {
    this._id = id;
  }

  /**
   * @param {Array} cart
   */
  setCart(cart) {
    const arr = new Array();
    cart.forEach((el) => {
      arr.push(
        new CartItem(
          el.name,
          el.image,
          el.productId,
          el.variantId,
          el.quantity,
          el.fulfilled,
          el.price,
          el.originalPrice,
          el.productNum,
          el.binLocation,
          el.variantRef,
          el.vendor,
          el.lb,
          el.oz,
          el.stock
        )
      );
      arr[arr.length - 1].setWholeSaleData(el.wholeSaleOne, el.wholeSaleTwo, el.wholeSaleThree);
    });
    this.cart = arr;
  }

  /**
   *
   * @param {cartItem} item
   */
  addToCart(item) {
    for (let i = 0; i < this.cart.length; i++) {
      if (
        item.variantId === this.cart[i].variantId &&
        item.variantGroup === this.cart[i].variantGroup
      ) {
        this.cart[i].increment(item.quantity);
        return;
      }
    }
    this.cart.push(item);
  }
  /**
   *
   * @param {cartItem} item
   */
  removeFromCart(id) {
    for (let i = 0; i < this.cart.length; i++) {
      if (id === this.cart[i].variantId) {
        this.cart.splice(i, 1);
        if (this.cart.length === 0) {
          this.cart = new Array();
        }
        return;
      }
    }
  }

  alterQuantity(item) {
    for (let i = 0; i < this.cart.length; i++) {
      if (item.variantId === this.cart[i].variantId) {
        this.cart[i].quantity += item.quantity;
        if (this.cart[i].quantity < 1) {
          this.cart[i].quantity = 1;
        }
        this.cart[i].verifyWholeSale();
      }
    }
  }
  setQuantity(payload) {
    for (let i = 0; i < this.cart.length; i++) {
      if (payload.variantId === this.cart[i].variantId) {
        this.cart[i].quantity = payload.quantity * 1;
        if (this.cart[i].quantity < 1) {
          this.cart[i].quantity = 1;
        }
      }
    }
  }
}

export default Order;
