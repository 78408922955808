/* eslint-disable no-unused-vars */
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    singleFilterTemplate: {},
    filterTemplates: new Map(),
    filter: {},

    unassignedFilterOptions: [],
    minPrice: 0,
    maxPrice: 0,
    initialMaxPrice: 100,
    inStock: false,
    outStock: false,

    options: new Map(),
    names: [],

    selectedFilterCount: 0,
    sort: 'A to Z',
    limit: 50,
    currentPage: 1,
    loading: false,
    filterKey: 0,
    totalProducts: 0,
    unassignedLoading: false,
  },
  mutations: {
    setFilterTemplates(state, data) {
      const f = new Map();
      for (let i = 0; i < data.length; i++) {
        f.set(data[i].name, data[i]);
      }
      state.filterTemplates = f;
    },
    alterOrderOfLabel(state, data) {
      const filterTemplate = state.filterTemplates.get(data.name);
      const filter = filterTemplate.filters.filter((el) => el._id === data.labelId)[0];

      filterTemplate.filters = filterTemplate.filters.filter((el) => el._id != data.labelId);
      filterTemplate.filters.splice(data.index, 0, filter);
      state.filterTemplates.set(data.name, filterTemplate);
    },
    setUnassignedFilterOptions(state, data) {
      state.unassignedFilterOptions = data;
    },
    setFilterStatic(state, data) {
      state.filter = data;
    },
    setSingleFilterTemplate(state, data) {
      state.singleFilterTemplate = data;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSort(state, payload) {
      state.sort = payload;
      state.filterKey++;
    },
    setNewFilter(state) {},
    setMinPrice(state, payload) {
      state.minPrice = payload;
    },
    setMaxPrice(state, payload) {
      state.maxPrice = payload;
    },
    setFilterInStock(state, payload) {
      state.inStock = payload;
      state.getFilterCount += 1;
    },
    setFilterOutStock(state, payload) {
      state.outStock = payload;
      state.getFilterCount += 1;
    },
    clearAllFilters(state) {
      state.options = new Map();
      state.names = [];
      state.minPrice = 0;
      state.maxPrice = state.initialMaxPrice;
      state.inStock = false;
      state.outStock = false;
      state.filterKey++;
    },
    /**
     * @param
     * @param {key: String, value: String} payload
     */
    alterOptionFilter(state, payload) {
      if (!state.options) {
        state.options = new Map();
      }

      //check to see if the key has already been added.
      if (state.options.has(payload.name)) {
        //if the key has been added.
        const values = state.options.get(payload.name);
        let i = 0;
        //check to see if the value is present, if it is then remove it.
        if (-1 < (i = values.indexOf(payload.value))) {
          values.splice(i, 1);

          //if the removed item was the last item in the array, the key of the map points to nothing, so remove it.
          if (values.length === 0) {
            state.options.delete(payload.name);
          }
        } else {
          //if the value is not in the array, add it.
          values.push(payload.value);
        }
      } else {
        //if the key hasn't been added
        const values = [payload.value];
        state.options.set(payload.name, values);
      }
      state.filterKey += 1;
    },
    clearFilter(state, payload) {
      if (state.options.has(payload)) {
        state.options.delete(payload);
      }
      state.filterKey++;
    },

    createDynamicFilter(state, data) {
      const products = data;
      //names are the different filter categories
      const filter = new Map();

      if (products) {
        filter.set('Vendor', new Set());
      }

      for (let productIndex = 0; productIndex < products.length; productIndex++) {
        const product = products[productIndex];

        if (product.vendor) {
          const values = filter.get('Vendor');
          values.add(product.vendor);
          filter.set('Vendor', values);
        }

        //the temporarySet will hold the set for the option that the for loop will be going thorugh.
        // This will make it so that the .get(optionName) will only have to be called once.
        let temporarySet = null;

        //delete this after rebuilding the data
        for (let optionIndex = 0; optionIndex < product.options.length; optionIndex++) {
          let singleFilter = null;
          const optionName = product.options[optionIndex];

          //if the filter has already been used.
          singleFilter = state.filterTemplates.get(optionName);
          if (filter.has(optionName)) {
            temporarySet = filter.get(optionName);
          } else {
            temporarySet = new Set();
          }

          for (let variantIndex = 0; variantIndex < product.variants.length; variantIndex++) {
            try {
              const optionValue = product.variants[variantIndex].options[optionIndex].toLowerCase();
              if (singleFilter && singleFilter.filters) {
                for (const f of singleFilter.filters) {
                  if (f.options.includes(optionValue)) {
                    temporarySet.add(f.label);
                  }
                }
              }
            } catch (error) {
              console.info(product.title);
              console.info(error);
            }
          }
          filter.set(optionName, temporarySet);
        }
      }

      const orderedList = [...state.filterTemplates.values()].sort((a, b) => a.order - b.order);

      state.filter.filterValues = [[...filter.get('Vendor')]];
      state.filter.filterNames = ['Vendor'];

      for (const c of orderedList) {
        if (filter.has(c.name)) {
          const val = state.filterTemplates.get(c.name).filters;
          const unorderedLabels = [...filter.get(c.name)];
          const orderedLabels = [];
          for (const label of val) {
            if (unorderedLabels.indexOf(label.label) > -1) {
              orderedLabels.push(label.label);
            }
          }
          state.filter.filterValues.push(orderedLabels);
          state.filter.filterNames.push(c.name);
        }
      }
      state.loading = false;
      state.filterKey++;
    },
    setUnassignedLoading(state, payload) {
      state.unassignedLoading = payload;
    },
  },
  getters: {
    getUnassignedLoading: ({ unassignedLoading }) => unassignedLoading,
    getLoading: ({ loading }) => loading,
    getFilterTemplates: ({ filterTemplates }) => filterTemplates,
    getUnassignedFilterOptions: ({ unassignedFilterOptions }) => unassignedFilterOptions,
    getSort: ({ sort }) => sort,
    getInStock: ({ inStock }) => inStock,
    getOutStock: ({ outStock }) => outStock,
    getSingleFilterTemplate: ({ filterTemplates }) => (payload) => {
      if (payload && filterTemplates) {
        const filter = filterTemplates.get(payload);
        return filter;
      }
      return {};
    },
    getIsAvailable: (state, getters) => (key, value) => {
      const products = getters.getFilteredProducts;
      return true;
    },
    getFilterCount: ({ options, minPrice, maxPrice, inStock, outStock, filterKey }) => {
      let count = 0;
      console.log(filterKey);
      for (const [key, values] of options) {
        count += values.length;
      }
      if (minPrice) count++;
      if (maxPrice) count++;
      if (inStock) count++;
      if (outStock) count++;

      return count;
    },
    getFilterNames: (state) => {
      if (state.filter) return state.filter.filterNames;
      return [];
    },
    getFilterValues: ({ filter }) => (key) => {
      for (let i = 0; i < filter.filterNames.length; i++) {
        if (key === filter.filterNames[i]) {
          return filter.filterValues[i];
        }
      }
    },
    getFilterValueSize: ({ filter }) => (key) => {
      for (let i = 0; i < filter.filterNames.length; i++) {
        if (key === filter.filterNames[i]) {
          return filter.filterValues[i].length;
        }
      }
    },
    getFilteredProducts(
      {
        options,
        minPrice,
        maxPrice,
        inStock,
        outStock,
        initialMaxPrice,
        filterTemplates,
        filterKey,
      },
      getters,
      rootState
    ) {
      let products = rootState.products.products;
      if (!products) {
        products = [];
      }
      console.log(filterKey);
      //filter for minPrice
      if (minPrice) {
        products = products.filter((el) => {
          return el.variants.some((k) => k.price >= minPrice);
        });
      }

      //filter for maxPrice
      if (maxPrice && maxPrice !== initialMaxPrice) {
        products = products.filter((el) => {
          return el.variants.some((k) => k.price <= maxPrice);
        });
      }

      //filter for inStock
      if (inStock) {
        products = products.filter((el) => {
          return el.variants.some((k) => k.stock > 0);
        });
      }

      //filter for out Of Stock
      if (outStock) {
        products = products.filter((el) => {
          return el.variants.some((k) => k.stock == 0);
        });
      }

      //filter for options
      if (options.size > 0) {
        for (const [key, values] of options) {
          products = products.filter((product) => {
            if (options.has(key)) {
              if (key == 'Vendor') {
                if (values.includes(product.vendor)) {
                  return product;
                }
                return;
              } else {
                let returnProduct = false;
                let keyTemplate = filterTemplates.get(key);
                const optionIndex = product.options.indexOf(key);

                if (optionIndex == -1) return;
                product.variants.some((variant) => {
                  try {
                    const variantOptionValue = variant.options[optionIndex].toLowerCase();
                    for (const filter of keyTemplate.filters) {
                      if (values.includes(filter.label)) {
                        if (filter.options.includes(variantOptionValue)) {
                          returnProduct = true;
                          return true;
                          // break;
                        }
                      }
                    }
                  } catch (error) {
                    console.log('there is an error with');
                    console.log(variant);
                  }
                  if (returnProduct) {
                    return true;
                  }
                });

                if (returnProduct) {
                  return product;
                }
              }
            }
          });
        }
      }
      return products;
    },
    getVariantColor: (state) => (color) => {
      const c = color.toLowerCase();
      if (state.filterTemplates.size > 0) {
        const colorFilter = state.filterTemplates.get('Color');
        for (const filter of colorFilter.filters) {
          if (filter.options.includes(c)) {
            if (filter.hex2) {
              return `<div class="rounded-l-full h-full w-1/2 overflow-hidden" style="background-color: #${filter.hex};"></div>
                <div class="h-full w-1/2 rounded-r-full overflow-hidden" style="background-color: #${filter.hex2};"></div>`;
            } else {
              return `<div class="w-full h-full rounded-full overflow-hidden" style="background-color: #${filter.hex};"/>`;
            }
          }
        }
      } else {
        return `<div class="h-full w-full rounded-full overflow-hidden" style="background-color: #eee;" />`;
      }
    },
    getSortedProducts(state, getters) {
      let products = getters.getFilteredProducts;
      switch (state.sort.toLowerCase()) {
        case 'a to z':
          return products.sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
          });
        case 'z to a':
          return products.sort((a, b) => {
            if (a.title > b.title) return -1;
            if (a.title < b.title) return 1;
            return 0;
          });
        case 'price low to high':
          return products.sort((a, b) => {
            if (a.variants[0].price < b.variants[0].price) return -1;
            if (a.variants[0].price > b.variants[0].price) return 1;
            return 0;
          });
        case 'price high to low':
          return products.sort((a, b) => {
            if (a.variants[0].price < b.variants[0].price) return 1;
            if (a.variants[0].price > b.variants[0].price) return -1;
            return 0;
          });
        case 'popularity':
          return products.sort((a, b) => {
            if (a.purchased > b.purchased) return 1;
            if (a.purchased < b.purchased) return -1;
            return 0;
          });
        default:
          return products;
      }
    },
    getSelectedFilters: ({ options, minPrice, maxPrice, inStock, outStock, initialMaxPrice }) => {
      const dynamic = new Map(options);
      const staticFilters = {};
      // if (maxPrice !== initialMaxPrice) {
      //   staticFilters.maxPrice = maxPrice;
      // }

      if (minPrice > 0) {
        staticFilters.minPrice = minPrice;
      }

      if (inStock) {
        staticFilters.inStock = inStock;
      }

      if (outStock) {
        staticFilters.outStock = outStock;
      }

      return {
        static: staticFilters,
        dynamic,
      };
    },
    getProcessedFilters: () => {
      return (selectedFilters, queryParams, clearFilters, scrollParams = {}) => {
        let query = {
          type: queryParams?.type,
          categories: queryParams?.categories,
          subcategories: queryParams?.subcategories,
          title: queryParams?.title,
          tags: queryParams?.tags,
          sort: queryParams?.sort,
          ...scrollParams,
        };

        if (!clearFilters) {
          query = {
            ...query,
            ...selectedFilters.static,
          };

          selectedFilters.dynamic.forEach((value, key) => {
            query[key] = value;
          });
        }
        return query;
      };
    },
    //used to determine how many products are available from the filtering.
    getFilteredProductCount(state, getters) {
      return getters.getFilteredProducts.length;
    },
  },
  actions: {
    async saveFilter({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/filters', payload);
        commit('setFilterTemplates', response.data);
        commit(
          'setError',
          {
            message: 'Successfully saved filter',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to save color',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },
    async createNewFilterCategory({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/filters', payload);
        commit('setFilterTemplates', response.data);
        commit(
          'setError',
          {
            message: 'Successfully saved filter',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to save color',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },

    async saveIndividualFilterModel({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/filters/single', payload);
        commit('setFilterTemplates', response.data);
        commit(
          'setError',
          {
            message: 'Successfully saved filter',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to save color',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },
    async deleteFilterTemplate({ commit }, payload) {
      try {
        const response = await axios.delete('/api/v1/filters/' + payload);
        commit('setFilterTemplates', response.data);
        commit(
          'setError',
          {
            message: 'Successfully deleted filter',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to delete filter',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },
    async getFilterTemplates({ commit }) {
      try {
        const response = await axios.get('/api/v1/filters/');
        commit('setFilterTemplates', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getSingleTemplate({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/filters/single' + payload);
        commit('setFilterTemplates', response.data.templates);
        if (
          response &&
          response.data &&
          response.data.templates &&
          response.data.templates.length > 0 &&
          response.data.templates[0].name === 'Color'
        ) {
          window.sessionStorage.setItem('colorFilter', JSON.stringify(response.data.templates[0]));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUnassignedFilterOptions({ commit }, payload) {
      try {
        commit('setUnassignedLoading', true);
        const response = await axios.get('/api/v1/filters/unassigned?options=' + payload);
        commit('setUnassignedFilterOptions', response.data);
        commit('setUnassignedLoading', false);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to save color',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },
    async getUnassignedFiltersWithUnassociatedValues({ commit }, payload) {
      try {
        const response = await axios.get(
          '/api/v1/filters/getUnassignedFiltersWithUnassociatedValues'
        );
        commit('setFilterTemplates', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async buildStaticFilters({ commit }) {
      try {
        axios.post('/api/v1/filters/build');
        commit(
          'setError',
          {
            message:
              'The file that populates the filters has begun rebuilding. No indicator will be given once it finishes, you may leave this page.',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
