import axios from 'axios';
import Order from '@/assets/utils/Order.js';
import CartItem from '@/assets/utils/CartItem';

export default {
  namespaced: true,
  state: {
    user: null,
    selectedCartIndex: 0,

    carts: [new Order('Cart')],

    initial: true,
    mostRecentPurchase: null,
    addedProduct: null,
    taxRate: 0.0775,
    cartName: '',
    orderKey: 0,
    //used for the product tile
    product: null,
    stripeToken: null,

    couponStatus: null,
    couponCode: null,
    orderTotal: null,
    finalizedOrder: null,
    shippingCosts: null,
  },
  mutations: {
    setCouponStatus(state, payload) {
      state.couponStatus = payload;
    },
    setShippingCosts(state, payload) {
      state.shippingCosts = payload;
    },
    clearTotals(state) {
      state.orderTotal = null;
      state.couponCode = null;
      state.couponStatus = null;
    },
    setCartIndex(state, payload) {
      state.selectedCartIndex = payload;
    },
    setCartName(state, payload) {
      state.cartName = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setToken(state, payload) {
      state.stripeToken = payload;
    },
    clearAddedProduct(state) {
      state.addedProduct = null;
    },
    setAddedProduct(state, payload) {
      state.addedProduct = payload;
    },
    clearOrder(state) {
      console.info('enter: order/clearOrder');
      state.carts[0] = new Order('');
      state.carts[0].orderName = 'Cart';
      state.selectedCartIndex = 0;
      state.taxRate = 0.0775;
      setLocalStorageOrder(state.carts[0]);
      state.orderKey++;
    },
    setCouponCode(state, payload) {
      state.couponCode = payload;
    },

    setOrder(state, payload) {
      state.finalizedOrder = payload;
    },

    setCarts(state, payload) {
      payload.forEach(function (cart) {
        const order = new Order(cart.orderName);
        order.setCart(cart.cart);
        order.setMongoId(cart._id);
        state.carts.push(order);
      });
    },

    addToCarts(state, payload) {
      const order = new Order(payload.orderName);
      order.setCart(payload.cart);
      order.setMongoId(payload._id);
      state.carts.push(order);
    },

    addOrderToCart(state) {
      state.carts.unshift(state.order);
    },

    removeCart(state) {
      state.carts.splice(state.selectedCartIndex, 1);
      state.selectedCartIndex -= 1;
    },

    setMostRecentPurchase(state, payload) {
      state.mostRecentPurchase = payload;
    },

    setUser(state, user) {
      if (!user && state.user) {
        user = state.user;
      } else {
        state.user = user;
      }
      state.carts[state.selectedCartIndex].paymentType = 'credit-card';
      if (user) {
        state.taxRate = 0.0775;
        if (user.company) {
          state.carts[state.selectedCartIndex].businessName = user.company.name;
          state.carts[state.selectedCartIndex].businessEmail = user.company.email;
          state.carts[state.selectedCartIndex].shippingAddress = user.company.address;
          state.carts[state.selectedCartIndex].shippingCity = user.company.city;
          state.carts[state.selectedCartIndex].shippingState = user.company.state;
          state.carts[state.selectedCartIndex].shippingZip = user.company.zip;
          state.carts[state.selectedCartIndex].associatedAccountNumber = user.company.accountNumber;
          state.carts[state.selectedCartIndex].customerRef = user.company.quickbooksId;
          state.carts[state.selectedCartIndex].salesman = user.company.salesman;
          state.carts[state.selectedCartIndex].paymentType = user.company.preferredPayment;
          state.carts[state.selectedCartIndex].shippingMethod = user.company.preferredShipping
            ? user.company.shippingMethod
            : 'local';
          if (user.company.taxExempt) {
            state.taxRate = 0;
          }
        } else {
          state.carts[state.selectedCartIndex].shippingAddress = user.shippingAddress;
          state.carts[state.selectedCartIndex].shippingCity = user.shippingCity;
          state.carts[state.selectedCartIndex].shippingState = user.shippingState;
          state.carts[state.selectedCartIndex].shippingZip = user.shippingZip;
          state.carts[state.selectedCartIndex].paymentType = user.paymentType;
          state.carts[state.selectedCartIndex].shippingMethod = user.shippingMethod
            ? user.shippingMethod
            : 'local';
        }
        state.carts[state.selectedCartIndex].name = user.name;
        state.carts[state.selectedCartIndex].email = user.email;
        state.carts[state.selectedCartIndex].phone = user.phone;

        const day = new Date();
        day.setDate(day.getDate() + 1);
        state.carts[state.selectedCartIndex].date = day.toISOString().substring(0, 10);
        day.setDate(day.getDate());
        state.carts[state.selectedCartIndex].deliveryDate = day.toISOString().substring(0, 10);
      }
      state.carts[state.selectedCartIndex].taxRate = state.taxRate;
    },
    alterKey(state) {
      state.orderKey++;
    },
    clearCarts(state, payload) {
      if (state.carts) state.carts = state.carts.filter((el) => el._id != payload._id);
    },
  },
  getters: {
    getCouponStatus: (state) => {
      return state.couponStatus;
    },
    getOrderTotal: (state, getters) => {
      if (state.orderTotal) {
        return state.orderTotal;
      }

      //the console log needs to be here to force a re-render.
      console.info(state.orderKey);
      let order = getters.getOrder;

      let subTotal = 0;
      order.cart.forEach((element) => {
        subTotal += element.price * 1 * (element.quantity * 1);
      });

      const tax = subTotal * 0.0775;
      const shipping = order.shipping ? order.shipping : 0;
      const total = tax * 1 + subTotal * 1 + shipping * 1;
      return {
        tax,
        shipping,
        subTotal,
        total,
        discount: 0,
      };
    },
    getProduct: (state) => {
      return state.product;
    },
    getUser: (state) => {
      return state.user;
    },
    getAddedProduct: (state) => {
      return state.addedProduct;
    },
    getMostRecentPurchase: (state) => {
      return state.mostRecentPurchase;
    },
    getCartCount: (state) => {
      let count = 0;
      state.carts[state.selectedCartIndex].cart.forEach((el) => {
        count += el.quantity;
      });
      console.info(state.orderKey);
      return count;
    },
    getOrder: (state) => {
      //the console log needs to be here to force a re-render.
      console.info(state.orderKey);

      let order = state.carts[state.selectedCartIndex];

      //get Cart from local storage if it is there.
      if (order.cart.length === 0 && state.selectedCartIndex == 0) {
        const localStorageOrder = JSON.parse(window.localStorage.getItem('order'));
        if (localStorageOrder) {
          order.setCart(localStorageOrder.cart);
        }
      }
      order.couponCode = state.couponCode;

      return order;
    },
    getCarts: (state) => {
      return state.carts;
    },
    getCart: (state) => {
      return state.carts[state.selectedCartIndex].cart;
    },
    getOrderKey: (state) => {
      return state.orderKey;
    },
    getIsDelayedPayment: (state) => {
      return !state.user ? false : !state.user.company ? false : state.user.company.creditLimit > 0;
    },
    getShippingCosts: ({ shippingCosts }) => shippingCosts,
  },
  actions: {
    async duplicateOrder({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/orders/duplicate', payload);
        commit(
          'setError',
          {
            message: `Order ${payload.orderNumber} has been successfully duplicated and can be found in your saved carts.`,
            color: 'green',
            duration: 7000,
          },
          { root: true }
        );
        commit('order/addToCarts', response.data, { root: true });
      } catch (error) {
        console.log(error);
      }
    },

    async alterQuantity({ state, commit }, payload) {
      try {
        state.carts[state.selectedCartIndex].alterQuantity(payload);
        if (state.selectedCartIndex != 0) {
          await axios.patch('/api/v1/orders/', state.carts[state.selectedCartIndex]);
        }
        commit('alterKey');
        if (state.selectedCartIndex === 0) {
          setLocalStorageOrder(state.carts[state.selectedCartIndex]);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async setQuantity({ state, commit }, payload) {
      state.carts[state.selectedCartIndex].setQuantity(payload);
      commit('alterKey');
    },

    /**
     * @param {*} state
     * @param { variant_id} payload
     */
    async removeFromCart({ state, commit }, payload) {
      try {
        state.carts[state.selectedCartIndex].removeFromCart(payload);
        if (state.selectedCartIndex === 0) {
          setLocalStorageOrder(state.carts[state.selectedCartIndex]);
        }
        if (state.selectedCartIndex != 0) {
          await axios.patch('/api/v1/orders/', state.carts[state.selectedCartIndex]);
        }
        commit('alterKey');
      } catch (error) {
        console.log(error);
      }
    },

    async addToCart({ state, commit }, payload) {
      try {
        state.carts[state.selectedCartIndex].addToCart(payload);
        commit('setAddedProduct', payload);
        commit('setCartName', state.carts[state.selectedCartIndex].orderName);

        if (state.selectedCartIndex != 0) {
          await axios.patch('/api/v1/orders/', state.carts[state.selectedCartIndex]);
        } else {
          setLocalStorageOrder(state.carts[state.selectedCartIndex]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveCart({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/orders/carts', payload);
        commit('addToCarts', response.data);

        commit(
          'setError',
          {
            message:
              'Order has been saved as a new Cart, click on the shopping cart to see this order.',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
        commit('alterKey');
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error saving cart',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async updateOrder({ commit }, payload) {
      try {
        await axios.patch('/api/v1/orders/', payload);
        commit(
          'setError',
          {
            message: 'Successfully updated.',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error saving cart',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async getCarts({ commit }) {
      try {
        const response = await axios.get('/api/v1/orders/carts');
        commit('setCarts', response.data);
        return true;
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error getting carts',
            color: 'red',
          },
          { root: true }
        );
        return false;
      }
    },

    // eslint-disable-next-line no-unused-vars
    async getOrderTotal({ commit, dispatch }, payload) {
      try {
        const response = await axios.patch('/api/v1/orders/getCost', payload);
        commit('setCouponStatus', response.data.couponStatus);
        if (response.data.couponType === 8 && response.data.couponStatus === 'success') {
          const { data } = await axios.get(
            '/api/v1/coupons/product?code=' + response.data.couponCode
          );
          const cartItem = new CartItem(
            data.title,
            data.image,
            data.product_id,
            data._id,
            1, //quantity
            0, //fulfilled
            0, //price
            0, //original price
            data.productNum,
            data.binLocation,
            data.quickbooksId,
            data.vendor,
            data.lb,
            data.oz,
            data.stock,
            data.isBundle,
            data.bundleItems,
            data.bundleDiscountPer
          );
          dispatch('order/addToCart', cartItem, { root: true });
        }
        console.log(response.data);
        commit('setOrder', response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async submitOrder({ commit }, payload) {
      console.info('enter: order/submitOrder');
      try {
        const response = await axios.post('/api/v1/orders/', payload);
        commit('clearOrder');
        commit('setMostRecentPurchase', response.data);
        commit('clearCarts', response.data);
      } catch (error) {
        if (error.message.includes('503')) {
          commit(
            'setError',
            {
              message:
                'Your outstanding balance has exceeded your credit limit. Please make a payment or contact accounting.',
              color: 'red',
              duration: 10000,
            },
            { root: true }
          );
        } else {
          commit(
            'setError',
            {
              message: error,
              color: 'red',
            },
            { root: true }
          );
          return false;
        }
      }
    },

    async softUpdate({ commit }, payload) {
      console.info('enter: order/softUpdate');
      try {
        await axios.put('/api/v1/orders/admin', payload);
      } catch (error) {
        commit(
          'setError',
          {
            message: error,
            color: 'red',
          },
          { root: true }
        );
        return false;
      }
    },
    async deleteOrder({ commit }, payload) {
      try {
        await axios.delete('/api/v1/orders/' + payload);
        commit('removeCart');
        commit(
          'setError',
          {
            message: 'Successfully deleted order',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Failed to delete Order',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async verifyCoupon({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/orders/verifyCoupon/' + payload);
        commit('setCouponStatus', response.couponVerification);
      } catch (error) {
        console.log(error);
      }
    },
    async saveShippingCosts({ commit }, payload) {
      try {
        await axios.post('/api/v1/orders/shipping', payload);
        commit('setError', {
          message: 'Successfully saved shipping.',
          color: 'green',
        });
      } catch (error) {
        console.log(error);
        commit('setError', {
          message: 'Failed to save shipping.',
          color: 'red',
        });
      }
    },
    async getShippingCosts({ commit }) {
      try {
        const response = await axios.get('/api/v1/orders/shipping');
        commit('setShippingCosts', response.data);
      } catch (error) {
        console.log(error);
        commit('setError', {
          message: 'Failed to save shipping.',
          color: 'red',
        });
      }
    },
  },
};

const setLocalStorageOrder = (order) => {
  if (order.orderName === 'Cart') window.localStorage.setItem('order', JSON.stringify(order));
};
