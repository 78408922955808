class item {
  /**
   * @param {String} name
   * @param {String} image
   * @param {ObjectId} productId
   * @param {ObjectId} variantId
   * @param {Int} quantity
   * @param {Int} fulfilled
   *
   * @param {Float} price
   * @param {String} productNum
   * @param {String} variantRef
   * @param {String} binLocation
   * @param String vendor
   * @param {String} lb
   * @param {String} oz
   * @param {Int} stock
   * @param {Boolean} isBundle
   * @param {BundleItems} bundleItems
   * @param {String | null} variantGroup
   *
   * */
  constructor(
    name,
    image,
    productId,
    variantId,
    quantity,
    fulfilled = 0,
    price = 0,
    originalPrice = 0,
    productNum = null,
    binLocation = null,
    variantRef = null,
    vendor = null,
    lb = 0,
    oz = 0,
    stock = 0,
    isBundle = false,
    bundleItems = [],
    bundleDiscount = 0,
    variantGroup = null
  ) {
    this.image = image;
    this.productId = productId;
    this.variantId = variantId;
    this.name = name;
    this.quantity = quantity;
    this.fulfilled = fulfilled;
    this.price = price;
    this.originalPrice = originalPrice;
    this.productNum = productNum;
    this.binLocation = binLocation;
    this.lb = lb;
    this.oz = oz;
    this.variantRef = variantRef;
    this.vendor = vendor;
    this.stock = stock;
    this.isBundle = isBundle;
    this.bundleItems = bundleItems.map((bundleItem) => {
      return {
        variantRef: bundleItem.variant.quickbooksId,
        quantity: bundleItem.quantity,
        price: bundleItem.price,
        cost: bundleItem.variant.cost,
        productTitle: bundleItem.productTitle,
        productNum: bundleItem.productNum,
        product: bundleItem.product,
        variant: bundleItem.variant._id,
      };
    });
    this.bundleDiscount = bundleDiscount;
    this.variantGroup = variantGroup;
  }

  /**
   * @param {*} variantId
   * @param {String | null} variantGroup
   * returns true if the variant id's match, else returns false
   */
  equals(variantId, variantGroup = null) {
    if (variantId === this.variantId && variantGroup === this.variantGroup) return true;
    return false;
  }

  /**
   *
   * @param {*} num number greater than 0
   */
  increment(num) {
    this.quantity = this.quantity * 1;
    this.quantity += num * 1;
  }

  /**
   *
   * @param {*} num number greater than 0
   */
  decrement(num) {
    this.quantity = this.quantity * 1;
    this.quantity -= num * 1;
    if (this.quantity < 1) {
      this.quantity = 1;
    }
  }

  setQuantity(num) {
    this.quantity = num;
  }
  /**
   * set the wholesale data so .verifyWholeSale can function properly.
   * @param {Object} wholeSaleOne
   * @param {Object} wholeSaleTwo
   * @param {Object} wholeSaleThree
   */
  setWholeSaleData(wholeSaleOne, wholeSaleTwo, wholeSaleThree) {
    this.wholeSaleOne = wholeSaleOne;
    this.wholeSaleTwo = wholeSaleTwo;
    this.wholeSaleThree = wholeSaleThree;
  }

  verifyWholeSale() {
    this.price =
      this.quantity >= this.wholeSaleThree.quantity
        ? this.wholeSaleThree.price
        : this.quantity >= this.wholeSaleTwo.quantity
        ? this.wholeSaleTwo.price
        : this.quantity >= this.wholeSaleOne.quantity
        ? this.wholeSaleOne.price
        : this.originalPrice;
  }
}

export default item;
