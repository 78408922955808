import axios from 'axios';

export default {
  namespaced: true,
  state: {
    order: null,
    cart: {},
    finished: false,
  },
  mutations: {
    setPartialPaymentOrder(state, payload) {
      state.order = payload.order;
      state.cart = payload.cart;
    },
    setFullPaymentOrder(state, payload) {
      console.info('delayedPayments/setFullPaymentOrder');

      state.order = payload.order;
      let cartItems = [];
      for (const cart of payload.carts) {
        if (!cart.paid) cartItems = cartItems.concat(cart.cartItems);
      }
      state.cart.cartItems = cartItems;
      state.cart.shipping = payload.order.shipping;
      state.cart.discount = payload.order.discount;
    },

    setFinished(state, payload) {
      state.finished = payload;
    },
    clear(state) {
      state.order = null;
      state.cart = {};
      state.finished = false;
    },
  },
  getters: {
    getCart: (state) => {
      return state.cart.cartItems;
    },
    getOrder: (state) => {
      return state.order;
    },
    getOrderTotal: (state) => {
      const shipping = state.cart.shipping ? state.cart.shipping : 0;
      const discount = state.cart.discount ? state.cart.discount : 0;

      return {
        shipping,
        discount,
      };
    },
    getFinished: (state) => {
      return state.finished;
    },
  },
  actions: {
    async markOrderAsPayed({ commit }, payload) {
      try {
        await axios.post('/api/v1/orders/payOrder', payload);
        commit(
          'setError',
          {
            message: `Order has been paid`,
            color: 'green',
            duration: 7000,
          },
          { root: true }
        );
        commit('setFinished', true);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
