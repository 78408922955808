<!--
    AddCartRequiredProduct.vue

    Component for redirecting to required product alongwith the product added in cart.

    Example:
    <AddCartRequiredProduct v-if="hasRequiredProduct" @setValues="setValues"></AddCartRequiredProduct>
-->

<template>
  <div class="text-center">
    <div class="related-product" v-for="(rProduct, i) in relatedProduct" :key="i">
      <p>{{ relatedProductText }}</p>
      <p>
        <router-link class="text-2xl" :to="`/product/${rProduct.product}`">
          Click here
        </router-link>
        to View and Add required Items.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddCartRequiredProduct',
  props: {
    relatedProductText: { type: String, default: '' },
    relatedProduct: { type: Array, default: new Array() },
  },
};
</script>
