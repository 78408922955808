import axios from 'axios';

export default {
  namespaced: true,
  state: {
    user: null,
    users: [],
    userSearch: null,
    loading: true,
    queryTotal: 100,

    commercialUsers: [],
    employees: [],
    primaryUsers: [],
  },
  mutations: {
    clearFilter(state) {
      state.userSearch = null;
    },
    clearUsers(state) {
      state.users = [];
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserSearch(state, payload) {
      state.userSearch = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setQueryTotal(state, payload) {
      state.queryTotal = payload;
    },
    setCommercialUsers(state, payload) {
      state.commercialUsers = payload;
    },
    setEmployees(state, payload) {
      state.employees = payload;
    },
    setPrimaryUsers(state, payload) {
      state.primaryUsers = payload;
    },
  },
  getters: {
    getQueryTotal: ({ queryTotal }) => queryTotal,
    getUser: ({ user }) => user,
    getEmployees: ({ employees }) => employees,
    getUsers: ({ users }) => users,
    getPrimaryUsers: ({ primaryUsers }) => primaryUsers,
    getCompanyUsers: (state, getters) => {
      const users = getters.getUsers;
      return users.filter((el) => {
        return el.company;
      });
    },
    getCommercialUsers: ({ commercialUsers }) => commercialUsers,
    getUserSearch: (state) => {
      return state.userSearch;
    },
    getUserOrders: ({ user }) => {
      if (user) {
        return user.orders;
      }
      return user;
    },
    getMyOrders: ({ user }) => {
      user.orders.forEach((el) => {
        el.date = el.date.slice(0, 10);
      });

      return user.orders.sort((a, b) => {
        return a.orderNumber > b.orderNumber ? -1 : 1;
      });
    },

    getOrder: (state, getters) => (payload) => {
      let order = null;
      getters.getMyOrders.some((el) => {
        if (el._id === payload) {
          order = el;
          return;
        }
      });
      return order;
    },

    getOrders: (state) => {
      if (state.user) {
        return state.user.orders;
      }
    },

    getSpecialPricedItems: ({ user }) => {
      return user.variants;
    },
    getLoading: (state) => {
      return state.loading;
    },
  },
  actions: {
    async updateUser({ commit }, data) {
      if (!data.email) {
        commit(
          'setError',
          {
            message: 'User must have an email.',
            color: 'red',
          },
          { root: true }
        );
        return;
      }
      delete data.orders;
      delete data.variants;
      delete data.tiers;
      delete data.favorites;
      delete data.savedCarts;
      commit('setLoading', true);
      try {
        let response = await axios.patch('/api/v1/users/', data);

        commit('setUser', response.data);

        commit(
          'setError',
          {
            message: 'Successfully updated profile',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error updating profile',
            color: 'red',
          },
          { root: true }
        );
      }
      commit('setLoading', false);
    },

    async saveTiers({ commit }, data) {
      try {
        delete data.orders;
        delete data.variants;
        const tiers = data.tiers;

        let response = await axios.patch('/api/v1/users/tiers', { _id: data._id, tiers });

        commit('setLoading', true);
        if (data._id) {
          response = await axios.get('/api/v1/users/' + data._id);
        } else {
          response = await axios.get('/api/v1/users/' + response.data._id);
        }
        commit('setUser', response.data);
        commit('setLoading', false);

        commit(
          'setError',
          {
            message: 'Successfully updated profile',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error updating profile',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async getUsers({ commit }, payload) {
      try {
        let query;
        if (payload != null) {
          Object.keys(payload).forEach((key) => {
            if (
              payload[key] === undefined ||
              payload[key] === 'undefined' ||
              payload[key] === null
            ) {
              delete payload[key];
            }
          });

          query =
            '?' +
            JSON.stringify(payload)
              .replace(/}/g, '')
              .replace(/{/g, '')
              .replace(/"/g, '')
              .replace(/:/g, '=')
              .replace(/,/g, '&');
        } else {
          query = '';
        }

        commit('setLoading', true);
        console.log(query);
        const response = await axios.get('/api/v1/users/all' + query);
        commit('setUsers', response.data.users);
        commit('setQueryTotal', response.data.total);
        commit('setLoading', false);
      } catch (error) {
        console.log(error);
      }
    },

    async getCommercialUsers({ commit }, payload) {
      //check to see if these users are saved in session storage first
      const commercialUsers = JSON.parse(window.sessionStorage.getItem('commercialUsers'));
      try {
        if (commercialUsers) {
          commit('setCommercialUsers', commercialUsers);
        } else {
          const response = await axios.get(
            '/api/v1/users/all?permissionLevel=Commercial' + payload
          );
          commit('setCommercialUsers', response.data.users);
          window.sessionStorage.setItem('commercialUsers', JSON.stringify(response.data.users));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getEmployees({ commit }) {
      const employees = JSON.parse(window.sessionStorage.getItem('employees'));
      try {
        if (employees) {
          commit('setEmployees', employees);
        } else {
          const response = await axios.get(
            '/api/v1/users/all?permissionLevel=Employee&permissionLevel=Admin'
          );
          commit('setEmployees', response.data.users);
          window.sessionStorage.setItem('employees', JSON.stringify(response.data.users));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPrimaryUsers({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/users/all?accountRole=Primary' + payload);
        commit('setPrimaryUsers', response.data.users);
      } catch (error) {
        console.log(error);
      }
    },

    async getUsersSimple({ commit }) {
      try {
        const response = await axios.get('/api/v1/users/simple');
        commit('setUsers', response.data);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     *
     * @param {*} payload User._id
     */
    async getUser({ commit }, payload) {
      commit('setLoading', true);
      try {
        if (payload === 'new') {
          commit('setUser', { _id: null, orders: [], products: [], associatedAccounts: [] });
        } else {
          const response = await axios.get('/api/v1/users/' + payload);
          commit('setUser', response.data);
        }
      } catch (error) {
        console.log(error);
      }
      commit('setLoading', false);
    },

    async deleteUser({ commit }, data) {
      try {
        await axios.delete('/api/v1/users/' + data._id);
        commit(
          'setError',
          {
            message: 'Successfully deleted user',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error deleting user',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async addOrderToHistory({ commit }, payload) {
      try {
        await axios.post('/api/v1/users/addOrderToHistory', payload);
        commit(
          'setError',
          {
            message: 'Added order to history',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        if (error.message.includes('404'))
          commit(
            'setError',
            {
              message: 'That order could not be found in the database.',
              color: 'red',
            },
            { root: true }
          );
        else
          commit(
            'setError',
            {
              message: 'Error adding order to history',
              color: 'red',
            },
            { root: true }
          );
      }
    },
  },
};
