<template>
  <div
    class="relative w-72 h-72 shadow-md ml-3 overflow-hidden my-5 rounded-xl bg-gray-400 animate-pulse"
  ></div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {},
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
