import axios from 'axios';
import calculateCosts from '@/assets/functions/calculateCosts';

export default {
  namespaced: true,
  state: {
    search: null,
    exactMatch: false,
    variants: [],
    loading: false,
    user: null,
    taxRate: 0.0775,
    order: { cart: [] },
    duplicateOrderCart: null,
    duplicateUser: null,
    includeTags: false,
    stripePaymentIntent: null,
    processingPayment: false,
  },
  mutations: {
    clearOrder(state) {
      state.order = { cart: [] };
      state.user = null;
      state.taxRate = 0.0775;
      state.variants = [];
      state.search = null;
      state.loading = false;
    },

    setDuplicateOrder(state, payload) {
      state.duplicateOrderCart = payload;
    },
    setDuplicateUser(state, payload) {
      state.duplicateUser = payload;
    },
    clearVariants(state) {
      state.variants = [];
    },
    setUser(state, user) {
      if (!user) {
        user = {};
      }
      console.log(user);
      state.user = user;
      if (user.company) {
        state.order.businessName = user.company.name;
        state.order.businessEmail = user.company.email;
        state.order.shippingAddress = user.company.address;
        state.order.shippingCity = user.company.city;
        state.order.shippingState = user.company.state;
        state.order.shippingZip = user.company.zip;
        state.order.associatedAccountNumber = user.company.accountNumber;
        state.order.customerRef = user.company.quickbooksId;
        state.order.salesman = user.company.salesman;

        if (user.company.taxExempt) {
          state.taxRate = 0;
        }
      } else {
        state.order.shippingAddress = user.shippingAddress;
        state.order.shippingCity = user.shippingCity;
        state.order.shippingState = user.shippingState;
        state.order.shippingZip = user.shippingZip;
      }
      state.order.paymentType = user.paymentType ? user.paymentType : 'Terms';
      state.order.name = user.name;
      state.order.email = user.email;
      state.order.phone = user.phone;
      state.order.shippingMethod = user.shippingMethod ? user.shippingMethod : 'local';

      state.order.billingAddress = state.order.shippingAddress;
      state.order.billingCity = state.order.shippingCity;
      state.order.billingState = state.order.shippingState;
      state.order.billingZip = state.order.shippingZip;

      const day = new Date();
      day.setDate(day.getDate() + 1);
      state.order.date = day.toISOString().substr(0, 10);
      day.setDate(day.getDate() + 1);
      state.order.deliveryDate = day.toISOString().substr(0, 10);
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setLoading(state, payload) {
      setTimeout(() => {
        state.loading = payload;
      }, 1000);
    },
    setExactMatch(state, payload) {
      state.exactMatch = payload;
    },

    setVariants(state, payload) {
      const variants = new Array();
      payload.forEach((product) => {
        product.variants.forEach((variant) => {
          if (variant) {
            variant.title = product.title;
            variant.product_id = product._id;
            variant.vendor = product.vendor;
            variant.tags = product.tags.concat(product.subcategories, product.categories);
            variants.push(variant);
          }
        });
      });
      state.variants = variants;
    },
    addToCart(state, payload) {
      state.order.cart.push(payload);
    },
    //payload is productNum
    removeFromCart(state, payload) {
      state.order.cart = state.order.cart.filter((el) => {
        return el.productNum != payload;
      });
    },
    setOrder(state, payload) {
      state.order = payload;
    },
    setIncludeTags(state, payload) {
      state.includeTags = payload;
    },
    setPaymentIntent(state, payload) {
      state.stripePaymentIntent = payload;
    },
    setProcessingPayment(state, payload) {
      state.processingPayment = payload;
    },
  },
  getters: {
    getProcessingPayment: ({ processingPayment }) => processingPayment,
    getStripePaymentIntent: ({ stripePaymentIntent }) => stripePaymentIntent,
    getExactMatch(state) {
      return state.exactMatch;
    },
    getIncludeTags(state) {
      return state.includeTags;
    },
    getDuplicate(state) {
      return {
        order: state.duplicateOrderCart,
        user_email: state.duplicateUser,
      };
    },
    getOrder(state, getters, rootState) {
      if (state.order == null) {
        return {};
      }
      console.log(rootState.order.orderKey);
      const order = state.order;

      let totalCartItems = 0;
      for (let i = 0; i < order.cart.length; i++) {
        totalCartItems += order.cart[i].quantity;
      }
      //set shipping price
      const shippingCosts = rootState.order.shippingCosts;
      if (order.subTotal > 0) {
        switch (order.shippingMethod) {
          case 'standard':
          case 'ground':
          case 'fedex':
            order.shipping =
              order.subTotal < shippingCosts[0].price * 1
                ? shippingCosts[0].cost * 1
                : order.subTotal < shippingCosts[1].price * 1
                ? shippingCosts[1].cost * 1
                : order.subTotal < shippingCosts[2].price * 1
                ? shippingCosts[2].cost * 1
                : order.subTotal < shippingCosts[3].price * 1
                ? shippingCosts[3].cost * 1
                : shippingCosts[4].cost * 1;
            break;
          case 'air':
            order.shipping =
              order.subTotal < shippingCosts[0].price * 1
                ? shippingCosts[0].cost * 2
                : order.subTotal < shippingCosts[1].price * 1
                ? shippingCosts[1].cost * 2
                : order.subTotal < shippingCosts[2].price * 1
                ? shippingCosts[2].cost * 2
                : order.subTotal < shippingCosts[3].price * 1
                ? shippingCosts[3].cost * 2
                : shippingCosts[4].cost * 2;
            break;
          default:
            order.shipping = 0;
            break;
        }
      } else {
        order.shipping = 0;
      }
      if (order.shipping > 0) {
        const itemShipping = order.shipping / totalCartItems;
        for (let i = 0; i < order.cart.length; i++) {
          const variant = order.cart[i];
          variant.itemShipping = itemShipping * variant.quantity;
        }
      }

      const { total, subtotal, tax, shipping } = calculateCosts(
        order.cart,
        state.taxRate,
        order.shipping,
        0,
        0
      );
      order.total = total;
      order.subTotal = subtotal;
      order.taxRate = state.taxRate;
      order.tax = tax;
      order.shipping = shipping;
      return order;
    },
    getVariants: (state) => {
      if (state.exactMatch && state.search && state.variants) {
        const search = state.search.toLowerCase();
        return state.variants.filter((el) => el.productNum.toLowerCase() == search);
      }
      return state.variants;
    },

    getUserOrders: (state) => {
      if (state.user === null) {
        return new Array();
      }
      return state.user.orders;
    },
    getSearch: (state) => {
      return state.search;
    },
    getLoading: (state) => {
      return state.loading;
    },
    getUser: (state) => {
      return state.user;
    },
  },
  actions: {
    async saveCart({ commit }, payload) {
      try {
        await axios.post('/api/v1/users/cart', payload);
        commit(
          'setError',
          {
            message: 'Order has been saved as a bid.',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error saving bid',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async duplicateOrder(state, payload) {
      return new Promise((resolve, reject) => {
        try {
          return resolve(axios.patch('/api/v1/orders/duplicate', payload));
        } catch (error) {
          return reject(error);
        }
      });
    },

    async getUserProducts({ state, commit }, payload) {
      commit('setLoading', true);
      console.log(payload);
      // eslint-disable-next-line no-async-promise-executor
      commit('clearVariants');
      let path = '';
      if (state.search) {
        path = `?search=${state.search}`;
      }
      try {
        const response = await axios.get('/api/v1/companies/products/' + payload + path);
        commit('setVariants', response.data);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          { message: 'There was an error when retreiving the products', color: 'red' },
          { root: true }
        );
      }
      commit('setLoading', false);
    },

    async submitOrder({ commit }, payload) {
      try {
        console.info(payload);
        const response = await axios.post('/api/v1/orders/createOrder', payload);
        commit('setOrder', response.data);
      } catch (error) {
        commit(
          'setError',
          {
            message: error,
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
        return false;
      }
    },

    /**
     *
     * @param {*} payload User.email
     */
    async getUser({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/users/' + payload);
        commit('setUser', response.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
