<template>
  <div>
    <!-- Desktop Display -->
    <div class="hidden sm:fixed modal-mask" v-if="largeScreen">
      <transition name="fadeFast">
        <div class="modal-wrapper" @click.self="close">
          <div
            class="mx-auto w-11/12 sm:w-3/4 lg:w-7/12 font-medium relative rounded-sm shadow-md transition-all duration-300 bg-gray-200 pb-2"
          >
            <!-- Module Header -->
            <div
              @click="close"
              class="absolute top-0 right-0 px-3 pb-1 sm:hover:bg-red-400 text-4xl rounded-tr-sm cursor-pointer leading-none font-normal text-white"
            >
              &#215;
            </div>
            <div class="w-full px-4 py-2 bg-primary shadow text-white rounded-t-sm">
              {{ product.title }}
            </div>
            <div class="mt-4 mb-2 mx-4 max-h-1/2 overflow-y-auto">
              <div v-if="selectedVariant" class="md:max-h-1/2">
                <div class="md:grid md:grid-cols-3 pb-2">
                  <router-link
                    :to="`/product/${product._id}`"
                    tag="div"
                    class="overflow-hidden h-40 md:h-64 w-40 md:w-56 mt-2 sm:mx-auto md:mr-2 bg-no-repeat bg-contain bg-center bg-white rounded shadow cursor-pointer"
                    :style="
                      selectedVariant.thumbnail
                        ? `background-image: url('${selectedVariant.thumbnail}');`
                        : selectedVariant.image
                        ? `background-image: url('${selectedVariant.image}');`
                        : `background-image: url(/images/icons/image-coming-soon.png);`
                    "
                    :alt="product.title"
                  ></router-link>
                  <div class="my-2 md:ml-8 col-span-2 md:my-auto">
                    <Pricing :product="product" :variant="selectedVariant" />
                    <Purchasing
                      :variantGroup="selectedGroup"
                      :product="product"
                      :variant="selectedVariant"
                      :flex="true"
                    />
                  </div>
                </div>
                <Options
                  :product="product"
                  :variantIndex="selectedIndex"
                  :variantGroupIndex="selectedGroup"
                  @changeVariant="changeVariant"
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End Desktop Display -->

    <!-- Mobile Display -->
    <Overlay v-else @close="$emit('close')" position="top">
      <div v-if="selectedVariant">
        <div class="md:grid md:grid-cols-3">
          <div>
            <router-link
              :to="`/product/${product._id}`"
              tag="div"
              class="overflow-hidden h-64 flex items-center justify-center bg-no-repeat bg-contain bg-center bg-white cursor-pointer shadow"
              :style="
                selectedVariant.thumbnail
                  ? `background-image: url('${selectedVariant.thumbnail}');`
                  : selectedVariant.image
                  ? `background-image: url('${selectedVariant.image}');`
                  : `background-image: url(/images/icons/image-coming-soon.png);`
              "
              :alt="product.title"
            ></router-link>
          </div>
          <div class="m-2 col-span-2 md:flex justify-center md:my-auto">
            <Options
              :product="product"
              :variantIndex="selectedIndex"
              :variantGroupIndex="selectedGroup"
              @changeVariant="changeVariant"
            />
          </div>
        </div>

        <Pricing :product="product" :variant="selectedVariant" />
        <Purchasing
          :variantGroup="selectedGroup"
          :product="product"
          :variant="selectedVariant"
          :flex="true"
        />
      </div>
    </Overlay>
  </div>
</template>

<script>
import Options from './ProductOptions';
import Pricing from './ProductPricing';
import Purchasing from './AddToCart';
import Overlay from '@/components/shared/MobileOverlay';

export default {
  data: function () {
    let largeScreen = window.innerWidth >= 600;
    return {
      selectedGroup: null,
      selectedVariant: null,
      selectedIndex: 0,
      largeScreen: largeScreen,
    };
  },
  computed: {
    product() {
      return this.$store.getters['order/getProduct'];
    },
  },
  watch: {
    $route() {
      this.$emit('close');
    },
  },
  methods: {
    changeVariant(data) {
      const { selectedIndex, selectedGroup } = data;
      this.selectedVariant = this.product.variants[selectedIndex];
      this.selectedIndex = selectedIndex;
      this.selectedGroup = selectedGroup;
    },
    initialize() {
      this.selectedVariant = this.product.variants[this.selectedIndex];
    },
    close() {
      this.show = false;
      setTimeout(() => {
        this.$emit('close');
      }, 100);
    },
  },
  components: {
    Options,
    Pricing,
    Purchasing,
    Overlay,
  },
  mounted() {
    this.initialize();
  },
  beforeMount() {
    setTimeout(() => {
      this.show = true;
    }, 50);
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
