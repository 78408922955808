/* eslint-disable no-unused-vars */
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    notifications: null,
    notificationTemplates: null,
    newNotification: null,
  },
  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setNotificationTemplates(state, payload) {
      state.notificationTemplates = payload;
    },
    setNewNotification(state, payload) {
      state.newNotification = payload;
    },
    clearMostRecent(state) {
      state.newNotification = null;
    },
  },
  getters: {
    getNotifications: ({ notifications }) => notifications,
    getNotificationTemplates: ({ notificationTemplates }) => notificationTemplates,
    getMostRecent: ({ newNotification }) => newNotification,
    getNotificationNumber: (state) => {
      return state.notifications ? state.notifications.length : 0;
    },
  },
  actions: {
    async createNotification({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/notifications', payload);
        commit(
          'setError',
          {
            message: ``,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: ``,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async getMostRecent({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/notifications/new');
        commit('setNewNotification', response.data);
      } catch (error) {
        console.error(error);
        commit('user/setUser', null, { root: true });
        commit('order/clearOrder', { root: true });
        commit('products/setFavorites', [], { root: true });
        commit('setNotifications', null);

        commit(
          'setError',
          {
            message: 'Your session has expired, please log in again.',
            color: 'green',
            duration: 0,
          },
          { root: true }
        );
      }
    },

    async updateNotification({ commit }, payload) {
      try {
        commit('clearMostRecent');
        await axios.put('/api/v1/notifications', payload);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: ``,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async markAllNotificationsAsRead({ commit }, payload) {
      try {
        await axios.delete('/api/v1/notifications');
        commit('setNotifications', []);
        commit(
          'setError',
          {
            message: `Successfully deleted all your notifications. `,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: `Failed to delete all your notifications.`,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async getNotifications({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/notifications' + payload);
        commit('setNotifications', response.data);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: ``,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async deleteNotification({ commit }, payload) {
      try {
        await axios.delete('/api/v1/notifications/' + payload);
        commit(
          'setError',
          {
            message: `Successfully deleted notification`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: ``,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async createNotificationTemplate({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/notifications/template', payload);
        commit('setNotificationTemplates', response.data);
        commit(
          'setError',
          {
            message: ``,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: `There was an error creating the template. This is usually because there can only be one of each notification type. Please make sure to change "New notification template" to a different type and save.`,
            color: 'red',
            duration: 15000,
          },
          { root: true }
        );
      }
    },
    async updateNotificationTemplate({ commit }, payload) {
      try {
        const response = await axios.put('/api/v1/notifications/template', payload);
        commit(
          'setError',
          {
            message: ``,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: ``,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async getNotificationTemplates({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/notifications/template' + payload);
        commit('setNotificationTemplates', response.data);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: ``,
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async deleteNotificationTemplate({ commit }, payload) {
      try {
        const response = await axios.delete('/api/v1/notifications/template/' + payload);
        commit(
          'setError',
          {
            message: ``,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: ``,
            color: 'red',
          },
          { root: true }
        );
      }
    },
  },
};
