import axios from 'axios';
export default {
  namespaced: true,
  state: {
    order: null,
    additions: new Array(),
    removals: new Array(),
    loading: true,
  },
  mutations: {
    setOrder(state, payload) {
      state.order = payload;
    },
    addToCart(state, payload) {
      state.order.carts[payload.index].cartItems.push(payload.cartItem);
      state.additions.push(payload.cartItem);
    },
    /**
     * @param {Object} payload.cartItem
     * @param {Number} payload.index
     */
    removeFromCart(state, payload) {
      state.order.carts[payload.index].cartItems = state.order.carts[
        payload.index
      ].cartItems.filter((el) => {
        return el.productNum != payload.cartItem.productNum;
      });
      state.removals.push(payload.cartItem);
    },
    clearAdditions(state) {
      state.additions = new Array();
    },
    clearRemovals(state) {
      state.removals = new Array();
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setOrderReturn(state, payload) {
      state.order.order_return = payload;
    },
  },
  getters: {
    getOrder(state) {
      return state.order;
    },
    getNextOrder: (state, getters, rootState, rootGetters) => {
      const currentOrder = getters.getOrder;
      const orders = rootGetters['admin/getOrders'];

      for (let i = 0; i < orders.length - 1; i++) {
        if (currentOrder._id === orders[i]._id) {
          return orders[i + 1]._id;
        }
      }
      return null;
    },
    getPreviousOrder: (state, getters, rootState, rootGetters) => {
      const currentOrder = getters.getOrder;
      const orders = rootGetters['admin/getOrders'];
      for (let i = 0; i < orders.length; i++) {
        if (i === 0 && currentOrder._id === orders[i]._id) {
          return null;
        }
        if (currentOrder._id === orders[i]._id) {
          return orders[i - 1]._id;
        }
      }
      return null;
    },
    getAlterationLogs: (state, getters, rootState) => {
      const user = rootState.user;
      const logs = new Array();
      state.removals.forEach((payload) => {
        logs.push({
          userEmail: user.email,
          type: 'Order',
          category: 'Update',
          name: `#${state.order.orderNumber}`,
          description: `Removed ${payload.productNum} from orderNumber #${state.order.orderNumber}`,
        });
      });

      state.additions.forEach((payload) => {
        logs.push({
          userEmail: user.email,
          type: 'Order',
          category: 'Update',
          name: state.order.orderNumber,
          description: `Added ${payload.productNum} to orderNumber #${state.order.orderNumber}`,
        });
      });

      return logs;
    },
    getLoading(state) {
      return state.loading;
    },
  },
  actions: {
    async getOrder({ commit, dispatch }, payload) {
      commit('setLoading', true);
      try {
        const response = await axios.get(`/api/v1/orders/${payload}`);
        commit('setOrder', response.data);

        dispatch('getLogs', `?search=PCS-${response.data.orderNumber}`, { root: true });
      } catch (error) {
        console.log(error);
      }
      commit('setLoading', false);
    },
    async cancelOrder({ commit }, payload) {
      try {
        await axios.put('/api/v1/orders/', payload);
        commit(
          'setError',
          {
            message: 'Canceled the order.',
            color: 'red',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Failed to cancel order.',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
    },
    async deleteOrder({ commit }, payload) {
      try {
        await axios.delete(`/api/v1/orders/${payload}`);
        commit(
          'setError',
          {
            message: 'Successfully deleted order',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async updateOrder({ commit, getters, dispatch }, payload) {
      try {
        await axios.patch('/api/v1/orders/admin', payload);
        const alterationLogs = getters.getAlterationLogs;
        for (let i = 0; i < alterationLogs.length; i++) {
          await axios.post('/api/v1/site/log', alterationLogs[i]);
        }
        commit('clearAdditions');
        commit('clearRemovals');
        commit(
          'setError',
          {
            message: 'Successfully updated order',
            color: 'green',
          },
          { root: true }
        );
        dispatch('getOrder', payload._id);
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error updating order',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
    },
    async saveReturn(state, payload) {
      try {
        await axios.put('/api/v1/returns', payload);
      } catch (error) {
        console.log(error);
      }
    },
    async submitReturn({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/returns', payload);
        commit('setOrder', response.data);
        commit(
          'setError',
          {
            message: 'Return was successfully created.',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Return was not created. ',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async sendEmail({ commit }, payload) {
      try {
        await axios.put('/api/v1/returns', payload.order_return);
        await axios.post('/api/v1/returns/email', payload);

        commit(
          'setError',
          {
            message: `Email sent to ${payload.email}`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Email failed to send. ',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async sendRefundGrantedEmail({ commit }, payload) {
      try {
        const quickbooksResponse = await axios.post(
          '/api/v1/quickbooks/credit_memo',
          payload.order_return
        );
        console.info(quickbooksResponse);
        payload.order_return.quickbooksId = quickbooksResponse.data;
        await axios.put('/api/v1/returns', payload.order_return);
        await axios.post('/api/v1/returns/email', payload);
        commit('setOrderReturn', payload.order_return);
        commit(
          'setError',
          {
            message: `Email sent to ${payload.email}`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Email failed to send. ',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async chargeCard({ commit, dispatch }, payload) {
      try {
        await axios.post('/api/v1/creditCard/charge', payload);
        payload.order.paid = true;
        await axios.patch('/api/v1/orders/admin', payload.order);
        await axios.post('/api/v1/site/log', {
          userEmail: payload.order.email,
          type: 'Order',
          category: 'Update',
          name: `PCS-${payload.order.orderNumber}`,
          description: `Made payment of ${payload.order.total} by inputing CC numbers on the Edit Order Page`,
        });
        commit(
          'setError',
          {
            message: 'Payment successful',
            color: 'green',
          },
          { root: true }
        );
        dispatch('getOrder', payload.order._id);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to charge Card',
            color: 'red',
          },
          { root: true }
        );
      }
    },
  },
};
