import axios from 'axios';
export default {
  namespaced: true,
  state: {
    company: null,
    companies: null,
    orders: [],
    totalNumberOfOrders: 0,
    loading: false,
  },
  mutations: {
    setCompany(state, payload) {
      state.company = payload;
    },
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setOrders(state, payload) {
      state.orders = payload;
    },
    setOrdersTotal(state, payload) {
      state.totalNumberOfOrders = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
  getters: {
    getCompany(state) {
      return state.company;
    },
    getCompanies(state) {
      return state.companies;
    },
    getOrders(state) {
      return state.orders;
    },
    getTotalNumberOfOrders(state) {
      return state.totalNumberOfOrders;
    },
    getLoading(state) {
      return state.loading;
    },
    getSpecialPricedItems(state) {
      return state.company?.products;
    },
  },
  actions: {
    async runClean({ commit }) {
      try {
        await axios.post('/api/v1/companies/runClean');
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error creating company',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
    },
    async createCompany({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/companies/', {
          users: [payload.user_id],
          name: payload.name,
          accountNumber: payload.accountNumber,
        });

        commit('setCompany', response.data);
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error creating company',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
    },
    async getCompany({ commit }, payload) {
      try {
        commit('setLoading', true);

        if (payload._id) {
          payload = payload._id;
        }
        const response = await axios.get('/api/v1/companies/' + payload);

        commit('setCompany', response.data);
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error getting company',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
      commit('setLoading', false);
    },
    async getCompanyLimited({ commit }, payload) {
      commit('setLoading', true);
      try {
        const response = await axios.get('/api/v1/companies/limited' + payload);
        commit('setCompany', response.data);
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error getting company',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
      commit('setLoading', false);
    },
    async getCompanies({ commit }, payload) {
      commit('setLoading', true);
      try {
        const response = await axios.get(`/api/v1/companies?${payload}`);
        commit('setCompanies', response.data);
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error getting companies',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
      commit('setLoading', false);
    },
    //Goes through and syncs the product costs with company special priced items
    async syncCosts({ commit }) {
      try {
        commit(
          'setError',
          {
            message:
              'Costs are being synced. This will take time, but you do not have to remain on this page. ',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
        await axios.patch('/api/v1/companies/all');
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'There was an error syncing costs',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async saveCompany({ commit }, payload) {
      commit('setLoading', true);
      try {
        delete payload.products;
        delete payload.orders;
        delete payload.tiers;

        const response = await axios.post('/api/v1/companies', payload);
        commit('setCompany', response.data);
        commit(
          'setError',
          {
            message: 'Successfully saved company',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error getting companies',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
      commit('setLoading', false);
    },

    async updateCompany({ commit }, payload) {
      commit('setLoading', true);
      try {
        delete payload.products;
        delete payload.orders;
        delete payload.tiers;

        await axios.put('/api/v1/companies', payload);
        commit(
          'setError',
          {
            message: 'Successfully saved company',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error getting companies',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
      commit('setLoading', false);
    },

    async saveTiers({ commit }, payload) {
      commit('setLoading', true);
      try {
        await axios.post('/api/v1/companies/tiers', { _id: payload._id, tiers: payload.tiers });
        commit(
          'setError',
          {
            message: 'Successfully saved tiers',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error saving tiers',
            color: 'red',
          },
          { root: true }
        );
        console.log(error);
      }
      commit('setLoading', false);
    },

    async saveToSpecialPricing({ commit }, payload) {
      try {
        await axios.patch(`/api/v1/companies/${payload.company_id}`, payload);
        commit(
          'setError',
          {
            message: `Successfully saved ${payload.productNum} to company.`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: `Failed to save ${payload.productNum} to company.`,
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async removeSpecialPricedItem({ commit }, payload) {
      try {
        await axios.put(`/api/v1/companies/${payload.company_id}`, payload);
        commit(
          'setError',
          {
            message: `Successfully removed product from company.`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: `Failed to remove product from company.`,
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async removeUser({ commit }, payload) {
      commit('setLoading', true);
      try {
        await axios.delete(`/api/v1/companies/${payload.company_id}/${payload.user_id}`);
        commit(
          'setError',
          {
            message: `Successfully removed user from company.`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: `Failed to remove user from company.`,
            color: 'red',
          },
          { root: true }
        );
      }
      commit('setLoading', false);
    },

    /**
     * Create a user account, add the user to the company, then send invitation to the user.
     * This is initiated by the user in Team.vue
     * @param {*} param0
     * @param {*} payload
     */
    async addAssociatedAccount({ commit }, payload) {
      try {
        await axios.post('/api/v1/companies/addAssociatedAccount', payload);
        commit(
          'setError',
          {
            message: 'Successfully added the account, please refresh the page.',
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message:
              'Email is already associated with an account, please contact that person and then customer service to have the emails linked.',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },

    /**
     * Add a user to the company users list
     * @param {Object} Store
     * @payload {Object: {user_id: String, company_id: String}}
     */
    async addUser({ commit, dispatch }, payload) {
      try {
        await axios.post(`/api/v1/companies/${payload.company_id}/${payload.user_id}`);
        commit(
          'setError',
          {
            message: `Successfully removed user from company.`,
            color: 'green',
          },
          { root: true }
        );
        dispatch('getCompany', payload.company_id);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: `Failed to remove user from company.`,
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async overWriteVariants({ commit }, payload) {
      if (payload.variants.length > 1) {
        commit('setLoading', true);
      }
      try {
        for (let i = 0; i < payload.variants.length; i) {
          await axios.put('/api/v1/companies/variant', {
            variants: payload.variants.slice(i, i + 25),
            users: payload.users,
            sendEmail: payload.sendEmail,
          });
          i += 25;
        }
        commit(
          'setError',
          {
            message: 'Saved variants',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error saving variant',
            color: 'red',
          },
          { root: true }
        );
      }
      commit('setLoading', false);
    },

    async getOrders({ commit }, payload) {
      try {
        const response = await axios.get('/api/v1/companies/orders?' + payload);
        commit('setOrders', response.data.orders);
        commit('setOrdersTotal', response.data.total);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'error retrieving orders',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },
  },
};
