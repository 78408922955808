<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="max-w-3xl">
          <h2 class="text-4xl font-bold tracking-tight text-gray-900">
            We built our business on customer service (only visible to Andrew & Gabriel)
          </h2>
          <p class="mt-4 text-gray-500">
            At the beginning at least, but then we realized we could make a lot more money if we
            kinda stopped caring about that. Our new strategy is to write a bunch of things that
            look really good in the headlines, then clarify in the small print but hope people don't
            actually read it.
          </p>
        </div>
        <div class="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
          <div class="sm:flex lg:block">
            <div class="sm:flex-shrink-0">
              <img
                class="h-16 w-16"
                src="https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg"
                alt=""
              />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
              <h3 class="text-sm font-medium text-gray-900">Free shipping</h3>
              <p class="mt-2 text-sm text-gray-500">
                It&#039;s not actually free we just price it into the products. Someone&#039;s
                paying for it, and it&#039;s not us.
              </p>
            </div>
          </div>

          <div class="sm:flex lg:block">
            <div class="sm:flex-shrink-0">
              <img
                class="h-16 w-16"
                src="https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg"
                alt=""
              />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
              <h3 class="text-sm font-medium text-gray-900">10-year warranty</h3>
              <p class="mt-2 text-sm text-gray-500">
                If it breaks in the first 10 years we&#039;ll replace it. After that you&#039;re on
                your own though.
              </p>
            </div>
          </div>

          <div class="sm:flex lg:block">
            <div class="sm:flex-shrink-0">
              <img
                class="h-16 w-16"
                src="https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg"
                alt=""
              />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
              <h3 class="text-sm font-medium text-gray-900">Exchanges</h3>
              <p class="mt-2 text-sm text-gray-500">
                If you don&#039;t like it, trade it to one of your friends for something of theirs.
                Don&#039;t send it here though.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
