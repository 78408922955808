import Vue from 'vue';
import App from './App.vue';
import VueLazyLoad from 'vue-lazyload';
import Hotjar from 'vue-hotjar';
import router from './router';
import store from './store';

import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import IdleVue from 'idle-vue';
import './assets/tailwind.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faEye,
  faRunning,
  faToolbox,
  faTools,
  faEnvelopeOpen,
  faUser,
  faTimes,
  faLock,
  faDollarSign,
  faPhoneAlt,
  faEnvelopeOpenText,
  faEnvelope,
  faUserShield,
  faUserCircle,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faImage,
  faSyncAlt,
  faSignOutAlt,
  faSignInAlt,
  faPlus,
  faMinus,
  faExclamationTriangle,
  faUserClock,
  faCircle,
  faTruck,
  faCalendarAlt,
  faAlignLeft,
  faObjectGroup,
  faFileAlt,
  faEllipsisV,
  faFile,
  faWindowMinimize,
  faSearch,
  faShoppingCart,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPrint,
  faCartPlus,
  faUnderline,
  faUnlock,
  faLink,
  faCog,
  faPencilAlt,
  faShare,
  faCheck,
  faInfoCircle,
  faExclamation,
  faPercent,
  faMapMarked,
  faList,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';

import {
  faEnvelopeOpen as farEnvelopeOpen,
  faUser as farUser,
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faEdit as farEdit,
  faFileAlt as farFileAlt,
  faObjectGroup as farObjectGroup,
  faTrashAlt as farTrashAlt,
  faSave as farSave,
  faWindowMinimize as farWindowMinimize,
} from '@fortawesome/free-regular-svg-icons';

import { faGoogle, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faEdit,
  faList,
  faShoppingCart,
  faUnderline,
  faSearch,
  faUnlock,
  faLock,
  faInfoCircle,
  faLink,
  faTimes,
  faHome,
  faPrint,
  faExclamation,
  faPencilAlt,
  faPercent,
  faEye,
  faRunning,
  faShare,
  faCheck,
  faToolbox,
  faChevronRight,
  faTools,
  faEnvelopeOpen,
  farEnvelopeOpen,
  faUser,
  farUser,
  faMinus,
  faTimes,
  faLock,
  faChevronLeft,
  faDollarSign,
  faCog,
  faPhoneAlt,
  faEnvelopeOpenText,
  faEnvelope,
  faUserShield,
  faUserCircle,
  faSyncAlt,
  faSignOutAlt,
  faSignInAlt,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faImage,
  faPlus,
  faExclamationTriangle,
  faUserClock,
  farCheckCircle,
  farCircle,
  faCircle,
  faCalendarAlt,
  faGoogle,
  faFacebookSquare,
  farEdit,
  faAlignLeft,
  farFileAlt,
  faFileAlt,
  farObjectGroup,
  faObjectGroup,
  faEllipsisV,
  farTrashAlt,
  farSave,
  faMapMarked,
  faFile,
  faTruck,
  farWindowMinimize,
  faWindowMinimize,
  faChevronDown,
  faCartPlus
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  error: '/images/icons/image-coming-soon.png',
  loading: '/images/icons/Loading.svg',
  attempt: 1,
});

Vue.use(
  VueGtag,
  {
    config: {
      id: 'GTM-MZ2382X',
      params: {
        send_page_view: false,
      },
    },
  },
  router
);

Vue.use(VueMeta);

const eventsHub = new Vue();

//set the time that it takes to log someone out.
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 36000000, //60min
  startAtIdle: false,
});

Vue.use(Hotjar, {
  id: '3480413',
  isProduction: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
