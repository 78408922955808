<template>
  <div class="fixed sm:sticky bottom-0 sm:bottom-auto sm:top-0 z-100 w-full">
    <div
      v-if="showSearch"
      @click="showSearch = false"
      class="modal-mask md:hidden"
      style="z-index: -1"
    ></div>
    <div
      class="relative w-full h-20 bg-white shadow-blur-glow flex items-center justify-around font-medium"
    >
      <!-- Logo -->
      <div class="sm:absolute sm:left-3 lg:left-6 flex">
        <router-link to="/">
          <img
            src="/images/icons/Logo Mark-full-color.svg"
            alt="Page Custom Supplies"
            width="65px"
            class="sm:hidden -mb-1"
          />
          <img
            src="/images/icons/Logo-Secondary-full-color.svg"
            alt="Page Custom Supplies"
            width="140px"
            class="hidden sm:block mt-1"
          />
        </router-link>
        <div
          class="text-xs mb-2 pb-px ml-1 align-bottom hidden md:flex mt-auto"
          :class="isOpenClasses()"
        >
          {{ isOpen() }}
        </div>
      </div>
      <!-- End Logo -->

      <!-- Search Input -->
      <div class="sm:absolute sm:right-10 lg:right-16 lg:mr-10 flex">
        <!-- Button on mobile-view navbar.  This stays there -->
        <button @click="search" class="focus:outline-none sm:hidden">
          <img src="/images/icons/Search.svg" alt="Search Icon" width="30px" />
        </button>

        <form
          class="fixed top-0 left-0 right-0 py-2 shadow-md sm:shadow-none sm:bg-transparent sm:p-0 sm:relative sm:flex items-center"
          :class="showSearch ? 'bg-white' : 'hidden'"
          @submit.prevent="search"
          autocomplete="off"
        >
          <button
            type="submit"
            class="focus:outline-none transition-all duration-150 sm:visible sm:opacity-100"
            :class="
              showSearch ? 'absolute left-1 sm:-ml-3 -mt-px sm:mt-0 p-3 z-1' : 'invisible opacity-0'
            "
          >
            <img
              src="/images/icons/Search.svg"
              alt="Search Icon"
              class="transition-all duration-100"
              :width="getSearchIconWidth"
            />
          </button>
          <transition :name="getInputTransition">
            <input
              v-if="showSearch"
              class="ml-4 sm:m-0 sm:relative bg-gray-300 w-3/4-screen sm:w-80 md:w-1/2-screen pl-12 py-2"
              :class="searchValue ? 'rounded-t-2xl' : 'rounded-full'"
              type="search"
              spellcheck="true"
              placeholder="Product Name or Number"
              v-model="searchValue"
              ref="navBarSearchInput"
              id="navBarSearch"
            />
          </transition>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              class="hidden md:block absolute sm:w-80 md:w-1/2-screen bg-gray-300 shadow-lg max-h-56 rounded-b-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none top-2 sm:text-sm z-10"
              v-if="showSuggestions"
            >
              <ul v-if="suggestions.length > 0">
                <li
                  v-for="(suggestion, index) in suggestions.splice(0, 10)"
                  :key="`${suggestion}-${index}`"
                  class="select-none relative mx-2 py-2 px-2 rounded-md flex items-center hover:text-white hover:bg-action cursor-pointer"
                  @click="setSearch(suggestion)"
                >
                  {{ suggestion }}
                </li>
              </ul>
              <ul v-else>
                <li
                  class="select-none relative py-2 pl-3 pr-9 flex items-center hover:text-white hover:bg-action cursor-pointer"
                >
                  No matching products
                </li>
              </ul>
            </div>
          </transition>

          <!-- The search button which converts to cancel button when form is opened -->
          <span class="text-sm ml-3 cursor-pointer text-primary">
            <button
              class="hidden lg:block font-medium"
              v-if="!showSearch"
              @click="showSearch = true"
            >
              Search
            </button>
            <input
              v-else
              spellcheck="true"
              type="reset"
              value="Cancel"
              @click="showSearch = false"
              class="plain font-medium cursor-pointer"
            />
          </span>
        </form>
      </div>
      <!-- End Search -->

      <!-- Shop Now -->
      <transition name="fadeFast">
        <div
          class="sm:mx-auto transition-all duration-200"
          :class="showSearch ? 'sm:opacity-0 sm:invisible' : 'sm:opacity-100'"
        >
          <button
            @click="$router.push({ name: 'Categories' })"
            class="btn-action rounded-xl text-xl w-36 sm:w-40"
          >
            Shop now
          </button>
        </div>
      </transition>
      <!-- End Shop Now -->

      <!-- Profile -->

      <div class="sm:absolute sm:right-6 sm:mr-2 lg:right-10 lg:mr-3" v-on-clickaway="close">
        <transition :name="getTransition">
          <div
            v-show="showUserOptions"
            id="userOptions"
            class="fixed sm:absolute bg-white bottom-5 left-0 right-0 sm:top-3 sm:-left-4 lg:left-auto sm:right-auto sm:bottom-auto text-lg sm:w-48 shadow sm:shadow-md flex justify-center flex-col flex-wrap text-left font-normal rounded rounded-b-none sm:rounded-b overflow-hidden"
          >
            <router-link
              v-if="registeredFully"
              to="/user"
              tag="button"
              :class="dropDownMenuClasses"
              class="pt-4 md:pt-2"
            >
              Profile
            </router-link>
            <router-link
              v-else
              to="/commercial"
              tag="button"
              :class="dropDownMenuClasses"
              class="pt-4 md:pt-2"
            >
              Profile
            </router-link>
            <router-link
              to="/user/orders"
              tag="button"
              :class="dropDownMenuClasses"
              class="pt-4 md:pt-2"
            >
              Order History
            </router-link>
            <router-link
              to="/user/favorites"
              tag="button"
              :class="dropDownMenuClasses"
              class="pt-4 md:pt-2"
            >
              Favorites
            </router-link>
            <router-link
              to="/admin/createOrder"
              tag="button"
              v-if="authorized"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
            >
              Create Order
            </router-link>
            <router-link
              to="/admin/orders"
              tag="button"
              v-if="authorized"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
              >Orders</router-link
            >
            <router-link
              to="/admin/products"
              tag="button"
              v-if="authorized"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
              >Products</router-link
            >
            <router-link
              to="/admin/users?sort=name&page=1&limit=25"
              tag="button"
              v-if="authorized"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
              >Users</router-link
            >
            <router-link
              to="/admin/vendors"
              tag="button"
              v-if="authorized"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
            >
              Vendors
            </router-link>
            <router-link
              v-if="authorized"
              to="/admin/photos"
              tag="button"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
            >
              Photo Uploader
            </router-link>
            <router-link
              v-if="admin"
              to="/admin/logging"
              tag="button"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
            >
              Logging
            </router-link>
            <router-link
              v-if="
                admin &&
                (user.email === 'pagehardware4less@gmail.com' ||
                  user.email === 'gabrielsuttner@gmail.com')
              "
              to="/admin/variants"
              tag="button"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
            >
              Variants
            </router-link>
            <!-- <router-link
              v-if="admin"
              to="/admin/coupons"
              tag="button"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
            >
              Coupons
            </router-link> -->
            <router-link
              v-if="admin"
              to="/admin/site-settings"
              tag="button"
              :class="dropDownMenuClasses"
              class="bg-gray-200"
            >
              Site Settings
            </router-link>
            <button @click="logout()" :class="dropDownMenuClasses" class="bg-gray-300">
              Sign Out
            </button>
          </div>
        </transition>
        <button @click="profile()" class="md:flex items-center relative">
          <img src="/images/icons/Profile.svg" alt="Profile Icon" width="30px" />
          <p class="hidden lg:block text-sm ml-3 text-primary font-medium">
            <span v-if="authorized">Admin</span>
            <span v-else-if="user">Profile</span>
            <span v-else>Login</span>
          </p>
        </button>
      </div>
      <!-- End Profile -->

      <!-- Cart -->
      <div class="relative mr-3 sm:mr-0 sm:absolute sm:right-3 lg:right-4">
        <router-link to="/checkout" tag="button" class="flex items-center">
          <div
            v-if="cartCount > 0"
            class="absolute w-5 h-5 flex justify-center items-center bg-red-500 px-2 text-white rounded-xl right-0 lg:right-2 lg:mr-1 top-0 -mr-2 -mt-2 text-xs opacity-90"
          >
            {{ cartCount }}
          </div>
          <img src="/images/icons/Cart.svg" alt="Cart Icon" width="33px" />
          <p class="hidden lg:block text-sm ml-4 text-primary font-medium">Cart</p>
        </router-link>
      </div>
      <!-- Cart -->
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  data: () => {
    let largeScreen = window.innerWidth >= 600;

    return {
      largeScreen: largeScreen,
      showSearch: false,
      showUserOptions: false,
      registeredFully: true,
    };
  },
  created() {
    addEventListener('resize', () => {
      this.largeScreen = innerWidth >= 600;
    });
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    searchValue: {
      get() {
        return this.$store.getters['products/getSearchValue'];
      },
      set(val) {
        this.$store.commit('products/setSearchValue', val);
      },
    },
    showSuggestions() {
      return this.searchValue;
    },
    cartCount() {
      return this.$store.getters['order/getCartCount'];
    },
    getSearchIconWidth() {
      return this.showSearch ? '20px' : '30px';
    },
    getInputTransition() {
      if (this.largeScreen) return 'expandLeft';
      else return 'scaleDown';
    },
    authorized() {
      return this.$store.getters.getAuthorized;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    dropDownMenuClasses() {
      if (this.authorized) {
        return 'px-6 py-1 text-primary hover:bg-primary-alt text-left';
      }
      return 'px-6 py-3 md:py-2 text-primary hover:bg-primary-alt text-left';
    },
    getTransition() {
      if (this.largeScreen) {
        return 'scaleDown';
      } else {
        return 'scaleUp';
      }
    },
    suggestions() {
      return this.$store.getters['products/getSearchArray'];
    },
  },
  watch: {
    showSearch(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.navBarSearchInput.focus();
        });
      } else {
        this.searchValue = '';
      }
    },
    $route() {
      this.showUserOptions = false;
    },
  },
  methods: {
    setSearch(val) {
      this.searchValue = val;
      this.search();
    },
    close() {
      this.showUserOptions = false;
    },
    search() {
      if (!this.showSearch) {
        this.showSearch = true;
        return;
      }
      if (this.searchValue == null) {
        this.showSearch = false;
        return;
      }
      this.$store.commit('filter/clearAllFilters');
      this.$router.push({
        name: 'Search',
        query: {
          type: 'search',
          title: `${this.searchValue}`,
        },
      });
      this.searchValue = null;
      this.showSearch = false;
    },
    profile() {
      this.registeredFully = this.user?.registeredFully;
      if (this.user == null) {
        this.$router.push('/user');
      } else {
        this.showUserOptions = !this.showUserOptions;
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.showUserOptions = false;
    },
    isOpen() {
      const currentTime = new Date();
      const todayIndex = currentTime.getDay();
      const today = this.siteSettings.hoursOfOperation[todayIndex];
      const tomorrow = this.siteSettings.hoursOfOperation[todayIndex < 6 ? todayIndex + 1 : 0];
      if (today.close === 'closed') {
        return 'currently closed';
      }
      const closingTime = new Date(currentTime.toISOString().slice(0, 10) + ' ' + today.close);
      const openTime = new Date(currentTime.toISOString().slice(0, 10) + ' ' + today.open);

      currentTime.setDate(currentTime.getDate());
      closingTime.setDate(currentTime.getDate());
      openTime.setDate(currentTime.getDate());
      if (currentTime < openTime) {
        return `open at ${openTime.toLocaleString('en-US', { timeStyle: 'short' }).toLowerCase()}`;
      }
      if (currentTime < closingTime && currentTime > openTime) {
        //If the store is still open.
        return `open until ${closingTime
          .toLocaleString('en-US', { timeStyle: 'short' })
          .toLowerCase()}`;
      } else {
        if (tomorrow.open != 'closed') {
          return `open tomorrow at ${tomorrow.open} am`;
        } else {
          return 'currently closed';
        }
      }
    },
    isOpenClasses() {
      if (this.isOpen().includes('open until')) {
        return 'text-primary';
      } else {
        return 'text-action';
      }
    },
  },
};
</script>

<style scoped>
#navBarSearch::-webkit-search-cancel-button {
  margin-right: 16px;
  cursor: pointer;
  padding: 2px;
}
</style>
