<template>
  <div class="relative">
    <button
      @click="scrollLeft()"
      :class="getHoverColor"
      class="hidden md:block absolute focus:outline-none -left-4 top-7 px-6 py-8 rounded-sm"
    >
      <font-awesome-icon icon="chevron-left" class="text-2xl text-gray-700" />
    </button>
    <button
      @click="scrollRight()"
      :class="getHoverColor"
      class="hidden md:block absolute focus:outline-none -right-4 top-7 px-6 py-8 rounded-sm"
    >
      <font-awesome-icon icon="chevron-right" class="text-2xl text-gray-700" />
    </button>
    <div class="" :class="white ? 'carousel-wrapper' : null">
      <transition-group
        v-if="loading"
        name="product"
        tag="div"
        ref="scrollWrapper"
        class="carousel z-1 scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch"
      >
        <ProductLoading v-for="(product, index) in products" :key="`${index}-product`" />
      </transition-group>
      <transition-group
        v-else
        name="slide-in"
        tag="div"
        ref="scrollWrapper"
        class="carousel z-1 scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch"
      >
        <ProductTile
          v-for="(product, index) in products"
          :key="`${index}-product`"
          :productInfo="product"
          :index="index"
          :length="products.length"
          :sortByCost="sortByCost"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import ProductTile from '@/components/items/ProductTile';
import ProductLoading from '@/components/shared/LoadingProduct';
export default {
  components: {
    ProductTile,
    ProductLoading,
  },
  props: {
    products: Array,
    white: { type: Boolean, default: true },
    loading: { type: Boolean, default: true },
    sortByCost: { type: Boolean, default: true },
  },
  computed: {
    getHoverColor() {
      if (this.white) return 'hover:bg-gray-200';
      else return 'hover:bg-gray-300';
    },
  },
  methods: {
    scrollLeft() {
      this.$refs.scrollWrapper.$el.scrollBy({
        top: 0,
        left: -900,
        behavior: 'smooth',
      });
    },
    scrollRight() {
      this.$refs.scrollWrapper.$el.scrollBy({
        top: 0,
        left: 900,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style>
.carousel {
  overflow: auto;
  white-space: nowrap;
  /* scroll-snap-type: x proximity; */
}

/* For anything larger than mobile device */
@media only screen and (min-width: 600px) {
  .carousel-wrapper::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white);
    width: 15px;
  }

  .carousel-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
    width: 15px;
  }
}

.carousel > * {
  /* scroll-margin: 0 50% 0 50%; */
  /* scroll-snap-align: center; */
  /* scroll-snap-stop: always; */
  display: inline-block;
}
.slide-in-enter-active {
  transition: transform 0.5s;
}

.slide-in-leave-active {
  transition: transform 0.5s;
}

.slide-in-enter {
  transform: translateX(-100%);
}

.slide-in-leave-to {
  transform: translateX(-100%);
}
</style>
