import axios from 'axios';

export default {
  namespaced: true,
  state: {
    incorrectUserProducts: null,
    loading: false,
  },
  getters: {
    getIncorrectUserProducts: ({ incorrectUserProducts }) => incorrectUserProducts,
    getLoading: ({ loading }) => loading,
  },
  mutations: {
    setIncorrectUserProducts(state, data) {
      state.incorrectUserProducts = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async reconcilePricing({ commit }, payload) {
      commit('setLoading', true);
      try {
        const response = await axios.get('/api/v1/analytics/?' + payload);
        commit('setIncorrectUserProducts', response.data);
      } catch (error) {
        console.error(error);
      }
      commit('setLoading', false);
    },
  },
};
