import axios from 'axios';

export default {
  namespaced: true,
  state: {
    loading: false,
    templates: [],
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setTemplates(state, payload) {
      state.templates = payload;
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getTemplates: (state) => (val) => {
      if (val) {
        return state.templates.filter((el) => el.name.includes(val));
      } else {
        return state.templates;
      }
    },
    getIsNameTaken: (state) => (payload) => {
      const val = payload.name.toLowerCase();
      for (const el of state.templates) {
        if (el.name.toLowerCase() == val && payload.template._id != el._id) {
          return true;
        }
      }
      return false;
    },
  },
  actions: {
    async get({ commit }) {
      try {
        const response = await axios.get('/api/v1/productTemplates/');
        commit('setTemplates', response.data);
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error retreiving templates',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async save({ commit }, payload) {
      try {
        const response = await axios.patch('/api/v1/productTemplates/', payload);
        commit('setTemplates', response.data);
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error saving template',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async create({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/productTemplates/', payload);
        commit('setTemplates', response.data);
        commit(
          'setError',
          {
            message: 'template created',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error retreiving templates',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async duplicate({ commit }, payload) {
      try {
        const response = await axios.put('/api/v1/productTemplates/', payload);
        commit('setTemplates', response.data);
        commit(
          'setError',
          {
            message: 'template duplicated',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error duplicating templates',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async delete({ commit }, payload) {
      try {
        const response = await axios.delete('/api/v1/productTemplates/' + payload);
        commit('setTemplates', response.data);
        commit(
          'setError',
          {
            message: 'Successfully deleted product template',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Error retreiving productTemplates',
            color: 'red',
          },
          { root: true }
        );
      }
    },
  },
};
