import axios from 'axios';
/**
 * This file is used for the admins when editing a user or creating an order for a user.
 * Not for the individual user when they are signed in.
 */
export default {
  namespaced: true,
  state: {
    user: null,
    admin: false,
    commercial: false,
    authorized: false,
    totalNumberOfOrders: 100,
    loading: false,

    order: null,
    order_return: null,
    orderLoading: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      if (user === null) {
        state.authorized = false;
        state.admin = false;
        state.commercial = false;
      } else if (user.permissionLevel === 'Admin') {
        state.authorized = true;
        state.admin = true;
        state.commercial = false;
      } else if (user.permissionLevel === 'Employee') {
        state.authorized = true;
        state.admin = false;
        state.commercial = false;
      } else if (user.permissionLevel === 'Commercial') {
        state.commercial = true;
        state.admin = false;
        state.authorized = false;
      } else {
        state.commercial = false;
        state.admin = false;
        state.authorized = false;
      }
    },
    setOrder(state, payload) {
      let day = new Date(payload.deliveryDate).toISOString().split('T')[0];
      payload.deliveryDate = day;
      day = new Date(payload.date).toISOString().split('T')[0];
      payload.date = day;
      state.order = payload;
      state.order_return = null;
    },
    setOrderReturn(state, payload) {
      state.order_return = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setOrderLoading(state, payload) {
      state.orderLoading = payload;
    },
  },
  getters: {
    getReturnObject: ({ order, order_return }) => {
      if (order.order_return) return order.order_return;
      if (order_return) return order_return;
      return false;
    },
    getUser: ({ user }) => {
      return user;
    },
    getUserId({ user }) {
      if (user) return user._id;
      return null;
    },
    getCompany: ({ user }) => {
      if (user.company) {
        return user.company;
      }

      return {};
    },
    getMyOrders: ({ user }) => {
      user.orders.forEach((el) => {
        el.date = el.date.slice(0, 10);
      });

      return user.orders.sort((a, b) => {
        return a.orderNumber > b.orderNumber ? -1 : 1;
      });
    },
    getQueryTotal: (state) => {
      return state.totalNumberOfOrders;
    },
    getOrder: ({ order }) => order,
    getLoading: ({ loading }) => loading,
    getOrderLoading: ({ orderLoading }) => orderLoading,
  },
  actions: {
    async updateUser({ commit }, data) {
      try {
        delete data.company;
        delete data.orders;
        delete data.products;
        delete data.variants;
        delete data.tiers;

        await axios.put('/api/v1/users/', data);
        commit(
          'setError',
          {
            message: 'Successfully updated profile',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error updating profile',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async addToFavorites({ commit }, payload) {
      try {
        await axios.post('/api/v1/products/favorite', payload);
        console.log(payload);
        commit('products/addToFavorites', payload, { root: true });
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to add item to favorites',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },
    async removeFromFavorites({ commit }, payload) {
      try {
        await axios.put('/api/v1/products/favorite', payload);
        commit('products/removeFromFavorites', payload, { root: true });
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to add item to favorites',
            color: 'red',
            duration: 6500,
          },
          { root: true }
        );
      }
    },

    async getOrder({ commit }, payload) {
      try {
        commit('setOrderLoading', true);
        const response = await axios.get('/api/v1/orders/' + payload);
        commit('setOrder', response.data);
        commit('setOrderLoading', false);
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to retrieve Order',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async updatePassword({ commit }, payload) {
      try {
        await axios.post('/api/v1/users/updatePassword', payload);
        commit(
          'setError',
          {
            message: 'Password has been updated',
            color: 'green',
          },
          { root: true }
        );
        return true;
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to update Password',
            color: 'red',
          },
          { root: true }
        );
        return false;
      }
    },

    async verifyResetToken({ commit }, payload) {
      try {
        await axios.post('/api/v1/users/verifyToken', payload);
        return true;
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Reset Password link is invalid.',
            color: 'red',
          },
          { root: true }
        );
        return false;
      }
    },

    async setPassword({ commit }, payload) {
      try {
        await axios.post('/api/v1/users/setPassword', payload);
        commit(
          'setError',
          {
            message: 'Password has been updated',
            color: 'green',
          },
          { root: true }
        );
        return true;
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: error.response.data.error,
            color: 'red',
          },
          { root: true }
        );
        return false;
      }
    },

    async resetPassword({ commit }, payload) {
      try {
        await axios.post('/api/v1/users/resetPassword', { email: payload });
        commit(
          'setError',
          {
            message: `A reset password link has been sent to ${payload}. The link is valid for next 24 hours.`,
            color: 'green',
            duration: 6500,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to reset Password',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async submitReturn({ commit }, payload) {
      try {
        const response = await axios.post('/api/v1/returns', payload);
        commit('setOrder', response.data);
        commit('setOrderReturn', null);
        commit(
          'setError',
          {
            message:
              'Your return was submitted. Please check your email for information on your order',
            color: 'green',
            duration: 10000,
          },
          { root: true }
        );
      } catch (error) {
        commit(
          'setError',
          {
            message: 'Return was not submitted. Please contact customer support.',
            color: 'red',
          },
          { root: true }
        );
      }
    },
  },
};
