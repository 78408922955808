import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import suggestions from './modules/suggestions';
import products from './modules/products';
import admin from './modules/admin';
import order from './modules/order';
import users from './modules/users';
import createOrder from './modules/createOrder';
import editOrder from './modules/editOrder';
import variants from './modules/variants';
import tags from './modules/tags';
import user from './modules/user';
import company from './modules/company';
import filter from './modules/filter';
import productTemplates from './modules/productTemplates';
import delayedPayments from './modules/delayedPayments';
import notifications from './modules/notifications';
import quickbooks from './modules/quickbooks';
import reviews from './modules/reviews';
import analytics from './modules/analytics';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layouts: new Array(),
    layoutLoad: false,
    error: null,
    webp: false,
    site_version: null,
    siteSettings: {
      phone: '(801) 410-4999',
      email: 'support@pagecustomsupplyllc.com',
      address: '47 S Orange Street Unit D 3-4 Salt Lake City, UT 84116',
      hoursOfOperation: [
        '8:00 a.m. - 5:00 p.m.',
        '8:00 a.m. - 5:00 p.m.',
        '8:00 a.m. - 5:00 p.m.',
        '8:00 a.m. - 5:00 p.m.',
        '8:00 a.m. - 5:00 p.m.',
        '8:00 a.m. - 5:00 p.m.',
      ],
    },
    logs: [],
    logQueryTotal: 0,
    scrollTo: {
      position: 0,
      name: null,
    },
  },
  mutations: {
    setScroll(state, data) {
      state.scrollTo = data ? data : { position: 0, name: null };
    },
    setSiteSettings(state, data) {
      state.siteSettings = data;
    },
    setSiteVersion(state, data) {
      state.site_version = data;
    },
    setWebpSupport(state, data) {
      state.webp = data;
    },
    setError(state, data) {
      if (!state.error) {
        state.error = data;
      } else {
        setTimeout(() => {
          state.error = data;
        }, 6000);
      }
    },
    setLayouts(state, data) {
      state.layouts = data;
    },
    setLayoutLoad(state, data) {
      state.layoutLoad = data;
    },
    addLayout(state, data) {
      state.layouts.push(data);
    },
    setLogs(state, data) {
      state.logs = data;
    },
    setLogQueryTotal(state, data) {
      state.logQueryTotal = data;
    },
  },
  getters: {
    getScroll:
      ({ scrollTo }) =>
      (param) => {
        if (param === scrollTo.name) {
          return scrollTo.position;
        }
        return 0;
      },
    getLayoutLoad: ({ layoutLoad }) => layoutLoad,
    getLogQueryTotal: ({ logQueryTotal }) => logQueryTotal,
    getLogs: ({ logs }) => logs,

    /**
     * @returns {Boolean} if the site version from the server doesn't match the version they have in local storage it will return false, if the version's match it will return true.
     * @param {*} state
     */
    getSiteVersion(state) {
      const site_version = window.localStorage.getItem('site_version');
      window.localStorage.setItem('site_version', state.site_version);
      if (!site_version || site_version === state.site_version) {
        return true;
      }
      return false;
    },
    getWebp: (state) => {
      return state.webp;
    },

    getLayouts: (state) => (param) => {
      if (param == 'All') return state.layouts;

      return state.layouts.filter((el) => {
        return el.page == param;
      });
    },

    getLayoutByID:
      ({ layouts }) =>
      (payload) => {
        let data = layouts.filter((t) => {
          return t._id === payload;
        });
        return data[0];
      },
    getAdmin(state) {
      return state.user.admin;
    },
    getCommercial(state) {
      return state.user.commercial;
    },
    getAuthorized(state) {
      return state.user.authorized;
    },
    getIsLoggedIn(state) {
      console.log(state.user);
      return state.user.user ? true : false;
    },
  },
  actions: {
    async saveSiteSettings({ commit }, payload) {
      try {
        await axios.post('/api/v1/site/', payload);
        commit('setError', {
          message: 'Successfully saved settings.',
          color: 'green',
        });
      } catch (error) {
        console.log(error);
        commit('setError', {
          message: 'Failed to save settings.',
          color: 'red',
        });
      }
    },

    async getSiteVersion({ commit }) {
      try {
        const response = await axios.get('/api/v1/site/');
        commit('setSiteVersion', response.data.siteVersion);
        commit('setSiteSettings', response.data.site);
      } catch (error) {
        console.log(error);
      }
    },

    async logout({ commit }) {
      try {
        await axios.delete('/api/v1/users');
        commit('user/setUser', null);
        commit('order/clearOrder', { root: true });
        commit('products/setFavorites', []);
        commit('notifications/setNotifications', null);

        commit('setError', {
          message: 'Successfully logged out.',
          color: 'green',
        });
      } catch (error) {
        console.log(error);
      }
    },
    async register({ commit }, data) {
      try {
        let response = await axios.post('/api/v1/users', data);
        commit('user/setUser', response.data.user);
        commit('users/setUser', response.data.user, { root: true });
      } catch (error) {
        console.log(JSON.stringify(error));
        if (error.message.includes('432')) {
          commit(
            'setError',
            {
              message: `There is already a company named ${data.company.name}. If you are a part of ${data.company.name}, please have a member of the company invite you or contact customer support.  `,
              color: 'red',
              duration: 10000,
            },
            { root: true }
          );
        } else {
          commit(
            'setError',
            {
              message: 'Email is already taken, please use another email.',
              color: 'red',
            },
            { root: true }
          );
        }
      }
    },
    async login({ dispatch, commit }, data) {
      try {
        let response = await axios.post('/api/v1/users/login', data);
        commit('user/setUser', response.data.user);
        commit('order/setUser', response.data.user);
        commit('notifications/setNotifications', response.data.notifications);

        dispatch('products/getFavorites');
        dispatch('products/getSuggested');
        commit('products/setLoading', false);

        if (response.data.user.permissionLevel === 'Commercial') {
          dispatch('order/getCarts');
        }

        commit(
          'setError',
          {
            message: 'Welcome!',
            color: 'green',
            duration: 3000,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: "Username or Password didn't match.",
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async loginAs({ dispatch, commit }, data) {
      try {
        let response = await axios.patch('/api/v1/users/login', { _id: data });
        commit('user/setUser', response.data.user);
        commit('order/setUser', response.data.user);
        dispatch('products/getFavorites');
        dispatch('products/getSuggested');
        commit('products/setLoading', false);

        if (response.data.user.permissionLevel === 'Commercial') {
          dispatch('order/getCarts');
        }

        commit(
          'setError',
          {
            message: `Welcome ${response.data.user.name}!`,
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Error logging in as user.',
            color: 'red',
          },
          { root: true }
        );
      }
    },

    async getUser({ commit, dispatch }) {
      try {
        let response = await axios.get('/api/v1/users');
        commit('user/setUser', response.data.user);
        commit('order/setUser', response.data.user);
        commit('notifications/setNotifications', response.data.notifications);

        dispatch('products/getFavorites');
        dispatch('products/getSuggested');
        commit('products/setLoading', false);

        if (response.data.user.permissionLevel === 'Commercial') {
          dispatch('order/getCarts');
        }
      } catch (error) {
        console.log(error);
      }
    },

    async google({ commit }, data) {
      try {
        let response = await axios.post('/api/v1/users/google', data);
        commit('user/setUser', response.data.user);
        commit('setError', {
          message: `Welcome!`,
          color: 'green',
        });
      } catch (error) {
        console.log(error);
        commit('setError', {
          message: 'There was an error Logging in',
          color: 'red',
        });
      }
    },

    async getLogs({ commit }, payload) {
      try {
        let response = await axios.get('/api/v1/site/logs' + payload);
        commit('setLogs', response.data.logs);
        commit('setLogQueryTotal', response.data.total);
        return response.data.logs;
      } catch (error) {
        console.log(error);
      }
    },
    async createLog(state, payload) {
      try {
        await axios.post('/api/v1/site/logs', payload);
      } catch (error) {
        console.log(error);
      }
    },
    async upload({ commit, state }, data) {
      try {
        let response = await axios.post('/api/v1/photos', data);
        console.log(response);
        if (!state.error) {
          commit('setError', {
            message: 'Successfully uploaded file',
            color: 'green',
          });
        }
        return response.data;
      } catch (error) {
        console.log(error);
        commit('setError', {
          message: 'Error uploading file',
          color: 'red',
        });
      }
    },

    async uploadSingle({ commit, state, getters }, data) {
      try {
        let response;
        if (getters.getAuthorized) {
          response = await axios.post('/api/v1/photos/single', data);
        } else {
          response = await axios.post('/api/v1/photos/avatar', data);
          response.data = [response.data];
        }
        if (!state.error) {
          commit('setError', {
            message: 'Successfully uploaded file',
            color: 'green',
          });
        }
        return response.data;
      } catch (error) {
        console.log(error);
        commit('setError', {
          message: 'Error uploading file',
          color: 'red',
        });
      }
    },

    async sendEmail(context, payload) {
      try {
        await axios.post('/api/v1/site/sendEmail', payload);
      } catch (error) {
        console.log(error);
      }
    },
    async contactUs(context, payload) {
      try {
        await axios.post('/api/v1/site/contactUs', payload);
      } catch (error) {
        console.log(error);
      }
    },
    //clears the search array so it reruns and recompiles the products.
    //this will also recreate the sitemaps.
    async clearSearchArray({ commit }) {
      try {
        axios.delete('/api/v1/site/clearSearchArray');
        commit('setError', {
          message:
            'The file that populates the search bar has begun rebuilding. No indicator will be given once it finishes, you may leave this page.',
          color: 'green',
          duration: 6500,
        });
      } catch (error) {
        console.log(error);
      }
    },

    async buildXML({ commit }) {
      try {
        axios.post('/api/v1/site/createXML');
        commit('setError', {
          message:
            'The file that populates the xml files has begun rebuilding. No indicator will be given once it finishes, you may leave this page.',
          color: 'green',
          duration: 6500,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
    suggestions,
    products,
    admin,
    order,
    users,
    createOrder,
    editOrder,
    variants,
    tags,
    user,
    company,
    filter,
    productTemplates,
    delayedPayments,
    notifications,
    quickbooks,
    reviews,
    analytics,
  },
});
