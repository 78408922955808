<!-- 
    FeedbackPopup.vue

    Pops up at bottom of screen to show feedback.
    Can have a message passed in through a slot,
        and a color (red, green, blue) defined through a prop.
    You can explicetly define the duration but if no duration is passed as a prob, it defaults to 2.5 sec. After 2.5 sec emits an event called 'goAway' to be
        able to change the v-if off
    Best when used with a transition

    WILL NOT WORK CURRECTLY IF NESTED IN A COMPONENT THAT
        HAS 'blurredBackground' CLASS APPLIED


    example:
    <transition name="coverUpPopup">
        <FeedbackPopup v-if="error" color="red" @goAway="error = ''">
            {{ error }}
        </FeedbackPopup>
    </transition>

 -->
<template>
  <div
    class="w-full sm:w-1/2 md:max-w-xl lg:max-w-lg fixed top-0 sm:top-auto sm:bottom-2 left-0 right-0 mx-auto shadow-lg text-md text-white rounded-b md:rounded top-level"
    :class="getBackgroundColor"
  >
    <div class="relative w-full">
      <button
        class="absolute right-0 px-2 py-1 rounded-tr rounded-bl hover:text-black hover:bg-gray-300 top-0"
        @click="$emit('goAway')"
      >
        <font-awesome-icon icon="times" />
      </button>
    </div>
    <div class="py-4 px-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'red',
    },
    duration: {
      type: Number,
      default: 2500,
    },
  },
  mounted() {
    if (this.duration > 0) {
      setTimeout(() => {
        this.$emit('goAway');
      }, this.duration);
    }
  },
  computed: {
    getBackgroundColor() {
      if (this.color == 'red') {
        return 'bg-feedbackError';
      } else if (this.color == 'green') {
        return 'bg-feedbackSuccess';
      } else if (this.color == 'white') {
        return 'bg-white';
      } else {
        return 'bg-action';
      }
    },
  },
};
</script>

<style scoped>
.top-level {
  z-index: 10000;
}
</style>
