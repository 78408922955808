/**
 *
 * @param {*} products
 * @param {*} taxRate
 * @param {*} shipping
 * @param {*} discount
 * @param {*} storeCredit
 * @returns
 */
module.exports = (products, taxRate = 0.0775, shipping = 0, discount = 0, storeCredit = 0) => {
  let subtotal = 0;
  for (const product of products) {
    subtotal += product.price * product.quantity;
  }
  let tax = (subtotal - storeCredit) * taxRate;
  let total = shipping + tax + subtotal - discount - storeCredit;
  return {
    subtotal,
    tax,
    total,
    shipping,
    storeCredit,
    discount,
  };
};
