import axios from 'axios';
/**
 * This file is used for the products page viewable by the user
 */
export default {
  namespaced: true,
  state: {
    featured: null,
    suggested: null,
    favorites: null,
    searchValue: '',
    recentlyPurchased: null,
    products: null,
    product: null,
    variants: null,
    //this is used inside of addtocart and productpricing
    quantityKey: 1,
    searchArray: [],
    limit: 50,
    currentPage: 1,
    loading: false,
    featuredLoading: false,
    suggestedLoading: false,
    totalProducts: 0,

    collectionProducts: null,
    collectionLoading: false,
  },
  mutations: {
    setTotalProducts(state, payload) {
      state.totalProducts = payload;
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload;
    },
    setLimit(state, payload) {
      state.limit = payload;
    },
    clearProducts(state) {
      state.products = [];
    },
    setLoadingFeaturedLoading(state, payload) {
      state.featuredLoading = payload;
    },
    setSuggestedLoading(state, payload) {
      state.suggestedLoading = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setVariants(state, payload) {
      state.variants = payload;
    },
    setQtyKey(state) {
      if (state.quantityKey > 10) {
        state.quantityKey = 0;
      }
      state.quantityKey++;
    },
    updateReviews(state, payload) {
      state.product.reviews = payload.reviews;
      state.product.averageRating = payload.averageRating;
    },
    /**
     * Set Product and also set if the variant is a favorite
     */
    setProduct(state, payload) {
      state.product = payload;
    },
    setProducts(state, payload) {
      if (state.products) {
        state.products = [...state.products, ...payload];
      } else {
        state.products = payload;
      }
    },
    setFeatured(state, payload) {
      state.featured = payload;
    },
    setRecentPurchases(state, payload) {
      state.recentlyPurchased = payload;
    },
    setSuggested(state, payload) {
      const suggestions = new Map();
      payload.forEach((el) => {
        suggestions.set(el._id, el);
      });
      state.suggested = suggestions;
    },
    setFavorites(state, payload) {
      state.favorites = payload;
    },
    addToFavorites(state, payload) {
      if (payload.product_id === state.product._id) {
        state.favorites.unshift(state.product);
      }
    },
    removeFromFavorites(state, payload) {
      state.favorites = state.favorites.filter((el) => {
        return el._id != payload.product_id;
      });
    },
    setSearchValue(state, payload) {
      state.searchValue = payload;
    },
    setSearchArray(state, payload) {
      state.searchArray = payload;
    },
    setCollectionProducts(state, payload) {
      state.collectionProducts = payload;
    },
    setCollectionLoading(state, payload) {
      state.collectionLoading = payload;
    },
  },
  getters: {
    getSearchValue(state) {
      return state.searchValue;
    },
    getFeaturedLoading(state) {
      return state.featuredLoading;
    },
    getSuggestedLoading(state) {
      return state.suggestedLoading;
    },
    getLoading(state) {
      return state.loading;
    },
    getQtyKey: ({ quantityKey }) => quantityKey,
    getCollectionProducts: ({ collectionProducts }) => collectionProducts,
    getCollectionLoading: ({ collectionLoading }) => collectionLoading,
    getProduct(state) {
      const product = state.product;
      const favorites = state.favorites;
      if (product && favorites && favorites.length > 0) {
        for (let i = 0; i < favorites.length; i++) {
          //if the products are the same go in and mark the variant as a favorite
          if (favorites[i]._id == product._id) {
            favorites[i].variants.some(function (favoriteVariant) {
              if (
                product.variants.some(function (variant) {
                  if (variant._id == favoriteVariant._id) {
                    variant.favorite = true;
                    variant.label = favoriteVariant.label;
                    return true;
                  }
                })
              ) {
                return true;
              }
            });
          }
        }
      }
      return product;
    },

    getProducts(state, getters, rootstate, rootGetters) {
      return rootGetters['filter/getSortedProducts'];
    },

    /**
     * Payload is the start index to pull from.
     */
    getLimitedProducts: (state, getters) => {
      const products = getters.getProducts;
      return products.slice(
        (state.currentPage - 1) * state.limit,
        (state.currentPage - 1) * state.limit + state.limit
      );
    },

    getFeatured: (state) => {
      const products = state.featured;
      if (state.featuredLoading) {
        return new Array(10);
      }
      if (products === undefined) {
        return null;
      }

      return products;
    },
    getSuggested: (state, getters, rootState) => {
      const products = state.suggested;
      if (state.suggestedLoading) {
        return new Array(10);
      }

      if (!products) {
        return [];
      }

      //go through the cart and remove added items from the suggestions
      const cart = rootState.order.carts[rootState.order.selectedCartIndex].cart;
      cart.forEach((el) => {
        if (products.has(el.productId)) {
          products.delete(el.productId);
        }
      });
      const suggestions = [...products.values()];
      if (state.product) {
        return state.product.suggestions
          .concat(suggestions)
          .filter((el) => {
            return el._id != state.product._id;
          })
          .slice(0, 20);
      } else {
        return suggestions.slice(0, 20);
      }
    },
    getRecentlyPurchased: (state) => (payload) => {
      const products = state.recentlyPurchased;

      if (!products) {
        return null;
      }
      if (Array.isArray(payload)) {
        return products.slice(0, payload);
      }

      return products;
    },

    getFavorites: (state) => (payload) => {
      const products = state.favorites;
      if (products == null || products.length == 0) {
        return [];
      }
      if (payload) {
        return products.slice(0, payload);
      }
      return products;
    },
    getSearchArray: (state) => {
      const search = state.searchValue ? state.searchValue.toLowerCase() : null;
      if (search) {
        return state.searchArray.filter((el) => el.includes(search));
      }
      return [];
    },
    getTotalProducts: ({ totalProducts }) => totalProducts,
    getLimit: ({ limit }) => limit,
    getCurrentPage: ({ currentPage }) => currentPage,
  },
  actions: {
    //returns the array for the search input suggestions.
    async getSearchArray({ commit }) {
      try {
        const response = await axios.get('/api/v1/site/getSearchValues');
        commit('setSearchArray', response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getProductAdmin({ commit, dispatch }, payload) {
      commit('setLoading', true);
      commit('setProduct', null);

      if (payload === 'new') {
        commit('setProduct', {
          _id: null,
          title: 'New Product',
          description: '',
          type: '',
          locationCode: '',
          image: null,
          flammable: false,
          publish: false,
          sale: false,
          isBundle: false,
          vendor: 'vendor',
          categories: new Array(),
          subcategories: new Array(),
          tags: new Array(),
          variants: new Array(),
          bundleItems: new Array(),
          options: new Array(),
          dropShip: false,
        });

        commit('setLoading', false);
        return;
      }
      try {
        let response = await axios.get('/api/v1/products/admin/' + payload);
        dispatch('getLogs', `?search=${response.data.title}`, { root: true });

        commit('setProduct', response.data);
      } catch (error) {
        console.log(error.message);
        commit('setProduct', null);
      }
      commit('setLoading', false);
    },

    async getProduct({ commit }, payload) {
      commit('setLoading', true);
      commit('setProduct', null);
      try {
        let response = await axios.get('/api/v1/products/' + payload);

        commit('setProduct', response.data);
      } catch (error) {
        console.log(error.message);
        commit('setProduct', null);
      }
      commit('setLoading', false);
    },

    async getProducts({ commit }, payload) {
      commit('setLoading', true);
      commit('filter/setLoading', true, { root: true });
      let query;
      let filterQuery = '';

      if (payload != null) {
        Object.keys(payload).forEach((key) => {
          if (payload[key] === undefined || payload[key] === 'undefined') {
            delete payload[key];
          } else {
            payload[key] = encodeURIComponent(payload[key]);
          }
        });
        if (payload.subcategories) {
          filterQuery = `name=${payload.subcategories}&type=Subcategory`;
        } else if (payload.categories) {
          filterQuery = `'name=${payload.categories}&type=Category`;
        } else {
          filterQuery = `name=${payload.tags}&type=Tag`;
        }

        query =
          '?' +
          JSON.stringify(payload)
            .replace(/}/g, '')
            .replace(/{/g, '')
            .replace(/"/g, '')
            .replace(/:/g, '=')
            .replace(/,/g, '&');
      } else {
        query = '';
      }
      let total = 1;
      let retreived = 0;

      let response = await axios.get(`/api/v1/filters/single?${filterQuery} `);
      commit('filter/setFilterStatic', response.data.filter, { root: true });
      commit('filter/setFilterTemplates', response.data.templates, { root: true });

      commit('filter/setLoading', false, { root: true });

      try {
        while (retreived < total) {
          response = await axios.get(
            '/api/v1/products/' + query + `&page=${retreived / 50 + 1}&limit=50`
          );
          commit('setLoading', false);
          commit('setProducts', response.data.products);
          total = response.data.total;
          retreived += response.data.products.length;
        }

        commit('setTotalProducts', total);
      } catch (error) {
        console.log(error);
      }
      commit('setLoading', false);
    },
    async createNewProduct(state, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (res, rej) => {
        try {
          const response = await axios.post('/api/v1/products/', payload);
          return res(response);
        } catch (error) {
          return rej(error);
        }
      });
    },

    async getAllVariants({ commit }) {
      commit('setLoading', true);
      try {
        const response = await axios.get('/api/v1/products/variant');
        commit('setVariants', response.data);
      } catch (error) {
        console.log(error);
      }
      commit('setLoading', false);
    },

    async search({ commit }, payload) {
      commit('setLoading', true);
      commit('filter/setLoading', true, { root: true });
      let query;
      if (payload != null) {
        query =
          '?' +
          JSON.stringify(payload)
            .replace(/}/g, '')
            .replace(/{/g, '')
            .replace(/"/g, '')
            .replace(/:/g, '=')
            .replace(/,/g, '&');
      } else query = '';

      try {
        let response = await axios.get('/api/v1/products/search' + query);
        let templateData = await axios.get('/api/v1/filters');
        commit('setProducts', response.data);
        commit('filter/setFilterTemplates', templateData.data, { root: true });
        commit('filter/createDynamicFilter', response.data, { root: true });

        commit('setTotalProducts', response.data.length);
      } catch (error) {
        console.log(error);
      }
      commit('setLoading', false);
    },

    async initialSetup({ commit }) {
      commit('setLoadingFeaturedLoading', true);

      try {
        const response = await axios.get('/api/v1/products/?tags=Featured');
        commit('setFeatured', response.data.products);
        commit('setLoadingFeaturedLoading', false);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Gets all of the suggested items for the products page
     */
    async getAllSuggested({ commit, rootState }) {
      let suggested;
      let recent;
      commit('setSuggestedLoading', true);
      if (rootState.user) {
        //if they are logged in
        suggested = axios.get('/api/v1/suggestions/');
        // recent = axios.get('/api/v1/products/recent');
      } else {
        suggested = axios.get('/api/v1/suggestions/notLoggedIn');
      }

      const response = await Promise.all([suggested, recent]);

      commit('setProducts', response[0].data);
      commit('setNewFilter');
      commit('setFilter');
      commit('setColors');
      commit('setSuggestedLoading', false);
    },

    async getSuggested({ commit, rootGetters }) {
      const payload = rootGetters['order/getOrder'];
      commit('setSuggestedLoading', true);
      const response = await axios.put('/api/v1/suggestions/', payload);
      commit('setSuggested', response.data);
      commit('setSuggestedLoading', false);
    },
    async setSuggested() {
      try {
        await axios.post('/api/v1/admin/products/suggestions');
      } catch (error) {
        console.log(error);
      }
    },
    async changeSuggestion({ commit }, payload) {
      try {
        await axios.put('/api/v1/admin/products/suggestions', payload);
        commit(
          'setError',
          {
            message: 'Successfully updated suggestion',
            color: 'green',
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          'setError',
          {
            message: 'Failed to update suggestion',
            color: 'red',
          },
          { root: true }
        );
      }
    },
    async getFavorites({ commit }) {
      try {
        const response = await axios.get('/api/v1/products/favorite');
        if (!Array.isArray(response.data)) {
          response.data = new Array();
        }

        commit('setFavorites', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getCollectionProducts({ commit }, payload) {
      try {
        commit('setCollectionLoading', true);
        const response = await axios.get('/api/v1/products?' + payload);

        commit('setCollectionProducts', response.data.products);
        commit('setCollectionLoading', false);
      } catch (error) {
        console.log(error);
      }
    },
    async getRecentPurchases({ commit }) {
      try {
        const recent = await axios.get('/api/v1/products/recent');
        commit('setProducts', recent.data);
        commit('setNewFilter');
        commit('setFilter');
        commit('setColors');
      } catch (error) {
        console.log(error);
      }
    },
  },
};
