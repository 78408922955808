<template>
  <div class="fixed bottom-0 md:right-1 md:bottom-1 z-100" v-if="notification">
    <Notification :notification="notification" />
  </div>
</template>

<script>
import Notification from './NotificationTemplates.vue';
export default {
  computed: {
    notification() {
      return this.$store.getters['notifications/getMostRecent'];
    },
    authorized() {
      return this.$store.getters['getAuthorized'];
    },
  },
  components: {
    Notification,
  },
  watch: {},
};
</script>

<style></style>
